import { useEffect, useRef, useCallback } from 'react'

import correctSound from 'assets/sounds/correct.mp3'
import wrongSound from 'assets/sounds/wrong.mp3'
import successSound from 'assets/sounds/success.mp3'
import useSiteStore from 'store/site'

export default function useSound() {
  const siteStore = useSiteStore()

  const correctSoundRef = useRef(null)
  const wrongSoundRef = useRef(null)
  const successSoundRef = useRef(null)

  useEffect(() => {
    // preload audio
    correctSoundRef.current = new Audio(correctSound)
    wrongSoundRef.current = new Audio(wrongSound)
    successSoundRef.current = new Audio(successSound)
  }, [])

  const canReproduceSound = siteStore?.site?.sound

  const playCorrectSound = useCallback(() => {
    if (canReproduceSound) {
      if (correctSoundRef.current?.currentTime) {
        correctSoundRef.current.currentTime = 0
      }
      correctSoundRef.current?.play()
    }
  }, [canReproduceSound])

  const playWrongSound = useCallback(() => {
    if (canReproduceSound) {
      if (wrongSoundRef.current?.currentTime) {
        wrongSoundRef.current.currentTime = 0
      }
      wrongSoundRef.current?.play()
    }
  }, [canReproduceSound])

  const playSuccessSound = useCallback(() => {
    if (canReproduceSound) {
      if (successSoundRef.current?.currentTime) {
        successSoundRef.current.currentTime = 0
      }
      successSoundRef.current?.play()
    }
  }, [canReproduceSound])

  return {
    playCorrectSound,
    playWrongSound,
    playSuccessSound,
  }
}
