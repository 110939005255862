import React from 'react'
import classNames from 'classnames'
import { useMedia } from 'react-use'
import { NavLink } from 'react-router-i18n'
import moment from 'moment'

import Header from 'layouts/Header'
import Layout from 'layouts/Layout'
import { Overflow } from 'layouts/Game'
import Button from 'components/Button'
import I18n from 'lang'
import useHomeBlockStore from 'store/home-block'
import useSiteStore from 'store/site'
import gamesApi from 'api'
import { getMomentLocale } from 'helpers/locale'
import Menu from 'components/Menu'

export const onClickExternalLink = async (e, basepath, to) => {
  e.preventDefault()
  e.stopPropagation()

  const saveUrl = `/userestado/save/${basepath}`.replace(/\/+/g, '/')

  try {
    await gamesApi.post(saveUrl)
  } catch (error) {}

  return window.open(to, '_self')
}

const Card = (props) => {
  let { game: gameProps = false, size } = props

  gameProps = gameProps || props.children?.[0]?.game || {}

  const horizontal = size === 'big'
  const className = classNames({ 'em-col-span-2 em-p-2': size === 'big' })

  const showAuthor = gameProps?.showAuthor
  const author = gameProps?.author
  const date = gameProps?.date || props?.children?.[0]?.game?.date
  const title = props?.label || gameProps?.title
  const childrenItems = props?.children?.map((item) => {
    return {
      label: item.game?.title,
      href: item.game?.url ? [item.game.locale, item.game.url].join('/').replace(/\/+/g, '/') : '',
    }
  })
  const iconUrl = gameProps.icon?.big || props?.icon?.big
  const externalLink = gameProps.url?.indexOf('http') === 0
  const to = externalLink ? gameProps.url : [gameProps.locale, gameProps.url].join('/').replace(/\/+/g, '/')
  const locale = gameProps.locale
  const isNew = gameProps.new

  const isMobile = useMedia('(max-width: 625px)')

  const icon = (
    <div>
      <img
        src={iconUrl}
        alt={title}
        className={classNames(
          'em-flex-grow em-flex-1 em-object-cover',
          { 'em-order-2': horizontal },
          { 'em-p-5': horizontal },
        )}
        style={{
          ...(horizontal
            ? isMobile
              ? { height: '180px', width: '180px' }
              : { width: '362px', height: '362px' }
            : isMobile
              ? { height: '94px', width: 'auto' }
              : { height: '160px', width: 'auto' }),
        }}
      />
    </div>
  )

  const Component = externalLink ? 'a' : NavLink
  const momentLocale = getMomentLocale(locale)

  return (
    <Component
      className={classNames(
        className,
        'group em-bg-white em-rounded-lg em-shadow-lg em-relative em-overflow-hidden',
        'hover:em-scale-[1.01] hover:em-z-10 hover:em-shadow-2xl em-transition-all',
        { 'em-py-4 lg:em-py-10': !horizontal },
        { 'em-py-4 lg:em-p-0': horizontal },
      )}
      to={to}
      href={to}
      ignoreLocale={true}
      onClick={(e) => {
        if (childrenItems) {
          e.preventDefault()
          e.stopPropagation()
          return
        }
        return externalLink ? onClickExternalLink(e, gameProps.api.basepath, to) : undefined
      }}
    >
      <Overflow
        active={!date}
        className='em-flex em-items-center em-justify-center'
      >
        {!date && <I18n t='game.notavailable' />}
      </Overflow>

      <div
        className={classNames('em-flex em-items-center em-justify-center em-h-full', {
          '!em-flex-col': !horizontal,
          '!em-flex-row': horizontal,
        })}
      >
        {!horizontal && icon}
        <div className='em-flex em-flex-col em-flex-1 em-flex-grow em-text-center'>
          <h3
            className={classNames(
              { 'em-pt-2 lg:em-pt-8': !horizontal },
              'em-text-black !em-font-semibold em-text-[22px] lg:em-text-2xl sm:em-text-3xl em-font-sans-pro em-ml-3 em-mr-3',
            )}
          >
            {title}
          </h3>
          {author && showAuthor && (
            <p className='em-capitalize em-font-sans-pro em-text-sm em-text-gray-500 !em-font-semibold'>
              {I18n.getTranslation({ pathname: '/' + locale }, 'by')} {author}
            </p>
          )}
          {date && (
            <p className='em-text-sm em-text-gray-500 em-capitalize em-font-sans-pro'>
              {moment(date).locale(momentLocale).format('dddd, DD.MM.YY')}
            </p>
          )}
          <div className='em-flex em-items-end em-justify-center em-flex-grow'>
            {childrenItems ? (
              <div className='em-mt-5'>
                <Menu
                  itemClassName='em-uppercase !em-font-semibold em-text-[14px]'
                  items={[
                    {
                      href: '',
                      label: I18n.getTranslation({ pathname: '/' + locale }, 'game.words.choose'),
                      options: childrenItems,
                      className:
                        'em-border em-border-black em-text-white em-uppercase em-font-semibold em-font-sans-pro em-py-2 em-px-5 em-rounded-lg',
                    },
                  ]}
                />
              </div>
            ) : (
              <Button
                as='div'
                className='em-uppercase em-mt-5 em-text-sm !em-font-semibold em-font-sans-pro group-hover:!em-bg-black group-hover:!em-text-white'
              >
                {I18n.getTranslation({ pathname: '/' + locale }, 'play')}
              </Button>
            )}
          </div>
        </div>
        {horizontal && icon}
      </div>

      {isNew && (
        <span
          className={classNames(
            'em-absolute em-right-0 em-font-sans-pro em-text-sm em-bg-red-500 em-text-white em-py-1 em-px-5 em-uppercase',
            { 'em-top-12 lg:em-top-34 sm:em-top-20': !horizontal },
            {
              'em-top-1/2 em-transform -em-translate-y-1/2': horizontal,
            },
          )}
        >
          <I18n t='game.new' />
        </span>
      )}
    </Component>
  )
}

Card.defaultProps = {
  locale: 'es',
}

export default function Home() {
  const homeBlockStore = useHomeBlockStore()
  const siteStore = useSiteStore()

  return (
    <Layout>
      <Header
        showGeneralTitle
        showMenu
      />
      {homeBlockStore.items?.map((block, index) => (
        <div
          className='em-p-3 lg:em-p-4 em-bg-disabled-lightest'
          style={{
            background: block.bgColor,
          }}
          key={index}
        >
          {block.title && (
            <div className='em-pb-3 em-text-xl em-font-bold em-text-center em-text-gray-600 em-uppercase lg:em-pb-5 em-font-sans-pro'>
              {block.title}
            </div>
          )}
          <div
            className={classNames('em-gap-3 lg:em-gap-4', 'em-grid em-grid-cols-2 lg:em-grid-cols-4 em-grid-flow-row ')}
          >
            {block.items?.map((item, index) => (
              <Card
                key={item.game?.id || item.game?.label}
                {...item}
              />
            ))}
          </div>
        </div>
      ))}

      <div className='em-p-3 em-bg-disabled-lightest lg:em-p-4'>
        <div
          className='em-text-sm em-text-right'
          dangerouslySetInnerHTML={{
            __html: siteStore?.site?.home?.footer,
          }}
        />
      </div>
    </Layout>
  )
}
