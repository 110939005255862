import useSiteStore from 'store/site'

export const isNameSite = (name) => {
  const { site } = useSiteStore.getState()

  if (Array.isArray(name)) {
    return name.includes(site.name)
  }

  return name === site.name
}
