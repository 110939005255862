import create from 'zustand'

import gamesApi from 'api'

const useGameInfoStore = create((set, get) => ({
  items: [],
  getGames: async () => {
    set({
      items: (await gamesApi.get('/config/games')).data,
    })
  },
}))

export default useGameInfoStore
