import { getCompletePathname } from '../../../config'

export default function TilesHexagon(props) {
  let tilePath
  if (props.paired) {
    tilePath = getCompletePathname(`/assets/tilesGame/tile-void.svg`)
  } else {
    tilePath = getCompletePathname(`/assets/tilesGame/tile-${props.tileId + 1}.svg`)
  }

  return (
    // props.tileId must be a value between 0 and 17
    // rotation must be a numeric value between 30, 90, 150, 210, 270, 330
    <svg
      x={props.x + '%'}
      y={props.y + '%'}
    >
      <image
        href={tilePath}
        transform={`rotate(${props.rotation + props.additionalRotation} 141.73 141.73)`}
      />
    </svg>
  )
}
