import React, { useRef } from 'react'
import propTypes from 'prop-types'
import { useParams } from 'react-router'
import moment from 'moment'
import classNames from 'classnames'

import Header from 'layouts/Header'
import Layout from 'layouts/Layout'
import Game, { Alert, Overflow, STATUS_FINISHED, STATUS_PAUSE } from 'layouts/Game'
import Timer from 'components/Timer'
import gamesApi, { gamesApiBase, getUserId } from 'api'
import I18n from 'lang'
import useGame from 'hooks/useGame'
import Buttonless from 'components/Buttonless'

import { ReactComponent as RedFaceIcon } from 'assets/testactualidad-emoji-frown-fill.svg'
import { ReactComponent as GreenFaceIcon } from 'assets/testactualidad-emoji-smile-fill.svg'
import useSound from 'hooks/useSound'

export default function CurrentChallenge(props) {
  const { gameProps } = props

  const { playCorrectSound, playWrongSound, playSuccessSound } = useSound()

  const {
    title,
    share: { title: shareTitle },
    color: bgColor,
    icon: { big: bigIconSrc },
    api: {
      basepath: baseUrlApi,
      attrs: { tema: category = null },
    },
    url: baseUrl,
  } = gameProps

  const { id = 'last', locale } = useParams()

  const [currentChallenge, setCurrentChallenge] = React.useState({})
  const [, _forceUpdate] = React.useState({})
  const [currentQuestion, setCurrentQuestion] = React.useState(0)
  const [statistics, setStatistics] = React.useState([])

  const forceUpdate = React.useCallback(
    () => {
      if (save && currentChallenge.id) {
        saveGame(false, '', true)
      }
      _forceUpdate({})
    },
    [currentChallenge.id], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const answersSelected = React.useRef([])

  const {
    initGame,
    setPause,
    setPlaying,
    setStatus,
    setModal,

    // actions
    finish,
    save,
    autoSave,

    // variables
    status,
    time,
    message,
    modal,
    isLoading,

    showEndGameModal,
    setShowEndGameModal,
  } = useGame({
    startFrom: parseInt(currentChallenge?.estadouser?.tiempo || 0, 10),
    statusInit: currentChallenge?.estadouser?.id,
    expirationDate: currentChallenge?.despublicado,
    locale: locale,
  })

  const fetchStatistics = async () => {
    const url = `/user/stats/${baseUrlApi}`.replace(/\/+/g, '/')
    const response = await gamesApi.post(url, {
      tema: category,
    })

    setStatistics(response.data)
  }

  const fetchGame = async () => {
    const response = await gamesApi.get(`${baseUrlApi}/get/${id}`.replace(/\/+/g, '/'), {
      params: {
        tema: category,
      },
    })
    const dataFetch = response.data

    setCurrentChallenge(dataFetch)
    answersSelected.current = dataFetch?.estadouser?.respuestas || Array(dataFetch.preguntas.length)
  }

  const finishGame = async () => {
    try {
      await finish(baseUrlApi, {
        gameid: currentChallenge?.id,
        gamedata: {
          tiempo: time,
          respuestas: answersSelected.current,
          tema: category,
        },
      })
      playSuccessSound()
      await fetchGame()
    } catch (error) {}
  }

  const saveGame = async (exit = false, newUrl = null, auto = false) => {
    const fnc = auto ? autoSave : save

    try {
      await fnc(
        baseUrlApi,
        {
          gameid: currentChallenge?.id,
          gamedata: {
            respuestas: answersSelected.current,
            tiempo: time,
            tema: category,
          },
        },
        exit,
        newUrl,
      )
    } catch (error) {}
  }

  const restartAllData = () => {
    setCurrentChallenge({})
    setCurrentQuestion(0)
    fetchStatistics()
    initGame(fetchGame)
  }

  React.useEffect(() => {
    restartAllData()
  }, [baseUrlApi, id, category]) // eslint-disable-line

  let correctAnswers = useRef(0)
  if (currentChallenge.preguntas) {
    correctAnswers.current = currentChallenge.preguntas.map((number) => number.respuestacorrecta)
  }

  const printAnswers = (withPadding = true, colors = true) => {
    if (correctAnswers.current) {
      return correctAnswers.current.map((value, index) => {
        if (answersSelected.current[index] === undefined) {
          return (
            <div
              key={`question_${index}`}
              className={withPadding && 'em-px-2'}
            >
              <div className=' em-w-10 em-h-10 em-rounded-full em-inline-flex em-items-center em-justify-center em-bg-gray-100 em-text-slate-600'>
                {index + 1}
              </div>
            </div>
          )
        }

        if (answersSelected.current[index] === value) {
          return (
            <div
              key={`question_${index}`}
              className={classNames({
                'em-px-2': withPadding,
                'em-text-success-600': colors,
                'em-text-yellow': !colors,
              })}
            >
              <GreenFaceIcon />
            </div>
          )
        } else {
          return (
            <div
              key={`question_${index}`}
              className={classNames({
                'em-px-2': withPadding,
                'em-text-danger': colors,
                'em-text-disabled-light': !colors,
              })}
            >
              <RedFaceIcon />
            </div>
          )
        }
      })
    }

    return null
  }

  const getStatusEndGame = React.useCallback(() => {
    let res = 'bad'
    let oks = 0
    if (correctAnswers.current) {
      correctAnswers.current.forEach((value, index) => {
        if (answersSelected.current[index] === value) {
          oks++
        }
      })
    }

    if (oks === 6) {
      res = 'good'
    } else if (oks >= 3) {
      res = 'neutral'
    }

    return res
  }, [correctAnswers, answersSelected])

  const endGameContent = (
    <div className='em-flex em-flex-col em-items-center em-justify-center em-text-yellow'>
      <span className='em-text-3xl em-uppercase em-text-center em-mb-4'>
        <I18n t={'game.currentChallenge.statusEndGame.' + getStatusEndGame()} />
      </span>
      <span className='em-uppercase em-text-white em-mb-3'>
        <I18n t='game.yourAnswers' />
      </span>
      <div className='em-flex em-justify-around em-w-full'>{printAnswers(false, false)}</div>
    </div>
  )

  const formatDate = (date) => {
    let dateText = moment(date).format('dddd, DD.MM.YY')
    return dateText.charAt(0).toUpperCase() + dateText.slice(1)
  }

  return (
    <Layout isLoading={isLoading}>
      <Header />

      <Game
        statsUrl={`/user/stats/${baseUrlApi}?tema=${category}`.replace(/\/+/g, '/')}
        endGameModal={{
          isOpen: showEndGameModal,
          title: shareTitle || title,
          icon: bigIconSrc,
          titleBgColor: bgColor,
          content: endGameContent,
          onDate: formatDate(currentChallenge?.publicado),
          statistics: [
            {
              i18nKey: 'game.messages.complete.completedTime',
              value: moment((currentChallenge?.estadouser?.tiempo || 0) * 1000).format('mm:ss'),
            },
            {
              i18nKey: 'game.messages.complete.averageTime',
              value: moment((currentChallenge?.promediogeneral || 0) * 1000).format('mm:ss'),
            },
          ],
          shareUrl:
            gamesApiBase +
            `user/stats/${baseUrlApi}/share?userid=${getUserId()}&id=${currentChallenge?.id}&tema=${category}`.replace(
              /\/+/g,
              '/',
            ),
          onClose: () => setShowEndGameModal(false),
        }}
        status={status}
        title={title}
        statistics={statistics}
        publicationDate={moment(currentChallenge?.publicado).format('dddd, DD.MM.YY')}
        expirationDate={moment(currentChallenge?.publicado).format('dddd, DD.MM.YY')}
        historicalGames={{
          active: !isLoading,
          url: `${baseUrlApi}/getlist?tema=${category}`.replace(/\/+/g, '/'),
          activeId: currentChallenge?.id,
          to: baseUrl,
          icon: bigIconSrc,
        }}
        saveGame={() => saveGame()}
        onClickOutside={(newUrl) => saveGame(true, newUrl)}
        exitGame={
          status !== STATUS_FINISHED
            ? (e) => {
                saveGame(true)
                e.preventDefault()
              }
            : false
        }
        helpMenu={[]}
        leftMenu={[]}
        middleMenu={
          <div className='em-flex em-items-center'>
            <Timer
              setPause={setPause}
              status={status}
              time={time}
            />
          </div>
        }
      >
        {message}
        <div className='em-text-center em-p-6 em-pt-3 em-mt-6 em-justify-items-start em-max-w-xl em-m-auto em-pb-52'>
          {status === STATUS_FINISHED && currentChallenge?.preguntas && currentChallenge?.estadouser?.id && (
            <div className='em-flex em-flex-col em-space-y-16'>
              {currentChallenge.preguntas.map((question, questionIndex) => {
                return (
                  <div>
                    <div className='em-text-sm em-mb-3'>
                      {I18n.getTranslation(
                        {
                          pathname: '/' + locale,
                        },
                        'game.quiz.pregunta',
                      )}{' '}
                      {questionIndex + 1}
                    </div>
                    <div
                      className='em-text-3xl em-text-center em-mb-12 !em-font-semibold !em-leading-8'
                      dangerouslySetInnerHTML={{
                        __html: question.pregunta,
                      }}
                    />
                    <div className='em-flex em-flex-col em-justify-start em-gap-6'>
                      {question.respuestas.map((answer, index) => {
                        const isActive = currentChallenge.estadouser.respuestas[questionIndex] === index
                        const isCorrect = index === currentChallenge.preguntas[questionIndex].respuestacorrecta

                        return (
                          <Buttonless
                            onClick={() => {}}
                            disabled={true}
                            key={`answer_${questionIndex}_${index}`}
                            className={classNames(
                              'em-flex em-items-center em-gap-3',
                              'em-border-2',
                              'em-rounded-md',
                              'em-min-h-[56px] em-px-3 em-py-1',
                              'em-transition-all',
                              {
                                'em-border-disabled-lighter hover:em-border-disabled-light': !isActive,
                                'em-border-success-600 em-bg-success-600 em-text-white': isCorrect,
                                'em-border-danger-light em-bg-danger-light em-text-white': isActive && !isCorrect,
                              },
                            )}
                          >
                            <div
                              className={classNames(
                                'em-w-9 em-h-9 em-rounded-full',
                                'em-text-white',
                                'em-flex em-min-w-[36px] em-max-w-[36px] em-items-center em-justify-center',
                                'em-gap-6 em-text-2xl !em-font-semibold',
                                {
                                  'em-bg-disabled': !isActive,
                                  '!em-text-success-600 em-bg-white': isCorrect,
                                  '!em-text-danger-light em-bg-white': isActive && !isCorrect,
                                },
                              )}
                            >
                              {String.fromCharCode(index + 65)}
                            </div>
                            <div
                              className={classNames('em-flex-grow em-text-left', 'em-text-2xl !em-font-semibold', {
                                'em-bg-success-600 em-text-white': isCorrect,
                                'em-bg-danger-light em-text-white': isActive && !isCorrect,
                              })}
                              dangerouslySetInnerHTML={{
                                __html: answer,
                              }}
                            />
                          </Buttonless>
                        )
                      })}
                    </div>
                    {question.explicacion && (
                      <div className='em-mt-10 em-border-solid em-bg-primary-light em-px-5 em-py-20 em-text-lg em-text-center'>
                        <h2 className='em-text-2xl !em-font-semibold !em-mt-0 !em-mb-5'>
                          <I18n t='game.currentChallenge.messages.showExplain' />
                        </h2>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: question.explicacion,
                          }}
                        />
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
          )}
          {status !== STATUS_FINISHED && currentQuestion !== null && currentChallenge?.preguntas && (
            <>
              <div className='em-text-sm em-mb-3'>
                {I18n.getTranslation({ pathname: '/' + locale }, 'game.quiz.pregunta')} {currentQuestion + 1}
              </div>
              <div
                className='em-text-3xl em-text-center em-mb-12 !em-font-semibold !em-leading-8'
                dangerouslySetInnerHTML={{
                  __html: currentChallenge.preguntas[currentQuestion].pregunta,
                }}
              />
              <div className='em-flex em-flex-col em-justify-start em-gap-6'>
                {currentChallenge.preguntas[currentQuestion].respuestas.map((answer, index) => {
                  const isAnswered = answersSelected.current[currentQuestion] !== undefined
                  const isActive = answersSelected.current[currentQuestion] === index
                  const isCorrect = index === currentChallenge.preguntas[currentQuestion].respuestacorrecta
                  return (
                    <Buttonless
                      onClick={() => {
                        if (status === STATUS_FINISHED || isAnswered) {
                          return
                        }
                        answersSelected.current[currentQuestion] = index
                        if (isCorrect) {
                          playCorrectSound()
                        } else {
                          playWrongSound()
                        }
                        forceUpdate()
                      }}
                      disabled={isAnswered}
                      key={`answer_${index}`}
                      className={classNames(
                        'em-flex em-items-center em-gap-3',
                        'em-border-2',
                        'em-rounded-md',
                        'em-min-h-[56px] em-px-3 em-py-1',
                        'em-transition-all',
                        {
                          'em-border-disabled-lighter hover:em-border-disabled-light': !isActive,
                          'em-border-success-600 em-bg-success-600 em-text-white': isAnswered && isCorrect,
                          'em-border-danger-light em-bg-danger-light em-text-white': isActive && !isCorrect,
                        },
                      )}
                    >
                      <div
                        className={classNames(
                          'em-w-9 em-h-9 em-rounded-full',
                          'em-text-white',
                          'em-flex em-min-w-[36px] em-max-w-[36px] em-items-center em-justify-center',
                          'em-gap-6 em-text-2xl !em-font-semibold',
                          {
                            'em-bg-disabled': !isActive,
                            'em-bg-white !em-text-success-600': isAnswered && isCorrect,
                            'em-bg-white !em-text-danger-light': isActive && !isCorrect,
                          },
                        )}
                      >
                        {String.fromCharCode(index + 65)}
                      </div>
                      <div
                        className={classNames('em-flex-grow em-text-left', 'em-text-2xl !em-font-semibold')}
                        dangerouslySetInnerHTML={{
                          __html: answer,
                        }}
                      />
                    </Buttonless>
                  )
                })}
                <div className='em-flex em-justify-end em-space-x-3'>
                  {answersSelected.current[currentQuestion] !== undefined &&
                    currentChallenge.preguntas[currentQuestion].explicacion && (
                      <button
                        className={classNames(
                          'em-border em-border-success-600 em-text-success-600 em-bg-white hover:em-shadow-xl hover:em-scale-105',
                          'em-transition-all',
                          'em-transform',
                          'em-p-3',
                          'em-outline-none',
                          'em-text-xl !em-font-semibold',
                          'em-rounded-lg em-shadow-lg',
                        )}
                        onClick={() => {
                          setStatus(STATUS_PAUSE)
                          setModal(
                            <Overflow
                              em-sticky
                              active={true}
                              className='em-z-[100]'
                            >
                              <Alert
                                className='!em-rounded-none !em-border-solid em-bg-primary-light !em-px-5 !em-py-20 !em-text-lg em-text-center'
                                onClose={() => {
                                  setPlaying()
                                }}
                              >
                                <h2 className='em-text-2xl !em-font-semibold !em-mt-0 !em-mb-5'>
                                  <I18n t='game.currentChallenge.messages.showExplain' />
                                </h2>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: currentChallenge.preguntas[currentQuestion].explicacion,
                                  }}
                                />
                              </Alert>
                            </Overflow>,
                          )
                        }}
                      >
                        <I18n t='game.currentChallenge.actions.showExplain' />
                      </button>
                    )}
                  <button
                    className={classNames(
                      {
                        'em-bg-primary em-text-white hover:em-shadow-xl hover:em-scale-105':
                          answersSelected.current[currentQuestion] !== undefined,
                      },
                      {
                        'em-text-disabled-light em-cursor-default':
                          answersSelected.current[currentQuestion] === undefined,
                      },
                      'em-transition-all',
                      'em-transform',
                      'em-px-10 em-py-3',
                      'em-outline-none',
                      'em-border-none',
                      'em-text-xl !em-font-semibold',
                      'em-rounded-lg em-shadow-lg',
                    )}
                    onClick={() => {
                      if (status === STATUS_FINISHED || answersSelected.current[currentQuestion] === undefined) {
                        return
                      }
                      const nextQuestion = currentQuestion + 1
                      if (nextQuestion >= currentChallenge.preguntas.length) {
                        setCurrentQuestion(null)
                        finishGame()
                      } else {
                        setCurrentQuestion(nextQuestion)
                      }
                    }}
                  >
                    {currentQuestion + 1 <= currentChallenge.preguntas.length - 1 ? (
                      <I18n t='game.currentChallenge.actions.next' />
                    ) : (
                      <I18n t='game.currentChallenge.actions.finish' />
                    )}
                  </button>
                </div>
              </div>

              <div className='em-items-center em-border-t em-mt-8 em-border-disabled-lighter em-text-sm em-space-x-2'>
                <div className='em-justify-center em-items-center em-pt-2 em-text-lg em-text-center em-mb-3 em-uppercase !em-font-semibold'>
                  <I18n t='game.yourAnswers' />
                </div>
              </div>
              <div className='em-flex em-justify-center em-items-center'>{printAnswers()}</div>
            </>
          )}
        </div>

        {modal}
      </Game>
    </Layout>
  )
}

CurrentChallenge.propTypes = {
  gameProps: propTypes.object,
  type: propTypes.string,
}

CurrentChallenge.defaultProps = {
  type: 'currentChallenge',
}
