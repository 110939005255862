import classNames from 'classnames'
import propTypes from 'prop-types'

export default function Buttonless(props) {
  return (
    <button
      id={props.id}
      className={classNames('em-transition-all !em-h-auto em-border-0', props.className)}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  )
}

Buttonless.propTypes = {
  disabled: propTypes.bool,
  className: propTypes.string,
  onClick: propTypes.func,
}
