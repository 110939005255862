import React from 'react'
import propTypes from 'prop-types'

import './Grid.css'

export default function Grid(props) {
  const { board, itemsActives, onClickLetter } = props
  let lastItem = null
  const click = (col, row, letter, e) => {
    if (itemsActives.length === 9) return false

    let item = { col: col, row: row, letter: letter }
    if (itemsActives.some((item) => item.col === col && item.row === row)) return false
    if (itemsActives.length > 0) lastItem = itemsActives[itemsActives.length - 1]
    if (
      lastItem === null ||
      (col === lastItem.col && row === lastItem.row + 1) ||
      (col === lastItem.col && row === lastItem.row - 1) ||
      (row === lastItem.row && col === lastItem.col + 1) ||
      (row === lastItem.row && col === lastItem.col - 1)
    ) {
      onClickLetter(item)
    }
  }

  return (
    <table
      role='grid'
      className='lc-table-responsive lc-table-principal'
    >
      <tbody>
        {board.map((item, y) => (
          <tr key={y}>
            {Array.from(item).map((letter, x) => (
              <td
                key={x}
                onClick={(e) => click(x, y, letter, e)}
                className={
                  itemsActives.filter(function (e) {
                    return e.col === x && e.row === y
                  }).length > 0
                    ? 'lc-table-prova lc-grid-text'
                    : 'lc-lc-table-prova-disabled lc-grid-text'
                }
              >
                {letter}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  )
}

Grid.propTypes = {
  board: propTypes.array.isRequired,
  itemsActives: propTypes.array.isRequired,
  onClickLetter: propTypes.func.isRequired,
}
