import React from 'react'
import classNames from 'classnames'
import propTypes from 'prop-types'
import { useClickAway } from 'react-use'

import MenuItem from 'components/Menu/MenuItem'

export default function Menu(props) {
  const { header, inverted, vertical, onClickOutside = () => {}, className, parentClassName } = props

  const ref = React.useRef(null)

  useClickAway(ref, onClickOutside)

  return (
    <nav
      ref={ref}
      className={classNames('!em-m-0 !em-w-auto', parentClassName)}
    >
      {header}
      <ul
        className={classNames(className, 'em-flex em-items-center', {
          'em-flex-col': vertical,
          'em-flex-row': !vertical,
        })}
      >
        {props.items.map((item, index) => (
          <MenuItem
            inverted={inverted}
            vertical={vertical}
            key={`menu_${item.href}_${index}`}
            className={classNames(props.itemClassName, item.className)}
            navItem={item}
          />
        ))}
      </ul>
    </nav>
  )
}

Menu.propTypes = {
  className: propTypes.string,
  items: propTypes.array,
  itemClassName: propTypes.string,
  inverted: propTypes.bool,
  vertical: propTypes.bool,
}
