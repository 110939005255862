import './DifferencesModalError.css'
import './DifferencesBoard.css'

export default function DifferencesModalError(props) {
  const onAnimationEnd = () => {
    props.showSuccessModal(false)
  }

  let classes =
    'em-flex em-absolute em-top-1/2 em-left-1/2 em-transform -em-translate-x-1/2 -em-translate-y-1/2 diff-modal-error diff-fade-out '

  return (
    <div
      className={classes}
      onAnimationEnd={onAnimationEnd}
    >
      {props.text}
    </div>
  )
}
