import { es } from './langs/es.js'
import { ar } from './langs/ar.js'
import { ca } from './langs/ca.js'
import { en } from './langs/en.js'
import { br } from './langs/br.js'
import { ge } from './langs/ge.js'
import { pl } from './langs/pl.js'
import { fr } from './langs/fr.js'
import { it } from './langs/it.js'

const resource = {
  es,
  ar,
  ca,
  en,
  br,
  ge,
  pl,
  fr,
  it,
}

export default resource
