import React from 'react'
import classNames from 'classnames'
import { isMobile } from 'react-device-detect'

import I18n from 'lang'
import { ReactComponent as VaJuegoIconAmarilloIcon } from 'assets/emoji-sunglasses-fill.svg'
import { ReactComponent as VaJuegoIconGrisIcon } from 'assets/emoji-sunglasses-nofil.svg'

import { STATUS_FINISHED, STATUS_INCOMPLETED, TRANSLATE_STATUS } from 'hooks/useGame'
import { isNameSite } from 'helpers'

export const LexiRetoFaces = ({ caras, showLabel = false }) => {
  const init = caras % 2 === 0 ? caras : caras - 1
  const end = caras % 2 === 0 ? caras + 1 : caras
  const i18n = `faces_${init}_${end}`

  const faces = (
    <div
      className={classNames('em-flex em-justify-between', {
        intermitentes: caras > 6,
      })}
    >
      <div>
        {caras >= 1 ? <VaJuegoIconAmarilloIcon className='em-w-full' /> : <VaJuegoIconGrisIcon className='em-w-full' />}
      </div>
      <div>
        {caras >= 2 ? <VaJuegoIconAmarilloIcon className='em-w-full' /> : <VaJuegoIconGrisIcon className='em-w-full' />}
      </div>
      <div>
        {caras >= 3 ? <VaJuegoIconAmarilloIcon className='em-w-full' /> : <VaJuegoIconGrisIcon className='em-w-full' />}
      </div>
      <div>
        {caras >= 4 ? <VaJuegoIconAmarilloIcon className='em-w-full' /> : <VaJuegoIconGrisIcon className='em-w-full' />}
      </div>
      <div>
        {caras >= 5 ? <VaJuegoIconAmarilloIcon className='em-w-full' /> : <VaJuegoIconGrisIcon className='em-w-full' />}
      </div>
      <div>
        {caras >= 6 ? <VaJuegoIconAmarilloIcon className='em-w-full' /> : <VaJuegoIconGrisIcon className='em-w-full' />}
      </div>
    </div>
  )

  if (showLabel) {
    return (
      <div className='em-flex em-flex-col em-space-y-4 em-justify-center'>
        <div className='em-text-3xl em-uppercase em-text-center em-mb-4 text-lexireto'>
          <I18n t={'game.LexiRetoGame.statusEndGame.' + i18n} />
        </div>
        {faces}
      </div>
    )
  }
  return faces
}

export default function LexiretoWord(props) {
  const ul_list = (arr, wordsUser = []) => {
    let ul_array = [...arr].map((item, index, { length }) => {
      let letrastart = item.substr(0, 1)

      let paraulauser = ''

      if (props._currentGameData.palabrasuser) {
        let palabrasstart = props._currentGameData.palabrasuser
        let exists = palabrasstart[letrastart].includes(item)

        if (!exists) {
          paraulauser = 'paraulanouser'
        }
      }

      let paraula = props._currentGameData.palabrasbenescrites[item]

      const wordWriteByUser = !!wordsUser.find((i) => i.replace('-', '') === item.replace('-', ''))

      if (props._currentGameData.heptacrack.includes(item)) {
        paraulauser += ' paraulaheptacrack em-font-bold em-text-[#39A351] em-uppercase'
        paraula += '(¡H!)'
      } else {
        if (!wordWriteByUser) {
          paraulauser += ' em-italic em-text-[#bf910f]'
        }
      }

      return (
        <span
          key={`item-${paraula}`}
          className={classNames(paraulauser)}
        >
          {paraula}
          {length - 1 !== index && ','}{' '}
        </span>
      )
    })
    return <div>{ul_array}</div>
  }

  const words = props._currentGameData.palabrasselectstart
  const wordstotales = props._currentGameData.palabrasstart
  const caras = props._currentGameData.caretas

  return (
    <div
      className={classNames('em-flex em-flex-col em-flex-grow-0 em-relative em-mx-auto em-px-4 em-max-w-sm em-w-full', {
        '!em-ml-[30px]': isNameSite(['italian', 'lavanguardia.com', 'THE NEWSPAPER']) && !isMobile,
      })}
    >
      <div
        className='em-uppercase em-font-bold titoltuspalabrascont em-mb-5'
        id=''
      >
        <I18n t='game.LexiRetoGame.comovatujuego' />
      </div>
      <div className='em-mb-6 em-w-full'>
        <LexiRetoFaces caras={caras} />
      </div>

      <div className='em-uppercase em-font-bold titoltuspalabrascont em-mb-5'>
        <I18n t='game.LexiRetoGame.marcador' />
      </div>

      <div className='em-grid em-grid-cols-3 em-space-x-3 em-mb-5'>
        <div className='em-flex-grow em-col-span-2'>
          <div className='em-text-sm em-text-center'>
            <I18n t='game.LexiRetoGame.tuspalabras' />
          </div>
          <div
            className={classNames(
              'em-bg-yellow-secondary em-text-black !em-font-semibold em-text-xl em-text-center em-py-1',
              {
                '!em-bg-[#89DE9C]': isNameSite(['italian', 'lavanguardia.com', 'THE NEWSPAPER']),
              },
            )}
          >
            {props._currentGameData.contaparaules}{' '}
            <span className='em-text-white'>/ {Object.keys(props._currentGameData.palabras).length}</span>{' '}
            <span>
              (
              {(
                (props._currentGameData.contaparaules * 100) /
                Object.keys(props._currentGameData.palabras).length
              ).toFixed(2)}{' '}
              %)
            </span>
          </div>
        </div>
        <div className='em-flex-grow'>
          <div className='em-text-sm em-text-center'>
            <I18n t='game.LexiRetoGame.heptacrackencontradas' />
          </div>
          <div
            className={classNames(
              'em-bg-yellow-secondary em-text-black !em-font-semibold em-text-xl em-text-center em-py-1',
              {
                '!em-bg-[#89DE9C]': isNameSite(['italian', 'lavanguardia.com', 'THE NEWSPAPER']),
              },
            )}
          >
            {props._currentGameData.nheptacrack}{' '}
            <span className='em-text-white'>/ {props._currentGameData.heptacrack.length}</span>
          </div>
        </div>
        {/* <div className='em-flex-grow'>
          <div className='em-text-sm em-text-center'>
            <I18n t='game.LexiRetoGame.puntuacion'/>
          </div>
          <div className='em-bg-yellow-secondary em-text-black !em-font-semibold em-text-xl em-text-center em-py-1'>
            {props._currentGameData.puntos}{' '}
            <span className='em-text-white'>
              / {props._currentGameData.totalpuntos}
            </span>
          </div>
        </div> */}
      </div>

      {Object.keys(words).map((key, row) => (
        <div
          key={`word-${key}`}
          className='em-py-3 em-border-t-2 em-border-dashed em-border-disabled-lighter'
        >
          <div className='em-grid em-grid-cols-5 em-py-0 '>
            <div className='em-font-bold titoltuspalabrascont_empiezan em-col-span-2'>
              <I18n t='game.LexiRetoGame.empiezanpor' />
              <span className='em-uppercase'>{key}</span>
            </div>
            <div className='titoltuspalabrascont_empiezan_contador em-col-span-3 em-text-right'>
              {words[key].length} / {wordstotales[key].length}
            </div>
          </div>
          <div className='paraules'>
            {TRANSLATE_STATUS[props._currentGameData.status] === STATUS_FINISHED ||
            TRANSLATE_STATUS[props._currentGameData.status] === STATUS_INCOMPLETED
              ? ul_list(wordstotales[key], words[key])
              : ul_list(words[key], words[key])}
          </div>
        </div>
      ))}
    </div>
  )
}
