import classNames from 'classnames'
import React from 'react'

function Cell({
  status = null,
  letter = '',
  delayIndex = 0,
  notFound = false,
  className = '',
  indexActive = false,
  lastCell = false,
  rowSelected = false,
  firstCell = false,
}) {
  const [initialized, setInitialized] = React.useState(false)
  React.useEffect(() => {
    setInitialized(true)
  }, [])

  return (
    <div
      className={classNames(
        'em-flip em-relative em-transition-all em-w-12 em-h-10 md:em-w-14 md:em-h-10',
        'em-flex em-items-center em-justify-center em-text-2xl md:em-text-3xl em-overflow-hidden !em-text-white',
        className,
        {
          'em-animate-vibrate': notFound,
          // 'em-border-primary em-border': indexActive,
        },
      )}
      style={{
        animationDelay: `${delayIndex / 10}s`,
      }}
    >
      <div
        className={classNames(
          'em-absolute em-border-l em-border-disabled-lighter em-front em-left-0 em-w-full em-top-0 em-h-full',
          'em-flex em-items-center em-justify-center em-text-2xl md:em-text-3xl !em-text-white',
          {
            //if last cell add right border or remove left border if row is selected
            'em-border-0': lastCell,
            'em-border-r': !rowSelected && lastCell,
            'em-border-l-0': rowSelected && firstCell,
          },
          className,
          {
            'em-active': ['wrong', 'exact', 'included']?.includes(status) && initialized,
            // 'em-bg-[#e6dada] em-border-white': rowSelected,
          },
        )}
        style={{
          transitionDelay: `${delayIndex / 10}s`,
        }}
      >
        {letter}
        {indexActive && (
          <svg
            className={classNames('em-w-full em-bg-white em-relative !em-top-[12px]', 'em-w-[13px]')}
            height={2}
            viewBox='0 0 100 1'
          >
            <rect
              width='100'
              stroke='white'
            />
          </svg>
        )}
      </div>
      <div
        className={classNames(
          'em-absolute em-back em-left-0 em-w-full em-top-0 em-h-full',
          'em-flex em-items-center em-justify-center em-text-2xl md:em-text-3xl !em-text-white',
          'em-border-l em-border-disabled-lighter',
          className,
          {
            'em-animate-vibrate': notFound,
            '!em-text-gray-400': status === 'wrong',
            '!em-text-[#5FC66E]': status === 'exact',
            '!em-text-[#F0C21C]': status === 'included',
            'em-active': ['wrong', 'exact', 'included']?.includes(status) && initialized,
            'em-border-r': lastCell,
          },
        )}
        style={{
          transitionDelay: `${delayIndex / 10}s`,
        }}
      >
        {letter}
      </div>
    </div>
  )
}

export default Cell
