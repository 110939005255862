import './TilesSuccessModal.css'
import './TilesBoard.css'
import I18n from '../../../lang'

export default function TilesSuccessModal(props) {
  let classes =
    'em-absolute em-top-1/2 em-left-1/2 em-transform -em-translate-x-1/2 -em-translate-y-1/2 tiles-success-modal tiles-fade-out '
  classes += props.success ? 'tiles-success-modal-ok' : 'tiles-success-modal-ko'

  return (
    <div className={classes}>
      <I18n t={props.success ? 'game.tilesGame.successfullyPaired' : 'game.tilesGame.incorrectlyPaired'} />
    </div>
  )
}
