import React from 'react'
import { useClickAway } from 'react-use'

export default function Message(props) {
  const ref = React.useRef(null)
  useClickAway(ref, () => {
    if (props?.onClose) {
      props.onClose()
    }
  })

  if (!props.active) {
    return null
  }

  return (
    <div
      ref={ref}
      className='em-p-2 sm:em-p-9 em-uppercase em-text-black em-bg-yellow em-text-lg sm:em-text-2xl em-text-center'
    >
      {props.children}
    </div>
  )
}
