import classNames from 'classnames'
import Loader from 'components/Loader'

export default function Layout(props) {
  const { isLoading, className } = props

  return (
    <>
      <main className={classNames('em-flex', 'em-flex-col', className)}>{props.children}</main>
      <Loader
        className='em-bg-white em-bg-opacity-90'
        center
        active={isLoading}
      />
    </>
  )
}
