const LexiRetoGameLocaleES = {
  expirationDate: 'día de hoy a las 23:59:59',
  title: 'Lexi Reto',
  resolveGame: 'Resolver',
  restartGame: 'Reiniciar',
  howToPlay: 'Cómo se juega',
  howToPlayText: 'Cómo se juega',
  howToPlayTextFull: () => (
    <>
      <div className='mb-1'>
        <strong>Cómo se juega</strong>
      </div>
      <ul>
        <li>
          Forma palabras de al menos 3 letras. Puedes repetir las letras, pero siempre incluyendo la letra central.
        </li>
        <li>No se admiten nombres propios, plurales y formas verbales conjugadas (solo infinitivos).</li>
        <li>
          Encuentra palabras que incluyan las 7 letras (¡Heptacrack!) y subirás de posición en Cómo va tu juego y
          mejorarás tus estadísticas.
        </li>
        <li>
          Puntuación: las palabras de 3 letras dan 1 punto y las de 4 letras, 2 puntos. A partir de 5 letras, obtendrás
          tantos puntos como letras tenga la palabra. Los heptacracks valen 10 puntos.
        </li>
        <li>
          ATENCIÓN. Solo podrás resolver los lexi retos durante el día en que se publican. A las 12 de la noche quedan
          inactivos y solo se pueden consultar.
        </li>
      </ul>
    </>
  ),
  totalpalabras: 'Tus palabras',
  tuspalabras: 'Tus palabras',
  empiezanpor: 'Empiezan por: ',
  encontradas: 'Encontradas ',
  heptacrackencontradas: 'Heptacracks',
  comovatujuego: 'Cómo va tu juego',
  comovatujuego_heptacrack: '¡Heptacrack!',
  comovatujuego_nuevonivel: '¡Subes posición!',
  marcador: 'Marcador',
  puntuacion: 'Tus puntos',
  maximo: 'Máximo',
  encontrados: 'Encontrados',
  actions: {
    apply: 'Aplicar',
    clean: 'Borrar',
    reordenar: 'Reordenar',
  },
  messages: {
    correctword: '¡Bien!',
    wrongword: 'No encontrada',
    repeatedword: 'Ya encontrada',
    minimo3letras: 'Mínimo 3 letras',
    faltaletracentral: ' Falta la letra central',
  },
  homenuevo: 'NUEVO',
  statusEndGame: {
    faces_6_7: '¡EXTRAORDINARIO!',
    faces_4_5: '¡EXCELENTE!',
    faces_2_3: '¡BUEN NIVEL!',
    faces_0_1: '¡PUEDES MEJORAR!',
  },
}

export default LexiRetoGameLocaleES
