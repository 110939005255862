import React from 'react'

import { isDesktop, isMobile } from 'react-device-detect'
import classNames from 'classnames'

import Cell from './Cell'
import { getStatuses } from './lib/statuses'
import { RowComponent } from './BasicRowComponent'
import { BackgroundRowComponent } from './BackgroundRowComponent'
import { isNameSite } from 'helpers'

function Grid({
  notFound = false,
  finished = false,
  attempts,
  solution,
  currentWord,
  cellComponent: CellComponent = Cell,
}) {
  return (
    <div className='em-flex em-flex-col em-space-y-2'>
      {attempts?.map((attempt, wIndex) => {
        const statuses = getStatuses(attempt, solution)
        return (
          <>
            <div
              key={`words_row_${wIndex}`}
              className='em-flex em-space-x-1 em-justify-center em-perspective em-items-center'
            >
              <span
                className={classNames(
                  'em-bg-disabled em-w-5 em-h-5 em-rounded-full em-text-white em-justify-center em-items-center em-flex em-mr-1 sm:em-mr-5',
                  {
                    '!em-bg-[#F5F5F5] !em-text-[#AAAAAA]': isNameSite(['italian', 'lavanguardia.com', 'THE NEWSPAPER']),
                  },
                )}
              >
                {wIndex + 1}
              </span>
              <div
                className={classNames('em-flex em-flex-row em-items-center', {
                  'em-space-x-1': isNameSite(['italian', 'lavanguardia.com', 'THE NEWSPAPER']),
                })}
              >
                {attempt.split('').map((letter, lIndex) => (
                  <CellComponent
                    key={`words_cell_${wIndex}_${lIndex}`}
                    delayIndex={lIndex}
                    status={statuses[lIndex]}
                    letter={letter}
                    lastCell={lIndex === solution.length - 1}
                  />
                ))}
              </div>
            </div>
            {isNameSite(['italian', 'lavanguardia.com', 'THE NEWSPAPER']) && (
              <svg
                className={classNames('em-w-full em-bg-disabled-lighter', {
                  '!em-w-[237px] !em-ml-[57px]': isMobile,
                  '!em-w-[53%] em-ml-[147px]': isDesktop,
                })}
                height={1}
                viewBox='0 0 100 1'
              >
                <rect
                  width='100'
                  stroke='gray'
                  opacity='20%'
                />
              </svg>
            )}
          </>
        )
      })}
      {Array(6 - attempts.length)
        .fill()
        .map((_, index) => {
          const wIndex = attempts.length + index
          return isNameSite(['italian', 'lavanguardia.com', 'THE NEWSPAPER'])
            ? BackgroundRowComponent(notFound, finished, attempts, solution, currentWord, index, wIndex, CellComponent)
            : RowComponent(notFound, finished, attempts, solution, currentWord, index, wIndex, CellComponent)
        })}
    </div>
  )
}

export default Grid
