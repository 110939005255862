import React from 'react'
import moment from 'moment'
import classNames from 'classnames'
import Slider from 'react-slick'
import { Link } from 'react-router-i18n'
import { useWindowSize, useMedia } from 'react-use'
import propTypes from 'prop-types'

import gamesApi from 'api'
import I18n from 'lang'
import { ReactComponent as ArrowRightCircleIcon } from 'assets/arrow-right-circle.svg'
import { ReactComponent as ArrowLeftCircleIcon } from 'assets/arrow-left-circle.svg'

import './index.scss'

const NextArrow = (props) => {
  const { style, className, onClick } = props
  return (
    <div
      onClick={onClick}
      className={classNames(className, 'em-text-disabled hover:em-text-primary em-transition-all')}
      style={style}
    >
      <ArrowRightCircleIcon className='em-w-full' />
    </div>
  )
}
const PrevArrow = (props) => {
  const { style, className, onClick } = props
  return (
    <div
      onClick={onClick}
      className={classNames(className, 'em-text-disabled hover:em-text-primary em-transition-all')}
      style={style}
    >
      <ArrowLeftCircleIcon className='em-w-full' />
    </div>
  )
}

/**
 *
 * @typedef {Object} HistoricalGames
 * @param {Object} props
 * @param {string} url
 * @param {string} to
 * @param {number} activeId
 * @param {bool} active
 * @param {element} icon
 *
 * @returns {React.Component}
 */
export default function HistoricalGames(props) {
  const [historicalGames, setHistoricalGames] = React.useState([])
  const { url, to, activeId, active, icon } = props

  // const autoScrollRef = React.useCallback(
  //   node => {
  //     node?.scrollTo(node.scrollWidth, 0)
  //   },
  //   [],
  // )

  const { width } = useWindowSize()
  const isMobile = useMedia('(max-width: 768px)')

  // const slider = React.useCallback(
  //   node => {
  //     if (node && 'slickGoTo' in node) {
  //       node.slickGoTo(historicalGames.length - 5)
  //     }
  //   },
  //   [historicalGames],
  // )

  React.useEffect(() => {
    const fetchHistoricalGames = async () => {
      const response = await gamesApi.get(url)
      const data = response.data

      setHistoricalGames(
        data.map((item) => ({
          id: item.id,
          date: moment(item.publicado).format('ddd DD.MM.YY'),
          statusLabel: item.estadouser?.texto,
          status: item.estadouser?.id,
        })),
      )
    }

    fetchHistoricalGames()
  }, [url, active]) // eslint-disable-line

  let amountOfSlides = Math.min(parseInt(width / 240), 5)

  const settingsSlider = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: amountOfSlides,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  }

  const games = historicalGames.map((item, index) => (
    <div
      key={`historicalgames_${item.id}_${index}`}
      className='em-min-w-max'
    >
      <div className='item'>
        <div className='divider' />
        <Link
          className={classNames(
            'em-pr-5 em-pl-5 em-py-5',
            'em-flex em-space-x-5',
            'em-cursor-pointer',
            'group hover:em-text-primary',
            'em-transition-all',
            { 'em-active em-text-primary': activeId === item.id },
          )}
          to={`/${to}/${item.id}`.replace(/\/+/g, '/')}
        >
          <img
            src={icon}
            alt='icon'
            className='em-object-contain caption'
          />

          <div
            className={classNames(
              'em-flex em-flex-col em-flex-nowrap em-text-sm em-leading-4',
              'group-hover:em-underline',
              { 'em-text-primary': activeId === item.id },
            )}
          >
            <h3 className='!em-font-semibold em-text-lg !-em-mt-1 !em-m-0'>
              {![2, 3].includes(item.status) && activeId === item.id ? (
                <I18n t='game.historicalGames.playing' />
              ) : (
                item.statusLabel
              )}
            </h3>
            {item?.time && <span>{item.time}</span>}
            <span className='em-capitalize'>{item.date}</span>
          </div>
        </Link>
      </div>
    </div>
  ))

  return (
    <div className={classNames('em-w-full', 'em-overflow-hidden em-bg-white', 'historical-games')}>
      {isMobile ? (
        <div className='em-flex em-overflow-x-auto em-whitespace-nowrap slick-slide-mobile'>{games}</div>
      ) : (
        <div className='em-px-12'>
          <Slider {...settingsSlider}>{games}</Slider>
        </div>
      )}
    </div>
  )
}

HistoricalGames.propTypes = {
  url: propTypes.string.isRequired,
  to: propTypes.string.isRequired,
  activeId: propTypes.number,
  active: propTypes.bool,
  icon: propTypes.element.isRequired,
}
