export function LexiretoCell(props) {
  return (
    <>
      <div className='em-relative em-flex em-items-center em-justify-center em-h-12 em-px-3 em-space-x-3 em-bg-primary-games-light'>
        {props.alert}
        <div className='em-text-center em-text-[#2D7BB9] em-text-3xl'>
          <div className='em-flex-grow em-text-center em-uppercase'>{props.paraula}</div>
        </div>
      </div>
      <div className='em-p-4 em-m-auto lexireto-container'>
        <ul id='hexGrid'>
          <li className='hex'>
            <div className='hexIn'>
              <div
                onClick={props.onClickLetter}
                className='hexLink'
              >
                {props._currentGameData.letters[0]}
              </div>
            </div>
          </li>
          <li className='hex'>
            <div className='hexIn'>
              <div
                onClick={props.onClickLetter}
                className='hexLink'
              >
                {props._currentGameData.letters[1]}
              </div>
            </div>
          </li>
          <li className='hex'>
            <div className='hexIn'>
              <div
                onClick={props.onClickLetter}
                className='hexLink'
              >
                {props._currentGameData.letters[2]}
              </div>
            </div>
          </li>
          <li className='hex'>
            <div className='hexIn'>
              <div
                onClick={props.onClickLetter}
                className='hexLink'
                id='center-letter'
              >
                {props._currentGameData.letracentral}
              </div>
            </div>
          </li>
          <li className='hex'>
            <div className='hexIn'>
              <div
                onClick={props.onClickLetter}
                className='hexLink'
              >
                {props._currentGameData.letters[4]}
              </div>
            </div>
          </li>
          <li className='hex'>
            <div className='hexIn'>
              <div
                onClick={props.onClickLetter}
                className='hexLink'
              >
                {props._currentGameData.letters[5]}
              </div>
            </div>
          </li>
          <li className='hex'>
            <div className='hexIn'>
              <div
                onClick={props.onClickLetter}
                className='hexLink'
              >
                {props._currentGameData.letters[6]}
              </div>
            </div>
          </li>
        </ul>
      </div>
    </>
  )
}
