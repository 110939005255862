import React from 'react'
import moment from 'moment'
import propTypes from 'prop-types'

import classNames from 'classnames'
import I18n from 'lang'
import { ReactComponent as Play } from 'assets/play-fill.svg'
import { ReactComponent as Pause } from 'assets/pause-fill.svg'
import { STATUS_FINISHED } from 'layouts/Game'

export default function Timer(props) {
  const { status, setPause, setPlaying, time } = props
  const [hideTime, setHideTime] = React.useState(false)

  React.useEffect(() => {
    if (status === STATUS_FINISHED) {
      setHideTime(false)
    }
  }, [status])

  return (
    <div className={classNames('em-flex em-items-center em-space-x-4 em-transition-all em-text-disabled em-mx-5')}>
      <span className={classNames('em-text-lg sm:em-text-2xl em-min-w-max')}>
        {hideTime ? '- - : - -' : moment(time * 1000).format('mm:ss')}
      </span>
      {status !== 'finished' && (
        <>
          {status === 'playing' ? (
            <Pause
              onClick={setPause}
              className='hover:em-text-primary em-transition-all em-cursor-pointer'
            />
          ) : (
            <Play
              onClick={setPlaying}
              className='hover:em-text-primary em-transition-all em-cursor-pointer'
            />
          )}
          <span
            className='hover:em-text-primary em-text-sm em-leading-4 em-transition-all em-cursor-pointer'
            onClick={() => setHideTime(!hideTime)}
          >
            {!hideTime ? <I18n t='game.timer.hide' /> : <I18n t='game.timer.show' />}
          </span>
        </>
      )}
    </div>
  )
}

Timer.propTypes = {
  status: propTypes.string.isRequired,
  setPause: propTypes.func.isRequired,
  setPlaying: propTypes.func.isRequired,
  time: propTypes.number.isRequired,
}
