import React from 'react'
import { useClickAway } from 'react-use'

export default function ClickOutside(props) {
  const ref = React.useRef(null)

  useClickAway(ref, props.onClickOutside)

  return <div ref={ref}>{props.children}</div>
}
