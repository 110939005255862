const TilesGameLocaleCA = {
  title: 'Parelles',
  totalPairs: 'Total parelles',
  resolvedPairs: 'Les meves parelles',
  marker: 'Marcador',
  resolveGame: 'Resoldre',
  restartGame: 'Reiniciar',
  howToPlay: 'Com es juga',
  howToPlayText:
    "Aparella les rajoles idèntiques fins a completar el panell abans que s'esgoti el temps. Disposes de 4 minuts! Fixa’t-hi bé, algunes rajoles poden estar girades.",
  howToPlayTextFull: () => (
    <>
      <strong>Com es juga</strong>
      <br />
      Aparella les rajoles idèntiques fins a completar el panell abans que s'esgoti el temps. Disposes de 4 minuts!
      Fixa’t-hi bé, algunes rajoles poden estar girades.
    </>
  ),
  incorrectlyPaired: 'Parella incorrecta!',
  successfullyPaired: 'Parella correcta!',
  messages: {
    init: 'Tens 4 minuts per acabar',
    '3m': 'Et queden 3 minuts',
    '2m': 'Et queden 2 minuts',
    '1m': 'Et queda 1 minut',
    '30s': 'Et queden 30 segons',
    '10s': 'Et queden 10 segons',
  },
  incomplete: {
    title: 'LLÀSTIMA!',
    message: 'Has esgotat el temps',
  },
}

export default TilesGameLocaleCA
