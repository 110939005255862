import { IoMdShare } from 'react-icons/io'

import LetrasConectadasES from 'scenes/LetrasConectadas/locales/es/translation.js'
import TilesGameLocaleES from 'scenes/Tiles/locales/es/translation.js'
import DifferencesGameLocaleES from 'scenes/Differences/locales/es/translation.js'
import LexiRetoGameLocaleES from 'scenes/Lexireto/locales/es/translation.js'

export const ar = {
  gameTitles: {
    crucigrama: 'Crucigrama',
    lexireto: 'Lexireto',
    diferencias: 'Diferencias',
    sudokus: 'Sudokus',
    'sudoku-facil': 'Sudoku facil',
    'sudoku-medio': 'Sudoku medio',
    'sudoku-avanzado': 'Sudoku avanzado',
    'mini-sudoku': 'Mini sudoku',
    quiz: 'Quiz',
    'quiz-historia-y-geografia': 'Historia y geografia',
    'quiz-lengua-y-literatura': 'Lengua y literatura',
    'quiz-deportes': 'Deportes',
    'quiz-ciencia': 'Ciencia',
    'quiz-arte-y-espectaculos': 'Arte y espectaculos',
    letras: 'Letras',
    'reto-actualidad': 'Reto actualidad',
    baldosas: 'Baldosas',
    'mots-encreuats': 'Mots encreuats',
    cruciveloz: 'Cruciveloz',
    fastcrossword: 'Fastcrossword',
  },
  close: 'Cerrar',
  by: 'Por',
  play: 'Jugá',
  game: {
    historicalGames: {
      playing: 'EN JUEGO',
    },
    new: 'Nuevo',
    actions: {
      save: 'Guardar',
      download: 'Descargar',
      exit: 'Salir',
      help: 'Ayuda',
    },
    timer: {
      show: 'Mostrar',
      hide: 'Ocultar',
    },
    yourPoints: 'Tus Puntos',
    heptacracks: 'Heptacracks',
    yourWords: 'Tus palabras',
    yourAnswers: 'Tus respuestas',
    send: 'Enviar',
    tusclics: 'Tus clics',
    messages: {
      init: {
        message: '¿Estás preparado para empezar?',
        button: 'Jugar',
      },
      pause: {
        message: 'El juego está en pausa',
        button: 'Continuar',
      },
      restart: {
        message: 'Estás a punto de volver a empezar el juego',
        buttonPrimary: 'Reiniciar',
        buttonCancel: 'Volver',
      },
      resolve: {
        message:
          'Si eliges RESOLVER, este juego quedará reflejado como incompleto en Mis estadísticas. ¿Estás conforme?',
        buttonPrimary: 'Si',
        buttonCancel: 'No',
      },
      save: {
        message: 'Estás a punto de salir del juego. ¿Quieres guardar los cambios?',
        buttonPrimary: 'Si',
        buttonCancel: 'No',
      },
      errorSaving: {
        message: 'Error al guardar. Intentelo nuevamente.',
        button: 'Cerrar',
      },
      errorRestarting: {
        message: 'Error al reiniciar el juego. Intentelo nuevamente.',
        button: 'Cerrar',
      },
      errorResolving: {
        message: 'Error al resolver el juego. Intentelo nuevamente.',
        button: 'Cerrar',
      },
      errorFinishing: {
        message: 'Error al finalizar el juego. Intentelo nuevamente.',
        button: 'Cerrar',
      },
      errorChecking: {
        message: '¡Lástima! No has completado el juego correctamente. Sigue probando.',
        button: 'Cerrar',
      },
      finished: {
        message: 'Ya has completado este juego. Solo puedes consultarlo.',
        button: 'Volver',
      },
      complete: {
        title: '¡Bravo!',
        endGameTitle: '¡JUEGO TERMINADO!',
        averageTime: 'Tiempo promedio general',
        percentageGeneralCompleted: 'Porcentaje general completados',
        completedTime: 'Juego completado en',
        share: 'Comparte tus resultados',
        shortDescription: 'Comprueba si has mejorado en Mis estadísticas.',
        description: 'Has terminado el juego. Comprueba si has mejorado en Mis estadísticas.',
        button: 'Cerrar',
        onDate: (date) => <>Juego del {date}</>,
      },
      incomplete: {
        message: 'Aquí tienes el juego resuelto. En Mis estadísticas se contabilizará como incompleto.',
      },
      past: {
        message: 'Juego terminado. Solo puedes consultarlo.',
        button: 'Volver',
      },
      saved: {
        // message: 'Ya no es necesario utilizar el botón Guardar. Cada vez que hagas un avance, el juego quedará automáticamente guardado.',
        message: 'El juego se ha guardado correctamente',
        button: 'Salir',
      },
      savedlexireto: {
        message: ({ date }) => <>El juego se ha guardado correctamente.</>,
        button: 'Salir',
      },
    },
    currentChallenge: {
      actions: {
        next: 'Siguiente',
        showExplain: 'Ver explicación',
        finish: 'Finalizar',
      },
      messages: {
        finish: {
          title: '¡Test completado!',
          description: ({ corrects, errors }) => (
            <>
              Respuestas correctas {corrects}, incorrectas {errors}.<br />
              Puedes revisar en Mis estadísticas los resultados acumulados.
            </>
          ),
          button: 'Cerrar',
        },
        showExplain: 'El porqué de la respuesta',
      },
      statusEndGame: {
        good: '¡BRAVO!',
        neutral: '¡BIEN!',
        bad: '¡PUEDES MEJORAR!',
      },
    },
    crosswords: {
      questions: 'Preguntas Crucigrama',
      horizontals: 'Horizontales',
      verticals: 'Verticales',
      horizontal: 'Horizontal',
      vertical: 'Vertical',
      messages: {
        finish: {
          title: '¡Bravo!',
          description: 'Vas acabar els teus mots encreuats.\nComprova si vas millorar en les estadístiques.',
          button: 'Salir',
        },
        incomplete: {
          title: '',
          description:
            'Aquí tens els mots encreuats resolts. A Les meves estadístiques es comptabilitzaran com a incomplets.',
          button: 'Salir',
        },
      },
      actions: {
        checkLetter: 'Comprobar letra',
        checkWord: 'Comprobar palabra',
        checkCrossword: ({ gameName }) => <>Comprobar {gameName}</>,
        resolveCrossword: 'Resolver',
        restartCrossword: 'Reiniciar',
      },
    },
    sudoku: {
      candidates: 'Posibles',
      normal: 'Normal',
      levels: {
        easy: 'Básico',
        medium: 'Avanzado',
        hard: 'Experto',
      },
      messages: {},
      actions: {
        checkCell: 'Comprobar casilla',
        checkSudoku: ({ gameName }) => <>Comprobar {gameName}</>,
        resolveSudoku: 'Resolver',
        restartSudoku: 'Reiniciar',
      },
    },
    letrasConectadasGame: LetrasConectadasES,
    tilesGame: TilesGameLocaleES,
    differencesGame: DifferencesGameLocaleES,
    LexiRetoGame: LexiRetoGameLocaleES,

    buttons: {
      statistics: 'Mis estadísticas',
      share: () => (
        <>
          <IoMdShare /> <span>Compartir</span>
        </>
      ),
      shareLabel: 'Compartir',
    },
    words: {
      howToPlay: 'Cómo se juega',
      howToPlayContent:
        'Descubre la palabra oculta en 6 intentos. Cada intento debe ser una palabra válida de 5 letras. Después de cada intento el color de las letras te ofrece pistas sobre la palabra oculta. No se admiten nombres propios, plurales y formas verbales conjugadas (solo intinitivos)',
      examples: {
        title: 'Ejemplos',
        first: {
          word: 'COBRE',
          explanation: 'La letra C está en la palabra y en la posición correcta.',
        },
        second: {
          word: 'MARZO',
          explanation: 'La letra R está en la palabra pero en la posición incorrecta.',
        },
        third: {
          word: 'VERDE',
          explanation: 'La letra E no está en la palabra.',
        },
      },
      messages: {
        filaincompleta: 'Fila Incompleta',
        noencontrada: '¡No encontrada!',
        palabracorrecta: 'La palabra correcta es:',
      },
      endGame: {
        puedesMejorar: '¡PUEDES MEJORAR!',
        insuperable: '¡INSUPERABLE!',
        fantástico: 'FANTÁSTICO!',
        excelente: '¡EXCELENTE!',
        bravo: '¡BRAVO!',
      },
      footer: '¡UNA PALABRA NUEVA CADA DÍA!',
      disclaimer:
        'La Palabra es un una adaptación de Mind training del juego original creado por Josh Wardle (@powerlanguish)',
      fourLetters: 'THE HIDDEN WORD 4',
      fiveLetters: 'THE HIDDEN WORD 5',
      sixLetters: 'THE HIDDEN WORD 6',
      choose: 'Elige',
    },
    quiz: {
      pregunta: 'Pregunta',
    },
  },
}
