import './DifferencesModalSuccess.css'
import './DifferencesBoard.css'
import I18n from '../../../lang'

export default function DifferencesModalSuccess(props) {
  const onAnimationEnd = () => {
    props.showSuccessModal(false, false)
  }

  let classes =
    'em-absolute em-top-1/2 em-left-1/2 em-transform -em-translate-x-1/2 -em-translate-y-1/2 diff-modal-success diff-fade-out'

  return (
    <div
      className={classes}
      onAnimationEnd={onAnimationEnd}
    >
      <I18n t='game.differencesGame.successfullyHit' />
    </div>
  )
}
