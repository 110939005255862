import React from 'react'
import { useParams } from 'react-router-dom'
import { useLocation } from 'react-use'
import useGameInfoStore from 'store/game'

export default function Title(props) {
  const { by, date, menu } = props

  const location = useLocation()
  const { locale, id } = useParams()
  const gameInfoStore = useGameInfoStore()

  const pathname = location.pathname.replace(`/${id}`, '')
  const pathArray = pathname?.split('/')
  const index = pathArray.indexOf(locale)
  const path = pathArray.splice(index + 1, pathArray.length).join('/')

  const currentGame = gameInfoStore?.items?.find((game) => game.url === '/' + path)

  if (!currentGame) {
    return null
  }

  return (
    <div
      className='em-flex em-items-center em-py-4 em-text-center em-text-white em-border-b  sm:em-border-0 lg:em-py-5 em-bg-defaultGame'
      style={{
        backgroundColor: currentGame?.color,
      }}
    >
      <div className='em-flex-grow'>
        <h2 className='em-text-3xl sm:em-text-4xl em-mb-3 !em-font-semibold titulojuego'>{currentGame.title}</h2>
        <p className='-em-mt-2 em-text-sm em-text-center em-text-white'>
          {by && <span className='!em-font-semibold em-hidden sm:em-inline'>{by} </span>}
          <span className='em-text-sm em-capitalize'>{date}</span>
        </p>
      </div>
      {menu && <div>{menu}</div>}
    </div>
  )
}
