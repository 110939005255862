import React from 'react'
import { useParams } from 'react-router'
import moment from 'moment'

import Header from 'layouts/Header'
import Layout from 'layouts/Layout'
import { AlertButton } from 'layouts/Game/messages'
import Game from 'layouts/Game'
import Timer from 'components/Timer'
import Grid from './board/Grid'
import Words from './board/Words'
import Word from './board/Word'
import I18n from 'lang'
import useGame, { STATUS_PLAYING, STATUS_FINISHED } from 'hooks/useGame'
import gamesApi, { gamesApiBase, getUserId } from 'api'

import { ReactComponent as EmojiSmile } from 'assets/emoji-smile-fill.svg'

import './index.scss'
import useSound from 'hooks/useSound'

export default function LetrasConectadas(props) {
  // Props and params
  const { gameProps } = props
  const { id = 'last', locale } = useParams()

  const { playCorrectSound, playWrongSound, playSuccessSound } = useSound()

  const {
    title,
    color: bgColor,
    api: { basepath: baseUrlApi },
    icon: { big: bigIconSrc },
    url: baseUrl,
  } = gameProps

  // States
  const [gameData, setGameData] = React.useState({})
  const [isLoading, setIsLoading] = React.useState(true)
  const [statistics, setStatistics] = React.useState([])
  const [_board, _setBoard] = React.useState([])
  const [_checkwords, _setCheckWords] = React.useState({})
  const [isResolved, setIsResolved] = React.useState(false)
  const [_showHowToPlayMessage, _setShowHowToPlayMessage] = React.useState(false)
  const [state, setState] = React.useState({
    letters: [],
    words: [],
    found: [],
    itemsActives: [],
  })

  // Api Endpoints
  const singleUrl = `${baseUrlApi}/get/${id}`

  // Usegame
  const {
    initGame,
    status,
    time,
    setPause,
    setPlaying,
    restart,
    resolve,
    finish,
    save,
    autoSave,
    modal,
    message,
    setShowEndGameModal,
    showEndGameModal,
  } = useGame({
    startFrom: parseInt(gameData?.estadouser?.tiempo || 0, 10),
    statusInit: gameData?.estadouser?.id,
    expirationDate: gameData?.despublicado,
    locale,
  })

  React.useEffect(() => {
    if (status === STATUS_PLAYING) {
      saveGame(false, null, true)
    }
  }, [state.found, state.letters, state.itemsActives, state.words, status]) // eslint-disable-line react-hooks/exhaustive-deps

  // Api game comunication
  const restartGame = async () => {
    try {
      await restart(baseUrlApi, {
        gameid: gameData.id,
      })
      await initGame(fetchLetras)
    } catch (error) {}
  }

  const resolveGame = async () => {
    try {
      await resolve(baseUrlApi, {
        gameid: gameData.id,
        gamedata: {
          tiempo: time,
        },
      })
      setIsResolved(true)
      //mapWords(state.words, _checkwords); //Show finished words and triggers finish
      await fetchLetras()
    } catch (error) {}
  }

  // validateGame not needed (there's nothing to validate)

  const finishGame = async () => {
    setIsLoading(true)
    const words = {}
    for (let key in state.words) {
      words[`palabra${key}`] = state.words[key].filter((item) => item !== undefined)
    }

    try {
      await finish(baseUrlApi, {
        gameid: gameData.id,
        gamedata: {
          tiempo: time,
          palabras: words,
        },
      })
      await fetchLetras()
    } catch (error) {}

    setIsLoading(false)
  }

  const saveGame = async (exit = false, newUrl = null, auto = false) => {
    const words = {}
    for (let key in state.words) {
      words[`palabra${key}`] = state.words[key].filter((item) => item !== undefined)
    }

    const fnc = auto ? autoSave : save

    try {
      await fnc(
        baseUrlApi,
        {
          gameid: gameData.id,
          gamedata: {
            tiempo: time,
            palabras: words,
          },
        },
        exit,
        newUrl,
      )
    } catch (error) {}

    fetchStatistics()
  }

  const mapWords = (data, listWords = []) => {
    let objWords = {}
    for (let key in data) {
      objWords[key] = new Array(data[key].length)
      for (let i = 0; i < data[key].length; i++) {
        // Create the word beggining with the first letter + _ _ _
        //  let strEmpty = Array.from(new Array(parseInt(key)).join("_")).join("\u00a0");
        let strEmpty = Array.from(new Array(parseInt(key)).join('_')).join('')
        // objWords[key][i] = data[key][i].charAt(0)+ "\u00a0" + strEmpty
        objWords[key][i] = data[key][i].charAt(0) + strEmpty
      }
    }
    let deleteWords = []
    for (let w of listWords) {
      let key = w.length
      if (objWords.hasOwnProperty(key)) {
        let isSetWord = false
        for (let i = 0; i < objWords[key].length; i++) {
          // Check if the word starts with the same letter and ends with _
          if (
            objWords[key][i].charAt(0) === w.charAt(0) &&
            objWords[key][i].charAt(objWords[key][i].length - 1) === '_'
          ) {
            objWords[key][i] = w
            isSetWord = true
            break
          }
        }
        if (!isSetWord) deleteWords.push(w)
      }
    }
    for (let w of deleteWords) {
      listWords.splice(listWords.indexOf(w), 1)
    }
    setState({
      ...state,
      words: objWords,
      found: listWords,
      letters: [],
      itemsActives: [],
    })
  }

  // Events
  const _onClickLetter = (item) => {
    if (status === STATUS_PLAYING) {
      setState({
        ...state,
        letters: [...state.letters, item.letter],
        itemsActives: [...state.itemsActives, item],
      })
    }
  }

  const _onClickAply = (word) => {
    if (status === STATUS_PLAYING && !state.found.includes(word) && _checkwords.includes(word)) {
      document.querySelector('#lc-modalOK').classList.add('show')
      document.querySelector('.lc-table-principal').classList.add('resultOK')
      mapWords(state.words, [...state.found, word])
      playCorrectSound()
      setTimeout(() => {
        document.querySelector('#lc-modalOK').classList.remove('show')
        document.querySelector('.lc-table-principal').classList.remove('resultOK')
      }, 1000)
    } else {
      playWrongSound()
      // ERROR
      let modal = '#lc-modalKO'
      if (state.found.includes(word)) {
        modal = '#lc-modalKO2'
      }
      document.querySelector(modal).classList.add('show')
      document.querySelector('.lc-table-principal').classList.add('resultKO')
      setTimeout(() => {
        _onClickClear()
        document.querySelector(modal).classList.remove('show')
        document.querySelector('.lc-table-principal').classList.remove('resultKO')
      }, 1000)
    }
  }

  const _onClickClear = () => {
    if (status === STATUS_PLAYING) {
      setState({
        ...state,
        letters: [],
        itemsActives: [],
      })
    }
  }

  // Deprecated: words are no longer removed
  const _onClickDelete = (word) => {
    let newWords = [...state.found]
    let index = newWords.indexOf(word)
    if (index > -1) newWords.splice(index, 1)

    mapWords(state.words, newWords)
  }

  const setGame = (data, gamefinished = false) => {
    _setBoard(data.cuadricula)
    let words = []
    Object.values(data.estadouser.palabras).forEach((arr) => arr.forEach((word) => words.push(word)))

    //Init list of words to check
    let checkWords = []
    for (let key in data.palabras) {
      for (let key2 in data.palabras[key]) {
        checkWords.push(data.palabras[key][key2])
      }
    }
    _setCheckWords(checkWords)
    if (gamefinished) {
      words = checkWords
    }

    //Init list of em-hidden words
    mapWords(data.palabras, words)
  }

  const restartAllData = async () => {
    await fetchStatistics()
    await initGame(fetchLetras)
  }
  // Obtener juego por ID
  const fetchLetras = async () => {
    setIsLoading(true)
    const response = await gamesApi.get(singleUrl)
    const dataFetch = response.data
    const _isFinished = [3, 2].includes(response.data.estadouser.id)

    setGameData(dataFetch)
    setGame(response.data, _isFinished)
    setIsLoading(false)
  }

  // Obtener estadísticas
  const fetchStatistics = async () => {
    const url = '/user/stats/letras' // + gameData.estadouser.id
    const response = await gamesApi.post(url)
    setStatistics(response.data)
  }

  const onHowToPlayClick = () => {
    _setShowHowToPlayMessage(true)
  }

  React.useEffect(() => {
    restartAllData()
  }, [singleUrl, id]) // eslint-disable-line

  //Check endgame
  React.useEffect(() => {
    if (isLoading) {
      return
    }

    let notrobat = false

    _checkwords.forEach(function (item) {
      if (!state.found.includes(item)) {
        notrobat = true
      }
    })
    if (!isResolved && status === STATUS_PLAYING && !notrobat) {
      playSuccessSound()
      finishGame()
    }
  }, [state.words]) // eslint-disable-line

  const helpMenu = [
    {
      href: '',
      disabled: status !== STATUS_PLAYING,
      onClick: resolveGame,
      label: <I18n t='game.letrasConectadasGame.actions.resolveGame' />,
    },
    {
      href: '',
      disabled: status !== STATUS_PLAYING,
      onClick: restartGame,
      label: <I18n t='game.letrasConectadasGame.actions.restartGame' />,
    },
    {
      href: '',
      onClick: onHowToPlayClick,
      disabled: status !== STATUS_PLAYING,
      label: <I18n t='game.tilesGame.howToPlay' />,
    },
  ]

  const formatDate = (date) => {
    let dateText = moment(date).format('dddd, DD.MM.YY')
    return dateText.charAt(0).toUpperCase() + dateText.slice(1)
  }

  return (
    <Layout
      isLoading={isLoading}
      className='letras'
    >
      <Header />

      <Game
        statsUrl={`/user/stats/letras`}
        endGameModal={{
          isOpen: showEndGameModal,
          title,
          icon: bigIconSrc,
          titleBgColor: bgColor,
          onDate: formatDate(gameData?.publicado),
          content: (
            <div className='em-flex em-items-center em-space-x-5 em-justify-center em-text-yellow'>
              <EmojiSmile className='em-w-[75px] em-h-[75px] em-rounded-full' />
              <div className='em-text-3xl em-uppercase'>
                <I18n t='game.messages.complete.title' />
              </div>
            </div>
          ),
          statistics: [
            {
              i18nKey: 'game.messages.complete.completedTime',
              value: moment((gameData?.estadouser?.tiempo || 0) * 1000).format('mm:ss'),
            },
            {
              i18nKey: 'game.messages.complete.averageTime',
              value: moment((gameData?.promediogeneral || 0) * 1000).format('mm:ss'),
            },
          ],
          shareUrl: `${gamesApiBase}user/stats/letras/share?userid=${getUserId()}&id=${gameData?.id}`,
          onClose: () => setShowEndGameModal(false),
        }}
        status={status}
        statistics={statistics}
        historicalGames={{
          active: !isLoading,
          url: `${baseUrlApi}/getlist`,
          activeId: gameData?.id,
          to: baseUrl,
          icon: bigIconSrc,
        }}
        publicationDate={moment(gameData?.publicado).format('dddd, DD.MM.YY')}
        title={title}
        saveGame={() => saveGame()}
        onClickOutside={(newUrl) => saveGame(true, newUrl)}
        exitGame={
          status !== STATUS_FINISHED
            ? (e) => {
                saveGame(true)
                e.preventDefault()
              }
            : false
        }
        helpMenu={helpMenu}
        leftMenu={[
          {
            href: '',
            label: <I18n t='game.actions.help' />,
            options: helpMenu,
          },
        ]}
        mobileMenu={[]}
        middleMenu={
          <Timer
            setPause={setPause}
            setPlaying={setPlaying}
            status={status}
            time={time}
          />
        }
      >
        {message}

        <div className='em-p-2 container-exercici'>
          {_showHowToPlayMessage === true && (
            <AlertButton
              active={_showHowToPlayMessage}
              messageI18n={'game.letrasConectadasGame.messages.howToPlayTextFull'}
              messageArgs={{}}
              buttonPrimaryI18n={'game.messages.complete.button'}
              onClickPrimary={() => {
                _setShowHowToPlayMessage(false)
              }}
              messageKey={''}
            />
          )}

          <div className='container-exercici-espai'>
            <div className='em-grid em-grid-cols-1 lg:em-grid-cols-12'>
              <div className='lc-table-container lg:em-col-span-5 lg:em-col-start-3'>
                <Grid
                  board={_board}
                  itemsActives={state.itemsActives}
                  onClickLetter={_onClickLetter}
                />

                <Word
                  letters={state.letters}
                  onClickAply={_onClickAply}
                  onClickClear={_onClickClear}
                />

                <div
                  className='lc-modal'
                  id='lc-modalOK'
                >
                  <p>
                    <I18n t='game.letrasConectadasGame.messages.correctword' />
                  </p>
                </div>
                <div
                  className='lc-modal'
                  id='lc-modalKO'
                >
                  <p>
                    <I18n t='game.letrasConectadasGame.messages.wrongword' />
                  </p>
                </div>
                <div
                  className='lc-modal'
                  id='lc-modalKO2'
                >
                  <p>
                    <I18n t='game.letrasConectadasGame.messages.repeatedword' />
                  </p>
                </div>
              </div>
              {/*<div className="lg:em-col-span-4 xl:em-col-span-3">*/}
              <div className='lg:em-col-span-5 xl:em-col-span-4'>
                <Words
                  words={state.words}
                  onClickDelete={_onClickDelete}
                />
              </div>
            </div>
          </div>
          <div className='em-mt-4 em-pb-10 em-mr-4 em-ml-4 em-text-center em-items-center em-border-t em-border-disabled-lighter'>
            <h3 className='em-mt-4 em-mb-1 lc-titol_petit lc-h3'>
              <I18n t='game.letrasConectadasGame.messages.howToPlayTitle' />
            </h3>
            <p className='lc-p em-tracking-tight'>
              <I18n t='game.letrasConectadasGame.messages.howToPlayText1' />
            </p>
            <p className='em-mt-1 lc-p em-tracking-tight'>
              <I18n t='game.letrasConectadasGame.messages.howToPlayText12' />
            </p>
            <p className='em-mt-1 lc-p em-uppercase em-tracking-tight'>
              <I18n t='game.letrasConectadasGame.messages.howToPlayText2' />
            </p>
          </div>
          {modal}
        </div>
      </Game>
    </Layout>
  )
}
