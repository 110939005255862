import React from 'react'
import propTypes from 'prop-types'

import './Word.css'
import I18n from '../../../lang'

export default function Word(props) {
  const { letters, onClickAply, onClickClear } = props
  const cols = []
  for (let i = 0; i < letters.length; i++) {
    cols.push(
      <td
        key={i}
        className='filled em-align-middle'
      >
        {letters[i]}
      </td>,
    )
  }
  while (cols.length < 9) {
    cols.push(
      <td
        key={cols.length}
        className='em-align-middle'
      >
        _
      </td>,
    )
  }
  return (
    <>
      <div className='lc-container-exercici-abaix'>
        <table
          aria-live='polite'
          className='lc-table-comprovacio !em-font-semibold'
        >
          <tbody>
            <tr>{cols}</tr>
          </tbody>
        </table>
      </div>
      <div className='lc-container-buttons em-pt-8'>
        <button
          className='lc-button lc-button-reset'
          type='button'
          onClick={(e) => onClickClear()}
        >
          <I18n t='game.letrasConectadasGame.actions.clean' />
        </button>
        <button
          className='lc-button lc-button-apply'
          type='button'
          onClick={(e) => onClickAply(letters.join(''))}
        >
          <I18n t='game.letrasConectadasGame.actions.apply' />
        </button>
      </div>
    </>
  )
}

Word.propTypes = {
  letters: propTypes.array.isRequired,
  onClickAply: propTypes.func.isRequired,
  onClickClear: propTypes.func.isRequired,
}
