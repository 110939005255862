import create from 'zustand'

import gamesApi from 'api'

const useSiteStore = create((set, get) => ({
  site: {},
  autosave: false,
  setAutosave: (value) => set({ autosave: value }),
  getSite: async () => {
    const response = (await gamesApi.get('/config/site')).data

    set({
      site: response,
      autosave: response.autosave,
    })

    return response
  },
}))

export default useSiteStore
