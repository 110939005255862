import React, { useState } from 'react'
import DifferencesModalSuccess from './DifferencesModalSuccess'
import DifferencesModalError from './DifferencesModalError'
import './DifferencesBoard.css'
import I18n from '../../../lang'

export default function DifferencesBoard(props) {
  const [_showClickOtherImage, _setShowClickOtherImage] = useState(false)
  const [_imgLoaded, _setImgLoaded] = useState(false)

  const findPosition = (oElement) => {
    if (typeof oElement.offsetParent != 'undefined') {
      for (var posX = 0, posY = 0; oElement; oElement = oElement.offsetParent) {
        posX += oElement.offsetLeft
        posY += oElement.offsetTop
      }
      return [posX, posY]
    } else {
      return [oElement.x, oElement.y]
    }
  }

  const handleImageClick = (event) => {
    if (props.isFinished) {
      return
    }

    let posX = 0
    let posY = 0
    let imgPos
    let myImg = document.getElementById('myImg')

    imgPos = findPosition(myImg)

    if (event.pageX || event.pageY) {
      posX = event.pageX
      posY = event.pageY
    } else if (event.clientX || event.clientY) {
      posX = event.clientX + document.body.scrollLeft + document.documentElement.scrollLeft
      posY = event.clientY + document.body.scrollTop + document.documentElement.scrollTop
    }

    posX = posX - imgPos[0]
    posY = posY - imgPos[1]

    let imgWidth = myImg.width
    let imgHeight = myImg.height

    let posXPercent = posX / imgWidth
    let posYPercent = posY / imgHeight

    props.handleClickImage({
      x: posXPercent,
      y: posYPercent,
    })
  }

  const drawCircles = (diffPoints, final = false) => {
    if (!_imgLoaded) {
      return
    }

    let canvas = document.getElementById('myCanvas')
    if (null === canvas) {
      return
    }

    canvas.width = canvas.offsetWidth
    canvas.height = canvas.offsetHeight

    let ctx = canvas.getContext('2d')
    ctx.lineWidth = 4
    // ctx.strokeStyle = '#30963f';

    diffPoints.forEach((point) => {
      if (point.discovered || props._gameState?.estadouser?.id === 3 || props._gameState?.estadouser?.id === 2) {
        let x = point.x * canvas.width
        let y = point.y * canvas.height
        if (!final || props._gameState.estadouser.puntos.find((p) => p.x === point.x && p.y === point.y)) {
          ctx.strokeStyle = '#30963f'
        } else {
          ctx.strokeStyle = '#ff0000'
        }

        ctx.beginPath()
        ctx.arc(x, y, 30, 0, 2 * Math.PI)
        ctx.stroke()
      }
    })
  }

  const isFinished = [2, 3].includes(props._gameState?.estadouser?.id)
  React.useEffect(() => {
    if (_imgLoaded) {
      drawCircles(isFinished ? props._gameState.puntos : props._currentGameData.diffPoints, isFinished)
    }
  }, [_imgLoaded, isFinished]) // eslint-disable-line react-hooks/exhaustive-deps

  drawCircles(isFinished ? props._gameState.puntos : props._currentGameData.diffPoints, isFinished)

  return (
    <>
      <div className='em-flex em-flex-col em-flex-shrink em-p-4 em-pt-0 em-pb-4 md:em-flex-row diff-wrapper'>
        <div className='em-flex em-flex-1'>
          <div className='diff-outside-wrapper'>
            <div className='diff-inside-wrapper'>
              <img
                className='em-object-cover em-overflow-hidden diff-img'
                src={props._currentGameData.img1}
                alt='differences game board'
                onClick={() => _setShowClickOtherImage(true)}
              />
              {_showClickOtherImage === true && (
                <DifferencesModalError
                  showSuccessModal={(success) => _setShowClickOtherImage(success)}
                  text={<I18n t='game.differencesGame.clickOtherImage' />}
                />
              )}
            </div>
          </div>
        </div>
        <div className='em-flex em-flex-1'>
          <div className='diff-outside-wrapper'>
            <div className='diff-inside-wrapper'>
              <img
                id='myImg'
                className='em-object-cover em-overflow-hidden diff-img'
                src={props._currentGameData.img2}
                alt='differences game diff board'
                onLoad={() => _setImgLoaded(true)}
              />
              <canvas
                id='myCanvas'
                className='diff-img diff-canvas'
                onClick={handleImageClick}
              />
              {props._success.show === true &&
                (props._success.success ? (
                  <DifferencesModalSuccess showSuccessModal={props.showSuccessModal} />
                ) : (
                  <DifferencesModalError
                    showSuccessModal={props.showSuccessModal}
                    text={<I18n t='game.differencesGame.incorrectHit' />}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
