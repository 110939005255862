import React from 'react'

import LexiretoBoard from './LexiretoBoard'
import LexiretoWord from './LexiretoWord'
import I18n from 'lang'

import { STATUS_INCOMPLETE, STATUS_FINISHED } from 'layouts/Game'
import './LexiretoGame.scss'
import classNames from 'classnames'
import useSound from 'hooks/useSound'

export default function LexiretoGame(props) {
  const { playCorrectSound, playWrongSound } = useSound()

  const [_paraula, _setParaula] = React.useState('')
  const [_showMessage, _setShowMessage] = React.useState(null)
  const timeoutId = React.useRef(null)
  const showMessage = React.useCallback(
    (message) => {
      _setShowMessage(message)
      if (timeoutId.current) {
        clearTimeout(timeoutId.current)
      }
      timeoutId.current = setTimeout(() => _setShowMessage(null), 3000)
    },
    [timeoutId],
  )

  const {
    _setCurrentGameData,
    estado,
    _currentGameData: { palabrasstart },
    isFinished,
  } = props

  const handleClickLetter = (e) => {
    if (isFinished === true || estado === STATUS_INCOMPLETE || estado === STATUS_FINISHED) {
      e.preventDefault()
      return false
    }

    _setParaula(_paraula + e.currentTarget.textContent)
    showMessage(null)
    e.preventDefault()
  }

  const getPunts = (word) => {
    let len = word.length
    if (len >= 5) {
      return len
    }
    if (len === 4) {
      return 2
    }
    if (len === 3) {
      return 1
    }

    return 0
  }

  const handleClickAplicar = (e) => {
    if (estado === STATUS_INCOMPLETE || estado === STATUS_FINISHED || isFinished === true) {
      return false
    }

    const words = palabrasstart
    const letrastart = _paraula.substring(0, 1)

    if (_paraula.length < 3) {
      showMessage('min-4-letters')
      _setParaula('')
      return false
    }

    if (!_paraula.includes(props._currentGameData.letracentral)) {
      showMessage('with-central')
      playWrongSound()
      _setParaula('')
      return false
    }

    let exists = false
    let triada = false

    if (words.hasOwnProperty(letrastart)) {
      exists = words[letrastart].includes(_paraula)
      triada = props._currentGameData.palabrasselectstart[letrastart].includes(_paraula)
    }

    if (exists && !triada) {
      playCorrectSound()
      let waitForMessage = false
      let puntos = getPunts(_paraula)
      let ncaras = getCaras()

      //check if heptacrack
      let ishepta = 0
      if (props._currentGameData.heptacrack) {
        ishepta = props._currentGameData.heptacrack.includes(_paraula) ? 1 : 0
      }

      if (ishepta === 1) {
        waitForMessage = true
        showMessage('modal-heptacrack')
        ncaras = ncaras + 1
        puntos = 10
      } else {
        showMessage('modal-ok')
      }

      if (ncaras !== props._currentGameData.caretas) {
        if (waitForMessage) {
          setTimeout(() => {
            showMessage('next-level')
          }, 3000)
        } else {
          showMessage('next-level')
        }
      }

      _setCurrentGameData((c) => ({
        ...c,
        palabrasselectstart: {
          ...c.palabrasselectstart,
          [letrastart]: [...c.palabrasselectstart[letrastart], _paraula],
        },
        contaparaules: c.contaparaules + 1,
        caretas: ncaras,
        puntos: props._currentGameData.puntos + puntos,
        nheptacrack: props._currentGameData.nheptacrack + ishepta,
      }))
    } else if (triada) {
      showMessage('modal-ok2')
      playWrongSound()
    } else {
      showMessage('modal-error')
      playWrongSound()
    }

    _setParaula('')
    e.preventDefault()
  }

  const getCaras = React.useCallback(() => {
    let nheptacrack = props._currentGameData.nheptacrack

    if (estado === 3 || estado === 5) {
      return 0
    }

    let perc = (props._currentGameData.contaparaules * 100) / props._currentGameData.palabras.length

    if (perc >= 10 && perc <= 19.9) {
      return 1 + nheptacrack
    } else if (perc >= 20 && perc <= 29.9) {
      return 2 + nheptacrack
    } else if (perc >= 30 && perc <= 39.9) {
      return 3 + nheptacrack
    } else if (perc >= 40 && perc <= 59.9) {
      return 4 + nheptacrack
    } else if (perc >= 60 && perc <= 79.9) {
      return 5 + nheptacrack
    } else if (perc >= 80 && perc <= 99.9) {
      return 6 + nheptacrack
    } else if (perc >= 100) {
      return 7 + nheptacrack
    }

    return 0 + nheptacrack
  }, [
    estado,
    props._currentGameData.nheptacrack,
    props._currentGameData.contaparaules,
    props._currentGameData.palabras.length,
  ]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleClickBorrar = (e) => {
    if (isFinished === true) {
      return false
    }

    let pa = _paraula.slice(0, -1)
    _setParaula(pa)

    e.preventDefault()
  }

  let alert = null

  const classNameAlert = classNames(
    'em-absolute em-w-max em-left-1/2 em-transform -em-translate-x-1/2 em-rounded-lg em-px-8 em-py-2 !em-text-white em-text-2xl',
  )

  if (_showMessage === 'modal-ok') {
    alert = (
      <div className={classNames(classNameAlert, '!em-text-white', 'em-bg-success-600')}>
        <p>
          <I18n t='game.LexiRetoGame.messages.correctword' />
        </p>
      </div>
    )
  } else if (_showMessage === 'modal-error') {
    alert = (
      <div className={classNames(classNameAlert, '!em-text-white', 'em-bg-danger-light')}>
        <p>
          <I18n t='game.LexiRetoGame.messages.wrongword' />
        </p>
      </div>
    )
  } else if (_showMessage === 'modal-ok2') {
    alert = (
      <div className={classNames(classNameAlert, '!em-text-white', 'em-bg-danger-light')}>
        <p>
          <I18n t='game.LexiRetoGame.messages.repeatedword' />
        </p>
      </div>
    )
  } else if (_showMessage === 'next-level') {
    alert = (
      <div className={classNames(classNameAlert, '!em-text-white', 'em-bg-primary-subesnivel')}>
        <p className='em-text-black'>
          <I18n t='game.LexiRetoGame.comovatujuego_nuevonivel' />
        </p>
      </div>
    )
  } else if (_showMessage === 'modal-heptacrack') {
    alert = (
      <div className={classNames(classNameAlert, '!em-text-white', 'em-bg-success-600')}>
        <p>
          <I18n t='game.LexiRetoGame.comovatujuego_heptacrack' />
        </p>
      </div>
    )
  } else if (_showMessage === 'min-4-letters') {
    alert = (
      <div className={classNames(classNameAlert, '!em-text-white', 'em-bg-danger-light')}>
        <p>
          <I18n t='game.LexiRetoGame.messages.minimo3letras' />
        </p>
      </div>
    )
  } else if (_showMessage === 'with-central') {
    alert = (
      <div className={classNames(classNameAlert, '!em-text-white', 'em-bg-danger-light')}>
        <p>
          <I18n t='game.LexiRetoGame.messages.faltaletracentral' />
        </p>
      </div>
    )
  }

  return (
    <>
      <LexiretoBoard
        onClickBorrar={handleClickBorrar}
        onClickAply={handleClickAplicar}
        onClickReorder={props.onClickReorder}
        _currentGameData={props._currentGameData}
        paraula={_paraula}
        onClickLetter={handleClickLetter}
        alert={alert}
      />
      <LexiretoWord _currentGameData={props._currentGameData} />
    </>
  )
}
