import React from 'react'
import ReactDOM from 'react-dom'
import classNames from 'classnames'
import { Link } from 'react-router-i18n'
import { useClickAway } from 'react-use'

import Buttonless from 'components/Buttonless'
import { ReactComponent as CaretDown } from 'assets/caret-down-fill.svg'

function SubMenu(props) {
  const { options, position = 'left', className, opened, el } = props
  if (!opened) return null
  let offset = false
  if (el) {
    offset = el.getBoundingClientRect()
  }

  let _position = 0
  const rootGames = document.querySelector('#rootGames')
  const gameOffset = rootGames.getBoundingClientRect()

  if (position === 'left') {
    _position = offset
      ? offset[position] -
        el.ownerDocument.defaultView.pageXOffset -
        gameOffset.left -
        rootGames.ownerDocument.defaultView.pageXOffset
      : 0
  } else if (position === 'right') {
    _position = offset
      ? el.ownerDocument.defaultView.innerWidth - offset[position] - rootGames.ownerDocument.defaultView.pageXOffset
      : 0
  }

  return ReactDOM.createPortal(
    <ul
      className={classNames(
        className,
        'sub-menu em-absolute em-z-[120] em-w-60 !em-m-0',
        '!em-bg-disabled-lightest em-shadow-2xl',
      )}
      style={
        offset
          ? {
              top:
                offset.top +
                el.ownerDocument.defaultView.pageYOffset +
                offset.height -
                gameOffset.top -
                rootGames.ownerDocument.defaultView.pageYOffset,
              [position]: _position,
            }
          : {}
      }
    >
      {options.map((option, index) => (
        <MenuItem
          noPadding
          container={{
            className: '!em-border-b-disabled-lighter !em-border-b !em-border-none last:!em-border-b-0',
          }}
          theme={false}
          className='em-justify-start em-w-full em-px-4 em-py-3 em-uppercase hover:em-bg-primary hover:em-text-white'
          key={`submenu_${option.href}_${index}`}
          navItem={option}
        />
      ))}
    </ul>,
    document.querySelector('#rootGames'),
  )
}

export default function MenuItem(props) {
  const [optionsOpened, setOptionsOpened] = React.useState(false)
  const ref = React.useRef(null)

  useClickAway(ref, () => {
    setTimeout(() => setOptionsOpened(false), 600)
  })

  const { navItem, className, noPadding, vertical, theme = true } = props

  let Component = Buttonless
  let options = {}

  if (navItem.options) {
    options = {
      ref,
    }
    Component = 'span'
  } else if (navItem.href) {
    if (navItem.href.indexOf('http') === -1) {
      options = {
        ignoreLocale: true,
      }
      Component = Link
    } else {
      Component = 'a'
    }
  }

  if (navItem.divider) {
    return <li className='em-pr-2 em-my-4 em-ml-2 em-border-l em-border-disabled-lighter' />
  }

  const IconComponent = navItem.icon

  return (
    <li className={classNames('group em-list-none em-h-full', { 'em-w-full': vertical }, props?.container?.className)}>
      <Component
        disabled={navItem?.disabled}
        target={Component === 'a' ? '_blank' : undefined}
        className={classNames(
          className,
          navItem?.className || '',
          'em-relative em-h-full em-cursor-pointer',
          'em-flex em-whitespace-nowrap em-space-x-2 em-items-center',
          'hover:!em-no-underline',
          'em-transition-all',
          {
            'em-py-4 em-px-3': !noPadding,
          },
          {
            'em-w-full em-uppercase': vertical,
          },
          (theme || navItem?.disabled) && {
            'em-bg-primary em-text-white': optionsOpened,
            'em-text-primary': !optionsOpened && !navItem?.disabled && navItem?.active,
            'em-text-disabled group-hover:em-text-primary em-cursor-pointer':
              !optionsOpened && !navItem?.disabled && !navItem?.active,
            'em-text-disabled-light focus:em-text-disabled-light em-cursor-default': navItem?.disabled,
          },
        )}
        {...options}
        onClick={(e) => {
          if (navItem.options) {
            e.preventDefault()
            if (!navItem.disabled) {
              setOptionsOpened(!optionsOpened)
            }
            return
          }
          if (navItem.disabled) {
            e.preventDefault()
            return
          }

          if (navItem?.onClick) {
            navItem.onClick(e)
          }
        }}
        to={navItem?.href}
        href={navItem?.href}
        {...navItem?.inputProps}
      >
        {IconComponent && !vertical && <IconComponent className='em-text-current em-fill-current' />}
        {navItem.image && !vertical && (
          <img
            src={navItem.image}
            alt={navItem.label}
          />
        )}
        {navItem.label && <span>{navItem.label}</span>}
        {navItem.options && (
          <>
            <CaretDown
              className={classNames('em-transform em-transition-all', {
                'em-rotate-180': optionsOpened,
              })}
            />
            <SubMenu
              position={navItem.optionPosition}
              options={navItem.options}
              opened={optionsOpened}
              el={ref.current}
            />
          </>
        )}
      </Component>
    </li>
  )
}
