import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import classNames from 'classnames'
import moment from 'moment'

import Header from 'layouts/Header'
import Layout from 'layouts/Layout'
import { AlertButton } from 'layouts/Game/messages'
import Game, { STATUS_FINISHED, STATUS_PLAYING } from 'layouts/Game'
import Timer from 'components/Timer'
import DifferencesBoard from './DifferencesBoard'
import useGame from 'hooks/useGame'
import gamesApi, { gamesApiBase, getUserId } from 'api'
import I18n from 'lang'

import { ReactComponent as CaraVerdeIcon } from 'assets/testactualidad-emoji-smile-fill.svg'
import { ReactComponent as CaraRojaIcon } from 'assets/testactualidad-emoji-frown-fill.svg'

import './DifferencesApp.css'
import useSound from 'hooks/useSound'

export default function DifferencesApp({ gameProps }) {
  const { id = 'last', locale = 'es' } = useParams()

  const { playCorrectSound, playWrongSound, playSuccessSound } = useSound()

  const {
    title,
    color: bgColor,
    api: { basepath: baseUrlApi },
    icon: { big: bigIconSrc },
    url: baseUrl,
  } = gameProps

  const [_gameState, _setGameState] = React.useState({})
  const [_currentGameData, _setCurrentGameData] = useState({})
  const [_success, _setSuccess] = useState({ show: false, success: false })

  const [_isInitialized, _setIsInitialized] = useState(false)
  const [_statistics, _setStatistics] = React.useState([])
  const [_showHowToPlayMessage, _setShowHowToPlayMessage] = React.useState(false)

  const {
    isLoading,
    status,
    initGame,
    setPause,
    setPlaying,
    save,
    autoSave,
    restart,
    resolve,
    finish,
    time,
    message,
    modal,

    showEndGameModal,
    setShowEndGameModal,
  } = useGame({
    startFrom: parseInt(_gameState?.estadouser?.tiempo || 0, 10),
    expirationDate: _gameState?.despublicado,
    locale: locale,
    statusInit: _gameState?.estadouser?.id,
  })

  //////INIT
  const initCurrentGameData = (_gameState) => {
    let diffPoints = []
    let diffPointsFound = []
    let clickestados = []

    if (null !== _gameState.estadouser.puntos) {
      diffPointsFound = [..._gameState.estadouser.puntos]
    }

    if (null !== _gameState.estadouser.clicksestados) {
      clickestados = _gameState.estadouser.clicksestados
    } else {
      //@TODO refactory
      clickestados = [null, null, null, null, null, null, null, null, null, null, null, null]
    }

    _gameState.puntos.forEach((diffPoint) => {
      let discovered = false

      //Check if it has been discovered
      diffPointsFound.forEach((diffPointFound) => {
        if (diffPoint.x === diffPointFound.x && diffPoint.y === diffPointFound.y) {
          discovered = true
        }
      })

      diffPoints.push({
        x: diffPoint.x,
        y: diffPoint.y,
        discovered: discovered,
      })
    })

    let successClicks = _gameState.estadouser.clicsacertados === '' ? 0 : parseInt(_gameState.estadouser.clicsacertados)
    let wrongCLicks = _gameState.estadouser.clicserroneos === '' ? 0 : parseInt(_gameState.estadouser.clicserroneos)

    let currentGameData = {
      img1: _gameState.image1,
      img2: _gameState.image2,
      diffPoints: diffPoints,
      diffPointsFound: diffPointsFound,
      clicks: successClicks + wrongCLicks,
      clickestados: clickestados,
      promediogeneral: _gameState.promediogeneral,
      tiempo: _gameState.estadouser.tiempo,
    }

    _setCurrentGameData(currentGameData)
    _setIsInitialized(true)
  }

  //////FUNCTIONS
  const fetchStatistics = async () => {
    const url = `/user/stats/${baseUrlApi}`.replace(/\/+/g, '/')
    const response = await gamesApi.post(url)

    _setStatistics(response.data)
  }

  const fetchGame = async () => {
    _setIsInitialized(false)
    const response = await gamesApi.get(`/${baseUrlApi}/get/${id}`)
    const dataFetch = response.data

    initCurrentGameData(dataFetch)

    _setGameState(dataFetch)
  }

  const restartAllData = async () => {
    _setIsInitialized(false)
    _setGameState({})
    _setCurrentGameData({})

    fetchStatistics()
    await initGame(() => fetchGame())
  }

  useEffect(() => {
    restartAllData()
  }, [id]) // eslint-disable-line react-hooks/exhaustive-deps

  const addTry = () => {
    let currentGameData = Object.assign({}, _currentGameData)
    currentGameData.clicks++
    _setCurrentGameData(currentGameData)
  }

  const showSuccessModal = (show, isSuccessfully = false) => {
    let success = Object.assign({}, _success)
    success.show = show
    success.success = isSuccessfully
    _setSuccess(success)
  }

  const limiteClicks = () => {
    if (_currentGameData.clicks > 10) {
      onFinishedGame()
    } else {
      saveGame(false, null, true)
    }
  }

  const handleClickImage = (clickedPoint) => {
    let idx = discoveredDiffPoint(clickedPoint)

    if (-1 === idx) {
      playWrongSound()
      showSuccessModal(true, false)
      addTry()

      _currentGameData.clickestados[_currentGameData.clicks] = false

      limiteClicks()
      return
    }

    if (true === _currentGameData.diffPoints[idx].discovered) {
      return
    }

    playCorrectSound()

    showSuccessModal(true, true)

    addDiffPointFound(idx)

    _currentGameData.clickestados[_currentGameData.clicks] = true

    limiteClicks()
  }

  const computeDistance = (point1, point2) => {
    return Math.sqrt(Math.pow(point1.x - point2.x, 2) + Math.pow(point1.y - point2.y, 2))
  }

  //If successfull return idx otherwise -1
  const discoveredDiffPoint = (clickedPoint) => {
    let myImg = document.getElementById('myImg')
    let imgWidth = myImg.width
    let imgHeight = myImg.height

    return _currentGameData.diffPoints.findIndex((diffPoint, index) => {
      let point1 = {
        x: diffPoint.x * imgWidth,
        y: diffPoint.y * imgHeight,
      }
      let point2 = {
        x: clickedPoint.x * imgWidth,
        y: clickedPoint.y * imgHeight,
      }
      return computeDistance(point1, point2) < 30
    })
  }

  const addDiffPointFound = (clickedPointIdx) => {
    let currentGameData = Object.assign({}, _currentGameData)

    if (clickedPointIdx instanceof Array) {
      clickedPointIdx.forEach((idx) => {
        currentGameData.diffPointsFound.push(currentGameData.diffPoints[idx])
        currentGameData.diffPoints[idx].discovered = true
      })
    } else {
      currentGameData.diffPointsFound.push(currentGameData.diffPoints[clickedPointIdx])
      currentGameData.diffPoints[clickedPointIdx].discovered = true
    }
    currentGameData.clicks++

    _setCurrentGameData(currentGameData)
  }

  const gameIsFinished = () => {
    return _currentGameData.diffPoints.length === _currentGameData.diffPointsFound.length
  }

  useEffect(() => {
    if (_isInitialized) {
      if (gameIsFinished()) {
        playSuccessSound()
        onFinishedGame()
      }
    }
  }, [_currentGameData]) // eslint-disable-line react-hooks/exhaustive-deps

  const getCurrentDataForApi = () => {
    return {
      gameid: _gameState.id,
      gamedata: {
        tiempo: time,
        puntos: _currentGameData.diffPointsFound,
        clicsacertados: _currentGameData.diffPointsFound.length,
        clicserroneos: _currentGameData.clicks - _currentGameData.diffPointsFound.length,
        clickestados: _currentGameData.clickestados,
      },
    }
  }

  const saveGame = async (exit = false, newUrl = null, auto = false) => {
    const fnc = auto ? autoSave : save
    await fnc(baseUrlApi, getCurrentDataForApi(), exit, newUrl)
    fetchStatistics()
  }

  const onFinishedGame = async () => {
    await finish(baseUrlApi, getCurrentDataForApi())
    await fetchStatistics()
    await fetchGame()
  }

  const onRestartGameClick = async () => {
    await restart(baseUrlApi, {
      gameid: _gameState.id,
      gamedata: {
        tiempo: time,
      },
    })
    restartAllData()
  }

  const onResolveGameClick = async () => {
    resolve(baseUrlApi, getCurrentDataForApi()).then((response) => {
      let currentGameData = Object.assign({}, _currentGameData)
      currentGameData.diffPoints.forEach((point) => {
        point.discovered = true
      })
      _setCurrentGameData(currentGameData)
      restartAllData()
    })
  }

  const onHowToPlayClick = () => {
    _setShowHowToPlayMessage(true)
  }

  const helpMenu = [
    {
      href: '',
      label: <I18n t='game.actions.help' />,
      options: [
        {
          href: '',
          onClick: onRestartGameClick,
          disabled: status !== STATUS_PLAYING,
          label: <I18n t='game.differencesGame.restartGame' />,
        },
        {
          href: '',
          onClick: onResolveGameClick,
          disabled: status !== STATUS_PLAYING,
          label: <I18n t='game.differencesGame.resolveGame' />,
        },
        {
          href: '',
          onClick: onHowToPlayClick,
          disabled: status !== STATUS_PLAYING,
          label: <I18n t='game.differencesGame.howToPlay' />,
        },
      ],
    },
  ]

  const mobileMenu = [
    {
      href: '',
      onClick: onRestartGameClick,
      disabled: status !== STATUS_PLAYING,
      label: <I18n t='game.differencesGame.restartGame' />,
    },
    {
      href: '',
      onClick: onResolveGameClick,
      disabled: status !== STATUS_PLAYING,
      label: <I18n t='game.differencesGame.resolveGame' />,
    },
    {
      href: '',
      onClick: onHowToPlayClick,
      disabled: status !== STATUS_PLAYING,
      label: <I18n t='game.differencesGame.howToPlay' />,
    },
  ]

  const formatDate = (date) => {
    let dateText = moment(date).format('dddd, DD.MM.YY')
    return dateText.charAt(0).toUpperCase() + dateText.slice(1)
  }

  const printAnswers = ({ className = '', showLabel = false } = {}, end = false) => {
    let contador = 0
    let oks = 0
    let bad = 0

    if (_currentGameData.clickestados) {
      let caras = _currentGameData.clickestados.map((item, index) => {
        if (item) {
          contador++
          oks++

          return (
            <div
              key={`face_${contador}`}
              className={classNames('em-flex em-items-center em-justify-center', {
                'em-text-green-600': !end,
                'em-text-yellow': end,
              })}
            >
              <CaraVerdeIcon />
            </div>
          )
        } else if (item !== null) {
          bad++
          contador++

          return (
            <div
              key={`face_${contador}`}
              className={classNames('em-flex em-items-center em-justify-center', {
                'em-text-danger': !end,
                'em-text-disabled-light': end,
              })}
            >
              <CaraRojaIcon />
            </div>
          )
        } else {
          return null
        }
      })

      for (contador; contador < 12; contador++) {
        caras[contador] = (
          <div
            key={`face_${contador}`}
            className='em-flex em-items-center em-justify-center'
          >
            <div
              className={classNames(
                'em-w-full em-h-full sm:em-w-10 sm:em-h-10 em-rounded-full em-inline-flex em-items-center em-justify-center ',
                {
                  'em-text-slate-600 em-bg-gray-100': !end,
                  'em-border-2 em-border-disabled-light em-text-disabled-light': end,
                },
              )}
            >
              {contador + 1}
            </div>
          </div>
        )
      }

      let i18n = 'bad'
      if (oks === 8) {
        if (bad === 0) {
          i18n = 'good'
        } else {
          i18n = 'neutral'
        }
      }

      if (showLabel) {
        return (
          <div className='em-flex em-flex-col em-items-center em-justify-center em-text-yellow'>
            <span className='em-text-3xl em-uppercase em-text-center em-mb-4'>
              <I18n t={'game.differencesGame.statusEndGame.' + i18n} />
            </span>
            <span className='em-uppercase em-text-white em-mb-3'>
              <I18n t='game.tusclics' />
            </span>
            <div className='em-w-full em-block'>
              <div
                className={classNames(
                  {
                    'em-grid em-grid-cols-6 em-gap-2': !className,
                  },
                  className,
                )}
              >
                {caras}
              </div>
            </div>
          </div>
        )
      }

      return (
        <div
          className={classNames(
            {
              // 'em-grid em-grid-cols-6 em-gap-3': ! className,
              'em-grid em-grid-cols-6 md:em-grid-cols-6 lg:em-grid-cols-12 em-gap-4 lg:em-gap-8"': !className,
            },
            className,
          )}
        >
          {caras}
        </div>
      )
    }
  }

  return (
    <Layout isLoading={isLoading}>
      <Header />

      {_isInitialized && (
        <Game
          statsUrl={`/user/stats/${baseUrlApi}`}
          endGameModal={{
            isOpen: showEndGameModal,
            title,
            icon: bigIconSrc,
            titleBgColor: bgColor,
            content: printAnswers({ showLabel: true }, true),
            onDate: formatDate(_gameState?.publicado),
            statistics: [
              {
                i18nKey: 'game.messages.complete.completedTime',
                value: moment((_currentGameData?.tiempo || 0) * 1000).format('mm:ss'),
              },
              {
                i18nKey: 'game.messages.complete.averageTime',
                value: moment((_currentGameData?.promediogeneral || 0) * 1000).format('mm:ss'),
              },
            ],
            shareUrl: `${gamesApiBase}user/stats/diferencias/share?userid=${getUserId()}&id=${_gameState?.id}`,
            onClose: () => setShowEndGameModal(false),
          }}
          status={status}
          statistics={_statistics}
          expirationDate={formatDate(_currentGameData?.publicado)}
          historicalGames={{
            active: !isLoading,
            url: `/${baseUrlApi}/getlist`,
            activeId: _gameState?.id,
            to: baseUrl,
            icon: bigIconSrc,
          }}
          publicationDate={formatDate(_gameState?.publicado)}
          title={title}
          by={_gameState?.author && `${_gameState?.author} // `}
          saveGame={() => saveGame()}
          onClickOutside={(newUrl) => saveGame(true, newUrl)}
          exitGame={
            status !== STATUS_FINISHED
              ? (e) => {
                  saveGame(true)
                  e.preventDefault()
                }
              : false
          }
          helpMenu={mobileMenu}
          leftMenu={helpMenu}
          middleMenu={
            <div className='em-flex em-items-center em-text-sm em-h-full em-space-x-2 em-mr-6'>
              {/*<div>*/}
              {/*    <I18n t='game.differencesGame.clicks'/>*/}
              {/*</div>*/}
              {/*<div className="diff-menu-number">*/}
              {/*    {_currentGameData.clicks}*/}
              {/*</div>*/}
              {/*<div className="diff-menu-number diff-menu-line-v">&nbsp;</div>*/}
              {/*<div className='diff-bg-green em-flex'>*/}
              {/*    <div className='em-text-white diff-menu-v-center'>*/}
              {/*        <I18n t='game.differencesGame.hits'/>*/}
              {/*    </div>*/}
              {/*    <div className="em-text-white diff-menu-number diff-menu-v-center">*/}
              {/*        {_currentGameData.diffPointsFound.length}*/}
              {/*    </div>*/}
              {/*</div>*/}
              {/*<div className="diff-menu-number ">&nbsp;</div>*/}
              <Timer
                setPause={setPause}
                setPlaying={setPlaying}
                status={status}
                time={time}
              />
            </div>
          }
        >
          {message}

          <div className='em-relative em-flex em-justify-center em-pt-9 em-pl-12 em-pr-12 diff-mobile'>
            {_showHowToPlayMessage === true && (
              <AlertButton
                active={_showHowToPlayMessage}
                messageI18n={'game.differencesGame.howToPlayTextFull'}
                messageArgs={{}}
                buttonPrimaryI18n={'game.messages.complete.button'}
                onClickPrimary={() => {
                  _setShowHowToPlayMessage(false)
                }}
                messageKey={''}
              />
            )}

            {_isInitialized && (
              <DifferencesBoard
                _currentGameData={_currentGameData}
                _gameState={_gameState}
                isFinished={status === STATUS_FINISHED}
                handleClickImage={handleClickImage}
                _success={_success}
                showSuccessModal={showSuccessModal}
              />
            )}
          </div>

          <div className=' em-items-center em-border-b em-mt-1 em-pb-4 em-border-disabled-lighter em-text-sm em-space-x-2 em-mr-6'>
            <div className='em-justify-center em-items-center md:em-pt-2 sm:em-pt-2  em-text-2xl em-text-center em-mb-3 titulotusrespuestas !em-font-semibold'>
              <I18n t='game.tusclics' />
            </div>

            <div className='em-flex em-justify-center em-items-center em-mb-2 '>{printAnswers()}</div>
          </div>

          {/*<div className={'em-mt-6 em-grid em-grid-cols-3  em-pb-10'}>*/}
          <div className={'em-mt-4 em-pb-10 em-mr-4 em-ml-4'}>
            {/*<div className={'em-col-start-2'}>*/}
            <div>
              <div className='diff-help-h3'>
                <h3>
                  <I18n t='game.differencesGame.howToPlay' />
                </h3>
              </div>
              <div className='diff-help-p'>
                <I18n t='game.differencesGame.howToPlayText' />
              </div>
            </div>
          </div>

          {modal}
        </Game>
      )}
    </Layout>
  )
}
