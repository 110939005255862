import React from 'react'
import classNames from 'classnames'

import { ReactComponent as Tail } from 'assets/tail-spin.svg'

export default function Loader(props) {
  if (!props.active) {
    return null
  }

  return (
    <div
      role={'progressbar'}
      className={classNames(
        props.className,
        {
          'em-flex em-items-center em-justify-center em-top-0 em-left-0 em-w-full em-h-full em-fixed': props.center,
        },
        'em-z-50',
      )}
    >
      <div className='em-bg-primary em-rounded-full em-p-2'>
        <Tail />
      </div>
    </div>
  )
}
