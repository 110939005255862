const DifferencesGameLocaleCA = {
  title: 'Les 8 diferències',
  hits: 'encerts',
  clicks: 'clics',
  resolveGame: 'Resoldre',
  restartGame: 'Reiniciar',
  howToPlay: 'Com es juga',
  howToPlayText: () => (
    <>
      Troba i assenyala els 8 errors en el segon dibuix. <br />
      Tens 12 oportunitats.
    </>
  ),
  howToPlayTextFull: () => (
    <>
      <strong>Com es juga</strong>
      <br />
      Troba i assenyala els 8 errors en el segon dibuix. <br />
      Tens 12 oportunitats.
    </>
  ),
  successfullyHit: 'BÉ!',
  incorrectHit: 'ERROR!',
  clickOtherImage: 'Fes clic en el segon dibuix',
  completadotitulo: 'Joc completat!',
  textoacertado: 'Clics correctes ',
  textoerroneos: ', clics erronis ',
  textorevisarestadisticas: 'Pots revisar en Les meves estadístiques els resultats totals',
  statusEndGame: {
    good: 'MOLT BÉ!',
    neutral: 'BÉ!',
    bad: 'POTS MILLORAR!',
  },
  // description: ({ corrects, errors }) => <>Respostes correctes {corrects}, incorrectes {errors}.<br />Pots revisar en Les meves estadístiques els resultats totals.</>,
  //  completat: ({correctes}) => <>Clics correctes {correctes}, clics erronis erronis.<br/>Pots revisar en Les meves estadístiques els resultats totals</>,
}

export default DifferencesGameLocaleCA
