import React from 'react'
import classNames from 'classnames'
import propTypes from 'prop-types'

import './Grid.scss'

export const Letter = (props) => {
  const { type, letter, isSelected, className, notFullSize, onClick = () => {} } = props

  return (
    <div
      onClick={onClick}
      className={classNames(
        className,
        'em-flex em-items-center em-justify-center',
        'em-uppercase !em-font-semibold',
        {
          'em-text-md md:em-text-xl': type !== 'minicrossword',
          'em-text-2xl sm:em-text-4xl': type === 'minicrossword',
        },
        {
          'em-w-full em-h-full': !notFullSize,
          'em-text-black': letter.letterError === null,
          'em-text-danger': letter.letterError === true,
          'em-text-success-600': letter.letterError === false,
          'em-bg-disabled-light': letter.divider,
          'em-bg-primary-light': letter.selected && !isSelected,
          'em-bg-white group-hover:em-bg-primary-light': !letter.selected && !letter.divider,
          'em-z-10 em-relative em-bg-primary em-text-white': isSelected,
        },
      )}
    >
      {!letter.divider && letter.value}
      {letter.value !== '' && letter.letterError && (
        <div className='em-absolute em-left-0 em-top-0 em-w-full em-h-full em-flex em-items-center em-justify-center'>
          <div className='em-origin-center em-w-full em-border-danger em-border-b-2 em-absolute em-transform em-rotate-45' />
          <div className='em-origin-center em-w-1 em-h-full em-border-danger em-border-l-2 em-absolute em-transform em-rotate-45' />
        </div>
      )}
    </div>
  )
}

export default function Grid(props) {
  const { board, itemActive, onClickItem } = props

  if (!board?.grid || !board?.grid[0]) {
    return null
  }

  return (
    <div
      id='crossword-grid-container'
      className={board.crosswordType === 1 ? 'minicrossword' : 'crossword'}
    >
      <div id='grid'>
        <table
          cellPadding='0'
          cellmargin='0'
          border='0'
        >
          <tbody>
            {board.crosswordType !== 1 && (
              <tr>
                <td></td>
                {board.grid[0].map((_, indexCol) => (
                  <td key={`col_${indexCol}`}>{indexCol + 1}</td>
                ))}
              </tr>
            )}
            {board.grid.map((row, indexRow) => (
              <tr key={`row_${indexRow}`}>
                {board.crosswordType !== 1 && <td>{indexRow + 1}</td>}
                {row.map(
                  (letter, indexCol) =>
                    letter.letter !== '-' && (
                      <td
                        key={`col_${indexCol}`}
                        onClick={() => !letter.divider && onClickItem(indexRow, indexCol)}
                        className={classNames('group', {
                          'em-cursor-pointer': !letter.divider,
                        })}
                      >
                        {letter.num && letter.num !== '#' && (
                          <span
                            className={classNames(
                              'em-absolute',
                              {
                                'em-top-0 em-left-1 lg:em-top-1 lg:em-left-2 em-text-sm':
                                  board.type === 'miniscrossword',
                                'em-top-[-9px] em-left-0 md:em-top-[-5px] md:em-left-[1px] em-text-[7px] md:em-text-[10px]':
                                  board.type !== 'miniscrossword',
                              },
                              {
                                'em-text-white em-z-20': itemActive[0] === indexRow && itemActive[1] === indexCol,
                              },
                            )}
                          >
                            {letter.num}
                          </span>
                        )}
                        <Letter
                          type={board.type}
                          letter={letter}
                          isSelected={itemActive[0] === indexRow && itemActive[1] === indexCol}
                        />
                      </td>
                    ),
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

Grid.propTypes = {
  board: propTypes.object.isRequired,
  itemActive: propTypes.array,
  onClickItem: propTypes.func,
}
