import { IoMdShare } from 'react-icons/io'

export const pl = {
  close: 'Zamknij',
  by: 'Przez',
  play: 'Graj',
  game: {
    historicalGames: {
      playing: 'NA PLANSZY',
    },
    new: 'Nowość',
    actions: {
      save: 'Zapisz',
      download: 'Pobierz',
      exit: 'Wyjdź',
      help: 'Pomoc',
    },
    timer: {
      show: 'Pokaż',
      hide: 'Ukryj',
    },
    yourPoints: 'Twoje punkty',
    heptacracks: 'Heptacracks',
    yourWords: 'Twoje słowa',
    yourAnswers: 'Twoje odpowiedzi',
    send: 'Wyślij',
    tusclics: 'Twoje kliknięcia',
    messages: {
      init: {
        message: 'Czy jesteś gotowy, aby rozpocząć?',
        button: 'Grać',
      },
      pause: {
        message: 'Gra jest wstrzymana',
        button: 'Kontynuuj',
      },
      restart: {
        message: 'Masz zamiar ponownie uruchomić grę',
        buttonPrimary: 'Uruchom ponownie',
        buttonCancel: 'Wróć',
      },
      resolve: {
        message:
          'Jeśli wybierzesz opcję ROZWIĄŻ, ta gra zostanie odzwierciedlona jako niekompletna w Moich statystykach. Czy zgadzasz się z tym?',
        buttonPrimary: 'Tak',
        buttonCancel: 'Nie',
      },
      save: {
        message: 'Zbliżasz się do wyjścia z gry. Czy chcesz zapisać wprowadzone zmiany?',
        buttonPrimary: 'Tak',
        buttonCancel: 'Nie',
      },
      errorSaving: {
        message: 'Błąd zapisu. Spróbuj ponownie.',
        button: 'Zamknij',
      },
      errorRestarting: {
        message: 'Błąd ponownego uruchomienia gry. Spróbuj ponownie.',
        button: 'Zamknij',
      },
      errorResolving: {
        message: 'Błąd podczas rozwiązywania gry. Spróbuj ponownie.',
        button: 'Zamknij',
      },
      errorFinishing: {
        message: 'Błąd zakończenia gry. Spróbuj ponownie.',
        button: 'Zamknij',
      },
      errorChecking: {
        message: 'Szkoda! Gra nie została pomyślnie ukończona. Próbuj dalej.',
        button: 'Zamknij',
      },
      finished: {
        message: 'Ukończyłeś tę grę. Możesz ją tylko wyświetlić.',
        button: 'Wstecz',
      },
      complete: {
        title: 'Brawo!',
        endGameTitle: 'GRA ZAKOŃCZONA!',
        averageTime: 'Ogólny średni czas',
        percentageGeneralCompleted: 'Ogólny procent ukończenia',
        completedTime: 'Gra ukończona w',
        share: 'Udostępnij swoje wyniki',
        shortDescription: 'Sprawdź czy poprawiłeś się w swoich statystykach',
        description: 'Ukończyłeś grę. Sprawdź czy poprawiłeś się w swoich statystykach.',
        button: 'Zamknij',
        onDate: (date) => <>Gra w {date}</>,
      },
      incomplete: {
        message: 'Tutaj gra została rozwiązana. W Moich statystykach będzie ona liczona jako niekompletna.',
      },
      past: {
        message: 'Koniec gry. Można się z nim tylko skonsultować.',
        button: 'Powrót do',
      },
      saved: {
        // message: 'Używanie przycisku Zapisz nie jest już konieczne. Za każdym razem, gdy przejdziesz dalej, gra zostanie automatycznie zapisana.',
        message: 'Gra została pomyślnie zapisana.',
        button: 'Wyjdź',
      },
      savedlexireto: {
        message: ({ date }) => <>Gra została pomyślnie zapisana.</>,
        button: 'Wyjdź',
      },
    },
    currentChallenge: {
      actions: {
        next: 'Dalej',
        showExplain: 'Zobacz wyjaśnienie',
        finish: 'Koniec',
      },
      messages: {
        finish: {
          title: 'Test zakończony!',
          description: ({ corrects, errors }) => (
            <>
              Poprawne odpowiedzi {corrects}, niepoprawne {errors}
              .<br />
              Możesz sprawdzić łączne wyniki w Moich statystykach.
            </>
          ),
          button: 'Zamknij',
        },
        showExplain: 'Powód udzielenia odpowiedzi',
      },
      statusEndGame: {
        good: 'BRAWO!',
        neutral: 'DOBRZE!',
        bad: 'MOŻESZ SIĘ POPRAWIĆ!',
      },
    },
    crosswords: {
      questions: 'Pytania do krzyżówki',
      horizontals: 'Poziomy',
      verticals: 'Pionowo',
      horizontal: 'Poziomo.',
      vertical: 'Poziomo.',
      messages: {
        finish: {
          title: 'Brawo!',
          description: 'Zamierzasz dokończyć swoje słowa w creuats.\nSprawdź, czy poprawiłeś statystyki.',
          button: 'Wyjdź',
        },
        incomplete: {
          title: '',
          description:
            'Tutaj masz rozwiązane wyrazy, które napisałeś. Moje statystyki zostaną zaliczone jako niekompletne.',
          button: 'Wyjdź',
        },
      },
      actions: {
        checkLetter: 'Sprawdź literę',
        checkWord: 'Sprawdź słowo',
        checkCrossword: ({ gameName }) => <>Sprawdź {gameName}</>,
        resolveCrossword: 'Rozwiązujący',
        restartCrossword: 'Restart',
      },
    },
    sudoku: {
      candidates: 'Kandydaci',
      normal: 'Końcowe liczby',
      levels: {
        easy: 'Podstawowy',
        medium: 'Zaawansowany',
        hard: 'Ekspert',
      },
      messages: {},
      actions: {
        checkCell: 'Pole wyboru',
        checkSudoku: ({ gameName }) => <>Sprawdź {gameName}</>,
        resolveSudoku: 'Rozwiąż',
        restartSudoku: 'Restart',
      },
    },
    letrasConectadasGame: {
      title: 'Listy',
      actions: {
        checkGame: 'Listy kontrolne',
        resolveGame: 'Rozwiązanie',
        restartGame: 'Restart',
        apply: 'Zastosuj',
        clean: 'Usuń',
      },
      messages: {
        saved: {
          message: ({ date }) => (
            <>
              Gra została pomyślnie zapisana. Będziesz mógł ją ukończyć do <strong>{date}</strong>
            </>
          ),
        },
        letters: 'Listy',
        howToPlayTitle: 'Jak grać',
        howToPlayText1:
          'Znajdź siedem słów, które ukryliśmy, wybierając sąsiednie pola we wszystkich kierunkach z wyjątkiem po przekątnej.',
        howToPlayText12: 'Możesz użyć każdej litery tyle razy, ile chcesz, ale nie w tym samym słowie.',
        howToPlayText2: 'Uwaga! Nie wszystkie słowa, które możesz utworzyć, będą ważne. Tylko te, które zaproponujemy.',
        howToPlayTextFull:
          'Znajdź siedem słów, które ukryliśmy, wybierając sąsiednie pola we wszystkich kierunkach, z wyjątkiem po przekątnej. Możesz użyć każdej litery tyle razy, ile chcesz, ale nie w tym samym słowie. Uwaga! Nie wszystkie słowa, które możesz utworzyć, będą prawidłowe. Tylko te, które sugerujemy.',
        correctword: 'Właściwe słowo!',
        wrongword: 'Złe słowo!',
        repeatedword: 'Powtórzone słowo!',
      },
    },
    differencesGame: {
      title: '8 błędów',
      hits: 'hity',
      clicks: 'kliknięcie',
      resolveGame: 'Rozwiązanie',
      restartGame: 'Restart',
      howToPlay: 'Jak grać',
      howToPlayText: () => (
        <>
          Znajdź i wskaż 8 różnic na drugim rysunku. <br />
          Masz 12 możliwości.
        </>
      ),
      howToPlayTextFull: () => (
        <>
          <strong>Jak grać</strong>
          <br />
          Znajdź i wskaż 8 różnic na drugim rysunku. <br />
          Masz 12 możliwości.
        </>
      ),
      successfullyHit: 'DOBRZE!',
      incorrectHit: 'BŁĄD!',
      clickOtherImage: 'Kliknij drugi rysunek',
      completadotitulo: 'Gra ukończona! ',
      textoacertado: 'Udane kliknięcia ',
      textoerroneos: ', nieprawidłowe kliknięcia ',
      textorevisarestadisticas: 'Możesz sprawdzić w Moich statystykach skumulowane wyniki',
      statusEndGame: {
        good: 'BRAWO!',
        neutral: 'DOBRY!',
        bad: 'STAĆ CIĘ NA WIĘCEJ!',
      },
      // description: ({ corrects, errors }) => <>Prawidłowe odpowiedzi {corrects}, nieprawidłowy {errors}.<br />Łączne wyniki można sprawdzić w Moich statystykach.</>,
      //  zakończone: ({udany}) => <>Udane kliknięcia {udany}, błędne, nieprawidłowe kliknięcia.<br/>Możesz sprawdzić w Moich statystykach skumulowane wyniki.</>,
    },
    tilesGame: {
      title: 'Płytki',
      totalPairs: 'Pary ogółem',
      resolvedPairs: 'Moje pary',
      marker: 'Tabela wyników',
      resolveGame: 'Rozwiązanie',
      restartGame: 'Restart',
      howToPlay: 'Jak grać',
      howToPlayText:
        'Dopasuj identyczne płytki, aby ukończyć panel przed upływem czasu - masz 4 minuty! Uważaj, niektóre płytki mogą być obrócone.',
      howToPlayTextFull: () => (
        <>
          <strong>Jak grać</strong>
          <br />
          Dopasuj identyczne płytki, aby ukończyć panel w jak najkrótszym czasie. Sprawdź niektóre płytki mogą być
          obrócone.
        </>
      ),
      incorrectlyPaired: 'Zła para!',
      successfullyPaired: 'Właściwa para!',
      messages: {
        init: 'Masz 4 minuty na ukończenie',
        '3m': 'Zostały 3 minuty',
        '2m': 'Zostały 2 minuty',
        '1m': 'Została 1 minuta',
        '30s': 'Zostało 30 sekund',
        '10s': 'Zostało 10 sekund',
      },
      incomplete: {
        title: 'TRWAŁOŚĆ!',
        message: 'Skończył ci się czas',
      },
    },
    LexiRetoGame: {
      expirationDate: 'dzisiaj o 23:59:59',
      title: 'Wyzwanie Lexi',
      resolveGame: 'Rozwiązanie',
      restartGame: 'ponowne uruchomienie',
      howToPlay: 'Jak grać',
      howToPlayText: 'Jak grać',
      howToPlayTextFull: () => (
        <>
          <div className='mb-1'>
            <strong>Jak grać</strong>
          </div>
          <ul>
            <li>
              Twórz słowa składające się z co najmniej 3 liter. Litery mogą się powtarzać, ale zawsze muszą zawierać
              główną literę.
            </li>
            <li>
              Rzeczowniki własne, liczba mnoga i odmieniane formy czasowników nie są dozwolone (tylko bezokoliczniki).
            </li>
            <li>
              Znajdź słowa zawierające wszystkie 7 liter (Heptacrack!), a awansujesz w rankingu. Sprawdź jak wygląda
              Twoja i popraw swoje statystyki.
            </li>
            <li>
              Punktacja: słowa 3-literowe otrzymują 1 punkt, a słowa 4-literowe otrzymują 2 punkty. Od 5 liter,
              otrzymujesz tyle punktów, ile jest liter w słowie. Heptakracki są warte 10 punktów.
            </li>
            <li>
              UWAGA. Wyzwania lexi będzie można rozwiązywać tylko w dniu ich publikacji. Po 24 godzinach stają się one
              nieaktywne i można się z nimi jedynie zapoznać.
            </li>
          </ul>
        </>
      ),
      totalpalabras: 'Twoje słowa',
      tuspalabras: 'Twoje słowa',
      empiezanpor: 'Zaczynają się NA: ',
      encontradas: 'Znaleziono',
      heptacrackencontradas: 'Heptacracks',
      comovatujuego: 'Jak idzie gra',
      comovatujuego_heptacrack: 'Heptacrack!',
      comovatujuego_nuevonivel: 'Awansujesz!',
      marcador: 'Tabela wyników',
      puntuacion: 'Twoje punkty',
      maximo: 'Maksimum',
      encontrados: 'Znaleziono',
      actions: {
        apply: 'Zastosuj',
        clean: 'Usuń',
        reordenar: 'Zmiana kolejności',
      },
      messages: {
        correctword: 'Dobrze!',
        wrongword: 'Nie znaleziono',
        repeatedword: 'Już znaleziono',
        minimo3letras: 'Minimum 3 litery',
        faltaletracentral: ' Brakuje środkowej litery',
      },
      homenuevo: 'NOWOŚĆ',
      statusEndGame: {
        faces_6_7: 'NADZWYCZAJNY!',
        faces_4_5: 'DOSKONAŁE!',
        faces_2_3: 'DOBRY POZIOM!',
        faces_0_1: 'STAĆ CIĘ NA WIĘCEJ!',
      },
    },

    //------------

    buttons: {
      statistics: 'Moje statystyki',
      share: () => (
        <>
          <IoMdShare /> <span>udostępnij</span>
        </>
      ),
      shareLabel: 'udostępnij',
    },
    words: {
      howToPlay: 'Jak grać',
      howToPlayContent:
        'Znajdź ukryte słowo w 6 próbach. Każda próba musi zawierać prawidłowe 5-literowe słowo. Po każdej próbie kolor liter wskazuje ukryte słowo. Rzeczowniki własne, liczba mnoga i odmieniane formy czasowników nie są dozwolone (tylko bezokolicznik).',
      examples: {
        title: 'Przykłady',
        first: {
          word: 'MIEDŹ',
          explanation: 'Litera M znajduje się w słowie i we właściwej pozycji.',
        },
        second: {
          word: 'MARZEC',
          explanation: 'Litera R znajduje się w słowie, ale w niewłaściwej pozycji.',
        },
        third: {
          word: 'GREEN',
          explanation: 'Litera N nie występuje w wyrazie.',
        },
      },
      messages: {
        filaincompleta: 'Niekompletny rząd',
        noencontrada: 'Nie znaleziono!',
        palabracorrecta: 'Prawidłowe słowo to:',
      },
      endGame: {
        puedesMejorar: 'STAĆ CIĘ NA WIĘCEJ!',
        insuperable: 'NIE DO POBICIA!',
        fantástico: 'FANTASTYCZNE!',
        excelente: 'DOSKONAŁE!',
        bravo: 'BRAWO!',
      },
      footer: 'NOWE SŁOWO KAŻDEGO DNIA!',
      disclaimer: "The Word to adaptacja oryginalnej gry stworzonej przez Josha Wardle'a (@powerlanguish).",
      fiveLetters: 'THE HIDDEN WORD 5',
      sixLetters: 'THE HIDDEN WORD 6',
      choose: 'Wybierać',
    },
    quiz: {
      pregunta: 'Pytanie',
    },
  },
}
