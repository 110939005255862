import classNames from 'classnames'

export function RowComponent(notFound, finished, attempts, solution, currentWord, index, wIndex, CellComponent) {
  return (
    <>
      <div
        key={`words_row_${wIndex}`}
        className={classNames('em-flex em-space-x-1 em-justify-center em-perspective em-items-center')}
      >
        <span
          className={classNames(
            'em-bg-disabled em-w-5 em-h-5 em-rounded-full em-text-white em-justify-center em-items-center em-flex em-mr-1 sm:em-mr-5',
          )}
        >
          {wIndex + 1}
        </span>
        {Array(solution.length)
          .fill()
          .map((_, lIndex) => (
            <CellComponent
              notFound={notFound && index === 0}
              key={`words_cell_${wIndex}_${lIndex}`}
              delayIndex={lIndex}
              letter={(index === 0 && currentWord?.[lIndex]) || ''}
              indexActive={!finished && index === 0 && currentWord?.length === lIndex}
              lastCell={lIndex === solution.length - 1}
              rowSelected={index === 0}
            />
          ))}
      </div>
    </>
  )
}
