import React from 'react'
import propTypes from 'prop-types'
import { useParams } from 'react-router'
import { isMobile } from 'react-device-detect'
import moment from 'moment'
import classNames from 'classnames'

import Game, { STATUS_FINISHED, STATUS_PLAYING, STATUS_RESOLVED, STATUS_RESTARTED } from 'layouts/Game'
import gamesApi, { gamesApiBase, getUserId } from 'api'
import Grid from './Board/Grid'
import Header from 'layouts/Header'
import I18n from 'lang'
import Layout from 'layouts/Layout'
import Timer from 'components/Timer'
import useGame from 'hooks/useGame'
import { ReactComponent as EmojiSmile } from 'assets/emoji-smile-fill.svg'
import { ReactComponent as Backspace } from 'assets/backspace.svg'

import './index.css'

export default function Sudoku(props) {
  const { gameProps, cols } = props

  const {
    title,
    color: bgColor,
    api: {
      attrs: { level = null },
      basepath: baseUrlApi,
    },
    icon: { big: bigIconSrc },
    url: baseUrl,
  } = gameProps

  const grid = gameProps.id.startsWith('sudoku-') ? 9 : 6

  const { id = 'last', locale } = useParams()

  const [, updateState] = React.useState()
  const forceUpdate = React.useCallback(() => {
    checkSudokuIsComplete()
    checkNumbersAreUnique()
    updateState({})
  }, [])

  const [boardIsComplete, setBoardIsComplete] = React.useState(false)
  const [candidatesKeyboard, setCandidatesKeyboard] = React.useState(false)

  const [sudoku, setSudoku] = React.useState(false)
  const [statistics, setStatistics] = React.useState([])

  const alreadyChecked = React.useRef(false)
  const itemActive = React.useRef([])
  const _board = React.useRef([])
  const _data = React.useRef([])
  const numberCols = React.useRef(3)

  const fetchStatistics = React.useCallback(async () => {
    const response = await gamesApi.post(`/user/stats/${baseUrlApi}`.replace(/\/+/g, '/'), {
      level,
    })
    const data = response.data

    setStatistics(data)
  }, [baseUrlApi, level])

  const processSudoku = React.useCallback(
    (data, isFinished = false) => {
      _data.current = data.cuadricula.split('\r\n').map((row) => row.split('').map((number) => number))

      _board.current = data.cuadriculauser.split('\r\n').map((row, rowIndex) =>
        row.split('').map((number, colIndex) => {
          const clickable = number === '#'

          return {
            active: false,
            disabled: false,
            clickable,
            rowIndex,
            colIndex,
            error: false,
            numberError: null,
            candidates: [],
            value: isFinished
              ? _data.current[rowIndex][colIndex]
              : clickable
                ? data?.estadouser?.cuadricula &&
                  data.estadouser.cuadricula[rowIndex] &&
                  data.estadouser.cuadricula[rowIndex][colIndex]
                  ? data.estadouser.cuadricula[rowIndex][colIndex]
                  : ''
                : number,
            number: number,
          }
        }),
      )

      forceUpdate()
      return data
    },
    [forceUpdate, _board, _data],
  )

  const fetchSudoku = React.useCallback(async () => {
    let params = ''
    if (level !== null) {
      params = new URLSearchParams({
        level,
      })
    }

    const response = await gamesApi.get(`${baseUrlApi}/get/${id}?${params.toString()}`.replace(/\/+/g, '/'))
    const data = response.data

    let isFinished = false
    if (data.estadouser.id === 2) {
      isFinished = true
    }
    if (data.estadouser.id === 3) {
      isFinished = true
    }

    setSudoku(data)

    processSudoku(data, isFinished)

    return data
  }, [baseUrlApi, level, title, id, setSudoku, processSudoku])

  const onKeyUp = React.useCallback(
    (e) => {
      if (itemActive.current.length > 0) {
        if (itemActive.current.length > 0) {
          if (e.key === 'ArrowUp' || e.key === 'ArrowLeft') {
            moveItem(-1, e.key)
          }
          if (e.key === 'ArrowDown' || e.key === 'ArrowRight') {
            moveItem(1, e.key)
          }
        }

        if (e.key === 'Escape') {
          _board.current[itemActive.current[0]][itemActive.current[1]].active = false
          itemActive.current = []

          forceUpdate()
        }
        if (e.key === 'Backspace') {
          resetItem()
        }
        const maxNumber = grid === 9 ? 9 : 6
        const numbersToInclude = Array(maxNumber)
          .fill(0)
          .map((_, i) => (i + 1).toString())
        if (numbersToInclude.includes(e.key.toString())) {
          changeItem(e.key)
        }
      }
    },
    [_board, itemActive, grid],
  )

  const onKeyDown = React.useCallback((e) => {
    if (
      e.code === 'ArrowDown' ||
      e.code === 'ArrowUp' ||
      e.code === 'ArrowLeft' ||
      e.code === 'ArrowRight' ||
      e.code === 'Space' ||
      e.key === 'Tab'
    )
      e.preventDefault()
  }, [])

  const moveItem = React.useCallback(
    (direction, key) => {
      let counter = 0
      let index = counter * direction

      while (true) {
        counter += 1
        index = direction * counter

        if (key === 'ArrowLeft' || key === 'ArrowRight') {
          if (!_board.current[itemActive.current[0]][itemActive.current[1] + index]) {
            break
          }

          if (_board.current[itemActive.current[0]][itemActive.current[1] + index].clickable) {
            onClickItem(itemActive.current[0], itemActive.current[1] + index)
            break
          }
        } else if (key === 'ArrowUp' || key === 'ArrowDown') {
          if (!_board.current[itemActive.current[0] + index]) {
            break
          }

          if (_board.current[itemActive.current[0] + index][itemActive.current[1]].clickable) {
            onClickItem(itemActive.current[0] + index, itemActive.current[1])
            break
          }
        }
      }

      forceUpdate()
    },
    [_board, itemActive],
  )

  const {
    setPause,
    setPlaying,
    initGame,

    // actions
    restart,
    validate,
    resolve,
    save,
    autoSave,

    // variables
    status,
    time,
    modal,
    message,
    isLoading,

    showEndGameModal,
    setShowEndGameModal,
  } = useGame({
    onKeyUp,
    onKeyDown,
    startFrom: parseInt(sudoku?.estadouser?.tiempo || 0, 10),
    statusInit: sudoku?.estadouser?.id,
    expirationDate: sudoku?.despublicado,
    locale,
  })

  const restartAllData = React.useCallback(async () => {
    setBoardIsComplete(false)
    setSudoku(false)
    setStatistics([])
    alreadyChecked.current = false
    itemActive.current = []
    _board.current = []
    _data.current = []

    fetchStatistics()
    initGame(async () => await fetchSudoku())
  }, [
    setBoardIsComplete,
    setSudoku,
    setStatistics,
    alreadyChecked,
    itemActive,
    _board,
    _data,
    fetchStatistics,
    initGame,
    fetchSudoku,
  ])

  const restartSudoku = React.useCallback(() => {
    restart(baseUrlApi, {
      gameid: sudoku.id,
      nivel: level,
    }).then((response) => {
      restartAllData()
    })
  }, [restartAllData, baseUrlApi, level, sudoku, restart])

  const resolveSudoku = React.useCallback(() => {
    resolve(baseUrlApi, {
      gameid: sudoku.id,
      nivel: level,
      gamedata: {
        tiempo: time,
      },
    }).then((response) => {
      restartAllData()
    })
  }, [baseUrlApi, level, sudoku, time, restartAllData])

  const saveSudoku = React.useCallback(
    (exit = false, newUrl = null, auto = false) => {
      const fnc = auto ? autoSave : save

      fnc(
        baseUrlApi,
        {
          gameid: sudoku.id,
          gamedata: {
            tiempo: time,
            nivel: level,
            grid: _board.current.map((row) => row.map((item) => item.value)),
          },
        },
        exit,
        newUrl,
      )
    },
    [level, sudoku, time],
  )

  const checkCell = React.useCallback(
    (row, col, _forceUpdate = true) => {
      if (!Number.isInteger(row) || !Number.isInteger(col)) {
        return null
      }

      const cell = _board.current[row][col]

      if (cell.value === '') {
        cell.numberError = null
        cell.error = false

        _forceUpdate && forceUpdate()

        return true
      }

      cell.numberError = true

      if (parseInt(cell.value, 10) === parseInt(_data.current[row][col], 10)) {
        cell.numberError = false
        cell.error = false

        _forceUpdate && forceUpdate()

        return true
      }

      _forceUpdate && forceUpdate()

      return false
    },
    [_board, _data],
  )

  const checkSudoku = React.useCallback(() => {
    const isComplete = _board.current
      .map((row, indexRow) => row.map((item, indexCol) => item.value !== '' && checkCell(indexRow, indexCol, false)))
      .every((row) => row.every((item) => item))

    alreadyChecked.current = true

    if (isComplete) {
      validateSudoku()
    }

    forceUpdate()
  }, [_board])

  const validateSudoku = React.useCallback(async () => {
    if (alreadyChecked.current === true) {
      return
    }

    const validated = await validate(`${baseUrlApi}`, {
      gameid: sudoku.id,
      gamedata: {
        tiempo: time,
        nivel: level,
        grid: _board.current.map((row) => row.map((item) => item.value)),
      },
    })

    alreadyChecked.current = true

    if (validated) {
      fetchStatistics()
      fetchSudoku()
    } else {
      forceUpdate()
    }
  }, [_board, time, level, sudoku, id])

  const checkSudokuIsComplete = React.useCallback(() => {
    const isComplete = _board.current.every((row) => row.every((item) => item.value !== ''))

    setBoardIsComplete(isComplete)

    return isComplete
  }, [_board])

  React.useEffect(() => {
    if (status === STATUS_RESTARTED) {
      restartSudoku()
    }

    if (status === STATUS_RESOLVED) {
      resolveSudoku()
    }

    if (!alreadyChecked.current && boardIsComplete && status === STATUS_PLAYING) {
      validateSudoku()
    }
  }, [status, boardIsComplete])

  React.useEffect(() => {
    restartAllData()
  }, [id, level]) // eslint-disable-line

  React.useEffect(() => {
    numberCols.current = cols
  }, [cols]) // eslint-disable-line

  const onClickItem = React.useCallback(
    (rowIndex, colIndex) => {
      if (status === STATUS_FINISHED) {
        return
      }

      if (itemActive.current.length > 0) {
        _board.current[itemActive.current[0]][itemActive.current[1]].active = false
      }
      _board.current[rowIndex][colIndex].active = true
      itemActive.current = [rowIndex, colIndex]

      forceUpdate()
    },
    [status],
  )

  const changeItem = React.useCallback(
    (value) => {
      if (itemActive.current.length === 0) {
        return null
      }

      alreadyChecked.current = false
      setBoardIsComplete(false)

      const _itemActive = _board.current[itemActive.current[0]][itemActive.current[1]]

      _itemActive.numberError = null
      _itemActive.error = false

      _itemActive.value = value

      forceUpdate()
    },
    [itemActive, _board],
  )

  const checkNumbersAreUnique = React.useCallback(() => {
    _board.current.forEach((row, rowIndex) =>
      row.forEach((item, colIndex) => {
        if (item.value !== '' && item.clickable) {
          item.error = !checkNumberIsUnique(rowIndex, colIndex)
        }
      }),
    )
  }, [_board])

  const checkNumberIsUnique = React.useCallback(
    (rowIndex = false, colIndex = false) => {
      if (itemActive.current.length === 0 && !Number.isInteger(rowIndex) && !Number.isInteger(colIndex)) {
        return true
      }

      const rowSelected = rowIndex
      const colSelected = colIndex

      const item = _board.current[rowSelected] && _board.current[rowSelected][colSelected]

      // check in the box
      const blockSizeRow = 3

      const blockSizeCol = numberCols.current
      // check sudoku block
      if (item?.value !== '' && item?.clickable) {
        for (let indexRowCount = 0; indexRowCount < blockSizeRow; indexRowCount++) {
          for (let indexColCount = 0; indexColCount < blockSizeCol; indexColCount++) {
            // check if it the start of block
            const currentRow = rowSelected - (rowSelected % blockSizeRow) + indexRowCount
            const currentCol = colSelected - (colSelected % blockSizeCol) + indexColCount
            const currentItem = _board.current[currentRow][currentCol]

            if (
              rowSelected !== currentRow &&
              colSelected !== currentCol &&
              currentItem.value !== '' &&
              parseInt(item.value, 10) === parseInt(currentItem.value, 10)
            ) {
              return false
            }
          }
        }
      }

      // check in the row and col
      return _board.current.every((row, _indexRow) =>
        row.every((_item, _indexCol) => {
          if (
            (_indexRow !== rowSelected && _indexCol !== colSelected) ||
            (_indexRow === rowSelected && _indexCol === colSelected) ||
            _item.value === ''
          ) {
            return true
          }

          return parseInt(_item.value, 10) !== parseInt(item.value, 10)
        }),
      )
    },
    [_board, itemActive, numberCols],
  )

  const resetItem = React.useCallback(() => {
    if (itemActive.current.length === 0) {
      return
    }

    if (_board.current[itemActive.current[0]][itemActive.current[1]].value !== '') {
      _board.current[itemActive.current[0]][itemActive.current[1]].value = ''
      _board.current[itemActive.current[0]][itemActive.current[1]].error = false
    } else {
      _board.current[itemActive.current[0]][itemActive.current[1]].candidates = []
    }

    forceUpdate()
  }, [itemActive, _board])

  const helpMenu = [
    {
      href: '',
      onClick: () => checkCell(itemActive.current[0], itemActive.current[1]),
      disabled: status !== STATUS_PLAYING || itemActive.current.length === 0,
      label: <I18n t='game.sudoku.actions.checkCell' />,
    },
    {
      href: '',
      onClick: checkSudoku,
      disabled: status !== STATUS_PLAYING,
      label: (
        <I18n
          t='game.sudoku.actions.checkSudoku'
          args={{ gameName: title }}
        />
      ),
    },
    {
      href: '',
      onClick: restartSudoku,
      disabled: status !== STATUS_PLAYING,
      label: (
        <I18n
          t='game.sudoku.actions.restartSudoku'
          args={{ gameName: title }}
        />
      ),
    },
    {
      href: '',
      onClick: resolveSudoku,
      disabled: status !== STATUS_PLAYING,
      label: (
        <I18n
          t='game.sudoku.actions.resolveSudoku'
          args={{ gameName: title }}
        />
      ),
    },
  ]

  const formatDate = (date) => {
    let dateText = moment(date).format('dddd, DD.MM.YY')
    return dateText.charAt(0).toUpperCase() + dateText.slice(1)
  }

  const onChangeCandidate = React.useCallback(
    (rowIndex, colIndex, number) => {
      if (status === STATUS_FINISHED) {
        return
      }

      const item = _board.current[rowIndex][colIndex]

      if (item.value !== '') {
        return
      }

      if (_board.current[rowIndex][colIndex].candidates.includes(number)) {
        _board.current[rowIndex][colIndex].candidates = _board.current[rowIndex][colIndex].candidates.filter(
          (item) => item !== number,
        )
      } else {
        _board.current[rowIndex][colIndex].candidates.push(number)
      }

      forceUpdate()
    },
    [_board, status],
  )

  return (
    <Layout isLoading={isLoading}>
      <Header />

      <Game
        statsUrl={`/user/stats${baseUrlApi}`}
        endGameModal={{
          isOpen: showEndGameModal,
          title,
          icon: bigIconSrc,
          titleBgColor: bgColor,
          onDate: formatDate(sudoku?.publicado),
          content: (
            <div className='em-flex em-items-center em-space-x-5 em-justify-center em-text-yellow'>
              <EmojiSmile className='em-w-[75px] em-h-[75px] em-rounded-full' />
              <div className='em-text-3xl em-uppercase'>
                <I18n t='game.messages.complete.title' />
              </div>
            </div>
          ),
          statistics: [
            {
              i18nKey: 'game.messages.complete.completedTime',
              value: moment((sudoku?.estadouser?.tiempo || 0) * 1000).format('mm:ss'),
            },
            {
              i18nKey: 'game.messages.complete.averageTime',
              value: moment((sudoku?.promediogeneral || 0) * 1000).format('mm:ss'),
            },
            {
              i18nKey: 'game.messages.complete.percentageGeneralCompleted',
              value: sudoku?.porcentajegeneralcompletado || 0,
            },
          ],
          shareUrl: `${gamesApiBase}user/stats${baseUrlApi}/share?userid=${getUserId()}&id=${sudoku?.id}&level=${level}`,
          onClose: () => setShowEndGameModal(false),
        }}
        status={status}
        historicalGames={{
          active: !isLoading,
          activeId: sudoku.id,
          to: baseUrl,
          url: `${baseUrlApi}/getlist?level=${level}`,
          icon: bigIconSrc,
        }}
        statistics={statistics}
        publicationDate={moment(sudoku.publicado).format('dddd, DD.MM.YY')}
        title={title}
        saveGame={() => saveSudoku()}
        onClickOutside={(newUrl) => saveSudoku(true, newUrl)}
        exitGame={
          status !== STATUS_FINISHED
            ? (e) => {
                saveSudoku(true)
                e.preventDefault()
              }
            : false
        }
        helpMenu={helpMenu}
        leftMenu={[
          {
            href: '',
            label: <I18n t='game.actions.help' />,
            options: helpMenu,
          },
        ]}
        middleMenu={
          <Timer
            setPause={setPause}
            setPlaying={setPlaying}
            status={status}
            time={time}
          />
        }
      >
        {message}

        <div
          className={classNames('em-flex em-justify-center em-p-0 em-pb-12 sm:em-p-12', {
            'em-flex-col': isMobile,
            'em-flex-row': !isMobile,
          })}
        >
          <div
            className={classNames('em-relative em-mb-12', {
              'em-mr-20': !isMobile,
              'em-m-auto em-mt-10': isMobile,
            })}
          >
            {sudoku && (
              <Grid
                className={gameProps.id}
                board={_board.current}
                onClickItem={onClickItem}
                onChangeCandidate={onChangeCandidate}
              />
            )}
          </div>
          {
            <div
              className={classNames('em-flex em-mt-0 em-flex-col em-items-around em-justify-center', {
                'em-m-auto': isMobile,
                'em-mr-0': !isMobile,
              })}
            >
              <div className={classNames('em-grid em-grid-cols-2 em-mb-2 em-gap-2')}>
                <button
                  className={classNames(
                    'em-rounded-md em-border-[1.5px] em-border-solid em-border-[#555555] em-p-2 em-text-center',
                    {
                      'em-bg-disabled-light !em-text-white': !candidatesKeyboard,
                    },
                  )}
                  onClick={() => setCandidatesKeyboard(false)}
                >
                  <I18n t='game.sudoku.normal' />
                </button>
                <button
                  className={classNames(
                    'em-rounded-md em-border-[1.5px] em-border-solid em-border-[#555555] em-p-2 em-text-center',
                    {
                      'em-bg-disabled-light !em-text-white': candidatesKeyboard,
                    },
                  )}
                  onClick={() => setCandidatesKeyboard(true)}
                >
                  <I18n t='game.sudoku.candidates' />
                </button>
              </div>
              <div
                className={classNames('em-m-auto', 'em-grid em-gap-2 em-items-center em-justify-center', {
                  'em-grid-cols-4': grid === 6,
                  'em-grid-cols-5': grid === 9,
                  'em-grid-cols-3 em-mt-0 em-ml-0': !isMobile,
                })}
              >
                {[...Array(grid).keys()].map((index) => {
                  const col = index % 3
                  const row = Math.floor(index / 3)
                  const totalCols = (grid - 1) % 3
                  const totalRows = Math.floor((grid - 1) / 3)

                  return (
                    <div
                      key={`keyboard_${index}`}
                      className={classNames(
                        'em-border-[1.5px] em-border-solid em-border-[#555555]',
                        'em-w-16 em-h-16',
                        'em-rounded-md',
                        'em-text-4xl em-bg-white !em-font-semibold',
                        'em-flex em-items-center em-justify-center',
                        'hover:em-bg-primary-light em-cursor-pointer',
                        'hover:em-text-primary',
                        'em-justify-center em-items-center',
                        {
                          '!em-justify-start': !isMobile && candidatesKeyboard && col === 0,
                          '!em-justify-end': !isMobile && candidatesKeyboard && col === totalCols,
                          '!em-items-start': !isMobile && candidatesKeyboard && row === 0,
                          '!em-items-end': !isMobile && candidatesKeyboard && row === totalRows,
                          'em-px-2 em-py-1': !isMobile && candidatesKeyboard,
                        },
                      )}
                      onClick={() => {
                        if (itemActive.current[0] === undefined) {
                          return
                        }
                        if (!candidatesKeyboard) {
                          changeItem(index + 1)
                        } else {
                          onChangeCandidate(itemActive.current[0], itemActive.current[1], index + 1)
                        }
                      }}
                    >
                      <span
                        className={classNames({
                          'em-text-[18px] em-relative': candidatesKeyboard,
                        })}
                      >
                        {index + 1}
                      </span>
                    </div>
                  )
                })}
                <div
                  className={classNames(
                    'em-text-4xl em-bg-white em-rounded-md',
                    'em-flex em-items-center em-justify-center',
                    'em-border-[1.5px] em-border-solid em-border-[#555555]',
                    'hover:em-bg-primary-light em-cursor-pointer',
                    {
                      'em-h-16 em-col-span-2': grid === 6,
                      'em-h-16': grid === 9,
                      '!em-col-span-3': !isMobile,
                    },
                  )}
                  onClick={() => resetItem()}
                >
                  <Backspace />
                </div>
              </div>
            </div>
          }

          {modal}
        </div>
      </Game>
    </Layout>
  )
}

Sudoku.propTypes = {
  title: propTypes.string,
  baseUrl: propTypes.string,
}

Sudoku.defaultProps = {
  title: 'Sudoku',
  baseUrl: 'sudoku',
}
