import React from 'react'
import propTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { useClickAway, useMedia } from 'react-use'

import useTimer from 'hooks/useTimer'
import gamesApi from 'api'
import { AlertButton, MessagePill, Alert2Buttons } from 'layouts/Game/messages'
import { gamesBasePathname } from 'config'
import { trigger } from 'lib/externalApi'
import useTimeStore from 'store/time'
import useSiteStore from 'store/site'

export const STATUS_INIT = 'init'
export const STATUS_PAUSED = 'paused'
export const STATUS_PLAYING = 'playing'
export const STATUS_SAVED = 'saved'
export const STATUS_SAVE = 'save'
export const STATUS_SAVELEXIRETO = 'savedlexireto'
export const STATUS_FINISHED = 'finished'
export const STATUS_INCOMPLETED = 'incompleted'
export const STATUS_ERROR = 'error'
export const STATUS_PAST = 'past' //para lexireto juegos pasados

export const TRANSLATE_STATUS = {
  5: STATUS_PAST,
  3: STATUS_FINISHED,
  2: STATUS_FINISHED,
  1: STATUS_INIT,
  0: STATUS_INIT,
}

/**
 *
 * @param {element} ref - ref to the element to be used as click away
 * @param {function} onClickOutside - callback when click outside
 * @param {bool} active - if true, the hook is active to listen to click outside
 * @returns {void}
 */
export const useClickOutside = (ref, onClickOutside, active) => {
  const isMobile = useMedia('(max-width: 625px)')

  useClickAway(
    ref,
    (e) => {
      function stopAll() {
        const body = document.querySelector('body')
        const tagA = e.target.closest('a')

        if (tagA && (tagA.pathname || tagA.href) && active && !tagA.classList.contains('ignore-click-outside')) {
          e.preventDefault()
          e.stopPropagation()
          e.stopImmediatePropagation()
          body.style.pointerEvents = 'none'

          let url = (tagA.pathname || tagA.href)?.replace(gamesBasePathname, '')
          if (tagA.closest('#rootGames') === null) {
            url = 'external::' + tagA.href
          } else {
            if (!url?.startsWith('/')) {
              url = `/${url?.replace(gamesBasePathname, '')}`
            }
          }

          onClickOutside && onClickOutside(url)
          setTimeout(() => (body.style.pointerEvents = 'auto'), 800)
          return
        }
      }

      if (isMobile && e.type === 'pointerup') {
        stopAll()
      } else if (!isMobile && e.type === 'pointerdown') {
        stopAll()
      }
    },
    ['pointerup', 'pointerdown'],
  )
}

/**
 * Hook to initialize the game.
 *
 * @param {Object} props
 * @param {number} props.startFrom - Start game at this time in seconds
 * @param {number} props.statusInit - Initial status from API
 * @param {string} props.expirationDate - Expiration date in string format from API
 * @param {string} props.locale - Language
 * @param {function} props.onKeyUp - Key up event
 * @param {function} props.onKeyDown - Key down event
 *
 * @returns {Object} useGame
 * @returns {string} useGame.status - Current status of the game
 * @returns {integer} useGame.time - Current time of the game
 * @returns {bool} useGame.showEndGameModal - Show end game modal
 * @returns {function} useGame.setShowEndGameModal - Set show end game modal
 * @returns {function} useGame.initGame - Function to initialize the game
 * @returns {function} useGame.restart - Function to restart the game
 * @returns {function} useGame.resolve - Function to resolve the game
 * @returns {function} useGame.validate - Function to validate the game
 * @returns {function} useGame.finish - Function to finish the game
 * @returns {function} useGame.save - Function to save the game
 * @returns {element} useGame.modal - Modals in the game
 * @returns {element} useGame.message - Function to show a message
 * @returns {bool} useGame.isLoading - Indicate if the game is loading
 *
 * @returns {function} useGame.setFinished - Function to set the game as finished. Used internally. Only changes the status in the game and dont call to API.
 * @returns {function} useGame.setPause - Function to set the game as paused. Used internally. Only changes the status in the game and dont call to API.
 * @returns {function} useGame.setPlaying - Function to set the game as playing. Used internally. Only changes the status in the game and dont call to API.
 * @returns {function} useGame.setReset - Function to set the game as reset. Used internally. Only changes the status in the game and dont call to API.
 * @returns {function} useGame.setStatus - Function to set the game status. Used internally. Only changes the status in the game and dont call to API.
 *
 */
export default function useGame(props) {
  const {
    decreasing = false,
    onTimeEnd = () => {},
    startFrom = 0,
    statusInit = 0,
    onKeyUp,
    onKeyDown = () => {},
    waitToShowEndGameModal = 0,
  } = props

  const history = useHistory()

  const [status, setStatus] = React.useState(TRANSLATE_STATUS[statusInit])
  const [modal, _setModal] = React.useState(null)
  const [message, _setMessage] = React.useState(null)
  const [errorModal, setError] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(false)
  const [showEndGameModal, setShowEndGameModal] = React.useState(false)
  const { autosave, setAutosave } = useSiteStore()

  const timeoutSaveGame = React.useRef(null)

  const setMessage = React.useCallback((message) => {
    _setModal(null)
    _setMessage(message)
  }, [])
  const setModal = React.useCallback((modal) => {
    _setModal(modal)
    _setMessage(null)
  }, [])

  const time = useTimer({ status, startFrom, decreasing, onTimeEnd })

  const setPlaying = () => {
    setStatus(STATUS_PLAYING)

    trigger('game:playing', null)

    setModal(null)
    setMessage(null)
    useTimeStore.getState().start()
  }

  const setPause = () => {
    useTimeStore.getState().clear()
    setStatus(STATUS_PAUSED)

    trigger('game:paused', null)

    setModal(
      <AlertButton
        messageKey={'pause'}
        onClickPrimary={setPlaying}
        active
        onClick={setPlaying}
      />,
    )
  }

  const isFinishedRef = React.useRef([])

  const setFinished = (byValidating = false) => {
    useTimeStore.getState().clear()
    setStatus(STATUS_FINISHED)

    trigger('game:finished', null)

    setMessage(null)

    const messagePill = (
      <MessagePill
        active
        messageKey={STATUS_FINISHED}
        shareButton={statusInit !== 3 ? () => setShowEndGameModal(true) : null}
      />
    )
    if (waitToShowEndGameModal === 0) {
      setMessage(messagePill)
    } else {
      if (isFinishedRef?.current?.[0]) {
        clearTimeout(isFinishedRef.current[0])
      }

      isFinishedRef.current[0] = setTimeout(() => {
        setMessage(messagePill)
      }, waitToShowEndGameModal)
    }

    if (byValidating) {
      if (isFinishedRef?.current?.[1]) {
        clearTimeout(isFinishedRef.current[1])
      }
      isFinishedRef.current[1] = setTimeout(() => {
        setShowEndGameModal(true)
      }, waitToShowEndGameModal)
    }
  }

  /**
   *
   * @param {function} fn Function to call when the game is initialized
   *
   * @returns {void}
   */
  const initGame = async (fn) => {
    setIsLoading(true)
    setReset()

    trigger('game:loaded', null)

    setModal(
      <AlertButton
        messageKey={'init'}
        onClickPrimary={() => {
          setError(null)
          setPlaying()
        }}
        onClose={() => {
          setError(null)
          setPlaying()
        }}
        active
        onClick={setPlaying}
      />,
    )

    await fn()

    setIsLoading(false)
  }

  const setReset = () => {
    useTimeStore.getState().reset()
    setStatus(STATUS_INIT)

    if (isFinishedRef?.current?.[0]) {
      clearTimeout(isFinishedRef.current[0])
    }

    if (isFinishedRef?.current?.[1]) {
      clearTimeout(isFinishedRef.current[1])
    }

    setShowEndGameModal(false)
  }

  React.useEffect(
    () => {
      if (errorModal) {
        setModal(
          <AlertButton
            active
            messageKey={errorModal}
            onClickPrimary={() => {
              setError(null)
              setPlaying()
            }}
            onClose={() => {
              setError(null)
              setPlaying()
            }}
          />,
        )
      }
    },
    [errorModal], // eslint-disable-line
  )

  React.useEffect(
    () => {
      if (TRANSLATE_STATUS[statusInit] === STATUS_INIT) {
        setReset()
      } else if (TRANSLATE_STATUS[statusInit] === STATUS_FINISHED) {
        setFinished()
      }
    },
    [statusInit], // eslint-disable-line
  )

  React.useEffect(() => {
    if (onKeyUp) {
      if (status !== STATUS_PLAYING) {
        document.removeEventListener('keyup', onKeyUp, false)
      }

      if (status === STATUS_PLAYING) {
        document.addEventListener('keyup', onKeyUp, false)
      }
    }

    if (onKeyDown) {
      if (status !== STATUS_PLAYING) {
        document.removeEventListener('keydown', onKeyDown, false)
      }

      if (status === STATUS_PLAYING) {
        document.addEventListener('keydown', onKeyDown, false)
      }
    }

    return () => {
      document.removeEventListener('keyup', onKeyUp, false)
      document.removeEventListener('keydown', onKeyDown, false)
    }
  }, [status]) // eslint-disable-line react-hooks/exhaustive-deps

  const okRestart = async (baseUrlApi, params = {}) => {
    setIsLoading(true)

    const _params = new URLSearchParams(params).toString()
    const restartUrl = `/userestado/reiniciar/${baseUrlApi}?${_params}`.replace(/\/+/g, '/')
    let response = null
    try {
      response = await gamesApi.post(restartUrl, params)
    } catch (error) {
      setError('errorRestarting')
      setStatus(STATUS_ERROR)
    }

    trigger('game:restarted', null)

    setReset()
    setIsLoading(false)
    return response
  }

  /**
   *
   * @param {string} baseUrlApi
   * @param {Object} params
   *
   * @returns {Promise}
   */
  const restart = (baseUrlApi, params = {}) => {
    setStatus(STATUS_PAUSED)
    return new Promise(async (resolve, reject) => {
      setModal(
        <Alert2Buttons
          active
          messageKey={'restart'}
          onClickPrimary={async () => {
            const response = okRestart(baseUrlApi, params)
            resolve(response)
          }}
          onClickSecondary={() => {
            setPlaying()
            reject()
          }}
          onClose={() => {
            setPlaying()
            reject()
          }}
        />,
      )
    })
  }

  const okResolve = async (baseUrlApi, params = {}, showModal = false) => {
    setIsLoading(true)

    const _params = new URLSearchParams(params).toString()
    const okResolveUrl = `/userestado/resolver/${baseUrlApi}?${_params}`.replace(/\/+/g, '/')
    let response = null
    try {
      response = await gamesApi.post(okResolveUrl, params)
    } catch (error) {
      setError('errorResolving')
      setStatus(STATUS_ERROR)
    }

    trigger('game:resolved', null)

    setFinished(showModal)
    setIsLoading(false)
    return response
  }

  /**
   *
   * @param {string} baseUrlApi
   * @param {Object} params
   *
   * @returns {Promise}
   */
  const resolve = async (baseUrlApi, params = {}) => {
    setStatus(STATUS_PAUSED)
    return new Promise((resolve, reject) => {
      setModal(
        <Alert2Buttons
          active
          messageKey={'resolve'}
          onClickPrimary={async () => {
            const response = okResolve(baseUrlApi, params)
            resolve(response)
          }}
          onClickSecondary={() => {
            setPlaying()
            reject()
          }}
          onClose={() => {
            setPlaying()
            reject()
          }}
        />,
      )
    })
  }

  /**
   *
   * @param {string} baseUrlApi
   * @param {Object} params - Params to send to the API
   *
   * @returns {bool} - Valid or not
   */
  const validate = async (baseUrlApi, params = {}) => {
    setIsLoading(true)

    const resolveUrl = `/${baseUrlApi}/valida`.replace(/\/+/g, '/')
    let isValidate = false

    try {
      await gamesApi.post(resolveUrl, params)

      isValidate = true

      await finish(baseUrlApi, params)
    } catch (error) {
      setError('errorChecking')
      setStatus(STATUS_ERROR)
    }

    setIsLoading(false)
    return isValidate
  }

  /**
   *
   * @param {string} baseUrlApi
   * @param {Object} params - Params to send to the API
   *
   * @returns {void}
   */
  const finish = async (baseUrlApi, params = {}) => {
    setIsLoading(true)

    const finishUrl = `/userestado/terminar/${baseUrlApi}`.replace(/\/+/g, '/')

    try {
      await gamesApi.post(finishUrl, params)
      setFinished(true)
    } catch (error) {
      setError('errorFinishing')
      setStatus(STATUS_ERROR)
      throw error
    }

    setIsLoading(false)
  }

  const okSave = async (baseUrlApi, params = {}) => {
    const saveUrl = `/userestado/save/${baseUrlApi}`.replace(/\/+/g, '/')
    let response = null

    try {
      response = await gamesApi.post(saveUrl, params)
      // setStatus(STATUS_SAVED)
    } catch (error) {
      setError('errorSaving')
      setAutosave(false)
      setStatus(STATUS_ERROR)
    }

    return response
  }

  /**
   *
   * @param {string} baseUrlApi
   * @param {Object} params Params to send to the API
   * @param {bool} exit After saving, exit the game
   * @param {string} newUrl Redirect to new url
   *
   * @returns {Promise} Promise with the response of the API
   */
  const save = (baseUrlApi, params = {}, exit = false, newUrl = false) =>
    new Promise(async (resolve, reject) => {
      if (timeoutSaveGame.current) {
        clearTimeout(timeoutSaveGame.current)
      }

      if (exit) {
        if (autosave) {
          await okSave(baseUrlApi, params)
          useTimeStore.getState().clear()
          if (newUrl?.startsWith('external::')) {
            window.location = newUrl.replace('external::', '')
          } else {
            history.push(newUrl || '/')
          }

          return
        }

        setModal(
          <Alert2Buttons
            active
            messageKey={STATUS_SAVE}
            onClose={setPlaying}
            onClickPrimary={async () => {
              const response = await okSave(baseUrlApi, params)
              useTimeStore.getState().clear()
              history.push(newUrl || '/')

              resolve(response)
            }}
            onClickSecondary={(e) => {
              e.preventDefault()
              history.push(newUrl || '/')
            }}
          />,
        )
      } else {
        timeoutSaveGame.current = setTimeout(
          async (baseUrlApi, params, resolve) => {
            const response = await okSave(baseUrlApi, params)
            resolve(response)
          },
          1000,
          baseUrlApi,
          params,
          resolve,
        )
      }
    })

  /**
   *
   * @param {string} baseUrlApi
   * @param {Object} params Params to send to the API
   * @param {bool} exit After saving, exit the game
   * @param {string} newUrl Redirect to new url
   *
   * @returns {Promise} Promise with the response of the API
   */
  const autoSave = (baseUrlApi, params = {}) =>
    new Promise(async (resolve, reject) => {
      if (!autosave) {
        return
      }

      if (timeoutSaveGame.current) {
        clearTimeout(timeoutSaveGame.current)
      }

      timeoutSaveGame.current = setTimeout(
        async (baseUrlApi, params, resolve) => {
          await okSave(baseUrlApi, params)
        },
        1000,
        baseUrlApi,
        params,
        resolve,
      )
    })

  return {
    status,
    time,
    initGame,
    showEndGameModal,
    setShowEndGameModal,
    setFinished,
    setPause,
    setPlaying,
    setReset,
    setStatus,
    restart,
    resolve,
    resolveForce: okResolve,
    validate,
    finish,
    save,
    autoSave,
    modal,
    message,
    isLoading,
    setMessage,
    setModal,
  }
}

useGame.propTypes = {
  startFrom: propTypes.number,
  statusInit: propTypes.number,
  onKeyUp: propTypes.func,
  expirationDate: propTypes.string,
  customExpirationDate: propTypes.string,
  locale: propTypes.string,
  onKeyDown: propTypes.func,
}

useGame.defaultProps = {
  startFrom: 0,
  statusInit: 0,
  onKeyUp: () => {},
  expirationDate: '',
  customExpirationDate: null,
  locale: 'es',
  onKeyDown: () => {},
}
