const LetrasConectadasES = {
  title: 'Letras',
  actions: {
    checkGame: 'Comprovar letras',
    resolveGame: 'Resolver',
    restartGame: 'Reiniciar',
    apply: 'Aplicar',
    clean: 'Borrar',
  },
  messages: {
    saved: {
      message: ({ date }) => (
        <>
          El juego se ha guardado correctamente. Lo podrás terminar hasta el <strong>{date}</strong>
        </>
      ),
    },
    letters: 'Letras',
    howToPlayTitle: 'Cómo se juega',
    howToPlayText1:
      'Encuentra las siete palabras que hemos ocultado seleccionando casillas contiguas en todas las direcciones, salvo en diagonal.',
    howToPlayText12: 'Puedes utilizar cada letra tantas veces como quieras, pero no en una misma palabra.',
    howToPlayText2: '¡Atención! No todas las palabras que puedas formar serán válidas. Sólo las que proponemos.',
    howToPlayTextFull:
      'Encuentra las siete palabras que hemos ocultado seleccionando casillas contiguas en todas las direcciones, salvo en diagonal. Puedes utilizar cada letra tantas veces como quieras, pero no en una misma palabra. ¡Atención! No todas las palabras que puedas formar serán válidas. Sólo las que proponemos.',
    correctword: '¡Palabra correcta!',
    wrongword: '¡Palabra incorrecta!',
    repeatedword: '¡Palabra repetida!',
  },
}

export default LetrasConectadasES
