export const getStatuses = (currentWord, solution) => {
  if (!currentWord) {
    return []
  }

  const statuses = Array(currentWord.length).fill('wrong')
  let solutionLetters = {}
  let currentWordLetters = {}

  for (let i = 0; i < solution.length; i++) {
    solutionLetters = {
      ...solutionLetters,
      [solution[i]]: solutionLetters[solution[i]] ? solutionLetters[solution[i]] + 1 : 1,
    }

    if (solution[i] === currentWord[i]) {
      currentWordLetters = {
        ...currentWordLetters,
        [currentWord[i]]: currentWordLetters[currentWord[i]] ? currentWordLetters[currentWord[i]] + 1 : 1,
      }

      statuses[i] = 'exact'
      continue
    }
  }

  for (let i = 0; i < solution.length; i++) {
    if (statuses[i] === 'exact') {
      continue
    }

    currentWordLetters = {
      ...currentWordLetters,
      [currentWord[i]]: currentWordLetters[currentWord[i]] ? currentWordLetters[currentWord[i]] + 1 : 1,
    }

    if (currentWordLetters[currentWord[i]] > solutionLetters[currentWord[i]]) {
      continue
    }

    if (solution.includes(currentWord[i])) {
      statuses[i] = 'included'
      continue
    }
  }

  return statuses
}
