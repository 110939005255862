import moment from 'moment'

export function getMomentLocale(locale) {
  let _locale = locale
  switch (locale) {
    case 'en':
      _locale = 'en-gb'
      break

    case 'pt':
    case 'br':
      _locale = 'pt-br'
      break

    case 'ca':
      _locale = 'ca'
      break

    case 'ge':
      _locale = 'de'
      break

    case 'pl':
      _locale = 'pl'
      break

    case 'fr':
      _locale = 'fr'
      break

    case 'it':
      _locale = 'it'
      break

    default:
      _locale = 'es'
      break
  }

  return _locale
}

export function updateMomentLocale(locale) {
  moment.locale(getMomentLocale(locale))
}
