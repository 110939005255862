import { isMobile } from 'react-device-detect'
import classNames from 'classnames'

import './Grid.scss'

export default function Grid(props) {
  const { board, onClickItem, onChangeCandidate } = props

  return (
    <div
      id='grid-container'
      className={classNames(`sudoku_${props.className}`, 'em-w-full')}
    >
      <table
        border='0'
        cellSpacing='0'
        cellPadding='0'
        className='em-border-2 em-border-solid em-border-black'
      >
        <tbody>
          {board.map((items, rowIndex) => (
            <tr
              key={`tr_${rowIndex}`}
              className='em-border em-border-disabled'
            >
              {items.map((item, colIndex) => (
                <td
                  key={`tr_${colIndex}`}
                  onClick={() => item.clickable && onClickItem(rowIndex, colIndex)}
                  className='
                            group
                            em-w-12 em-h-12 sm:em-w-16 sm:em-h-16
                            em-m-0 em-p-0
                            em-cursor-pointer em-relative
                            !em-align-top
                          '
                >
                  {item.value !== '' && item.numberError === true && (
                    <div className='em-absolute em-left-0 em-top-0 em-w-full em-h-full em-flex em-items-center em-justify-center'>
                      <div className='em-origin-center em-w-full em-border-danger em-border-b-2 em-absolute em-transform em-rotate-45' />
                      <div className='em-origin-center em-h-full em-border-danger em-border-l-2 em-absolute em-transform em-rotate-45' />
                    </div>
                  )}
                  <div
                    className={classNames(
                      'em-border-l em-border-disabled',
                      'em-left-0 em-top-0 em-w-full em-h-full',
                      'em-text-4xl',
                      '!em-font-semibold',
                      'em-transition-all',
                      'em-outline-none',
                      'em-flex em-items-center em-justify-center',
                      {
                        'em-bg-white em-cursor-pointer': item.clickable && !item.active,
                        'group-hover:em-bg-primary-light hover:em-z-10': item.clickable && !item.active,
                        'em-bg-disabled-lightest em-text-disabled-light em-cursor-default': !item.clickable,
                        'em-bg-primary em-text-white em-z-20': item.active,
                        'em-text-green-600': item.numberError === false,
                        'em-text-danger': item.numberError === true,
                      },
                    )}
                  >
                    {item.value}
                    {item.value === '' && (
                      <div className='em-absolute em-left-0 em-top-0 em-w-full em-h-full em-grid em-grid-cols-3'>
                        {Array.from({
                          length: board.length,
                        }).map((i, index) => (
                          <div
                            key={`dot_${index}_${rowIndex}_${colIndex}}`}
                            className={classNames(
                              'em-transition-all',
                              '!em-leading-none',
                              'em-opacity-0',
                              'md:em-text-[17px] em-text-[12px] em-text-disabled-lighter em-font-thin',
                              'em-flex em-items-center em-justify-center',
                              {
                                'em-opacity-100': item.candidates?.includes(index + 1),
                                'hover:em-opacity-100': item.active && !isMobile,
                                '!em-text-white': item.active && !isMobile,
                              },
                            )}
                            onClick={() => {
                              if (!item.active || isMobile) return
                              onChangeCandidate(rowIndex, colIndex, index + 1)
                            }}
                          >
                            {index + 1}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  {item.error && (
                    <div className=' em-w-1 em-h-1 em-absolute em-right-1 em-bottom-1 em-rounded-full em-bg-danger-light lg:em-w-3 lg:em-h-3' />
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
