const LexiRetoGameLocaleCA = {
  expirationDate: "dia d'avui a les 23:59:59",
  title: 'Set Lletres',
  resolveGame: 'Resoldre',
  restartGame: 'Reiniciar',
  howToPlay: 'Com es juga',
  howToPlayText: 'Com es juga',
  howToPlayTextFull: () => (
    <>
      <div className='mb-1'>
        <strong>Com es juga</strong>
      </div>
      <ul>
        <li>Forma paraules d'almenys 3 lletres. Pots repetir les lletres, però sempre incloent la lletra central.</li>
        <li>No s'admeten noms propis, plurals i formes verbals conjugades (només infinitius).</li>
        <li>
          Troba paraules que incloguin les 7 lletres (Heptacrack!), pujaràs de posició en Com va el teu joc i milloraràs
          les teves estadístiques.
        </li>
        <li>
          Puntuació: les paraules de 3 lletres sumen 1 punt I les de 4 lletres, 2 punts. A partir de 5 lletres,
          obtindràs tants punts com lletres tingui la paraula. Els heptacracks valen 10 punts.
        </li>
        <li>
          ATENCIÓ. Només podràs resoldre cada joc durant el dia en què es publica. A les 12 de la nit queda inactiu i
          només el pots consultar.
        </li>
      </ul>
    </>
  ),
  totalpalabras: 'Les teves paraules',
  tuspalabras: 'Les teves paraules',
  empiezanpor: 'Comencen per: ',
  encontradas: 'Trobades ',
  heptacrackencontradas: 'Heptacracks',
  comovatujuego: 'Com va el teu joc',
  comovatujuego_heptacrack: 'Heptacrack!',
  comovatujuego_nuevonivel: 'Puges posició!',
  marcador: 'Marcador',
  puntuacion: 'Els teus punts',
  maximo: 'Màxim',
  encontrados: 'Trobats',
  actions: {
    apply: 'Aplicar',
    clean: 'Esborrar',
    reordenar: 'Reordenar',
  },
  messages: {
    correctword: 'Bé!',
    wrongword: 'No trobada',
    repeatedword: 'Ja trobada',
    minimo3letras: 'Mínim 3 lletres',
    faltaletracentral: 'Falta la lletra central',
  },
  homenuevo: 'NOU',
  statusEndGame: {
    faces_6_7: 'EXTRAORDINARI!',
    faces_4_5: 'EXCEL·LENT!',
    faces_2_3: 'BON NIVELL!',
    faces_0_1: 'POTS MILLORAR!',
  },
}

export default LexiRetoGameLocaleCA
