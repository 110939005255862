import TilesHexagon from './TilesHexagon'
import TilesHexagonAnchor from './TilesHexagonAnchor'
import './TilesBoard.css'
import React from 'react'
import TilesHexagonTransition from './TilesHexagonTransition'
import TilesSuccessModal from './TilesSuccessModal'

export default function TilesBoard(props) {
  // const hexagonWidth  = 192.939    //hexagon's width
  // const hexagonHeight = 219.529    //hexagon's height
  const a = 111.399435 //hexagon's side length
  const paddingBetweenHexagons = 15

  const paddingLeftSVG = 30
  const paddingTopSVG = 45

  // Distance between adjacent hexagons on different rows
  // cx distance on x axis, cy distance on y axis, h hypotenuse = distance
  let cy = (Math.sqrt(3) / 2) * a + paddingBetweenHexagons / 2
  let h = Math.sqrt(3) * a + paddingBetweenHexagons
  let cx = Math.sqrt(h * h - cy * cy)

  //Compute viewBox size
  const viewBoxWidth = 18 * cx + paddingLeftSVG * 2
  const viewBoxHeight = 17 * cy + paddingTopSVG * 2

  //Calc position for each hexagon
  let cells = props.board.cells.map((cell) => {
    let isOddRow = cell.y % 2 !== 0

    let dx = -paddingLeftSVG + (isOddRow ? cx : 0)
    let dy = -paddingTopSVG

    return {
      column: cell.x,
      row: cell.y,
      x: ((dx + cell.x * cx * 2) / viewBoxWidth) * 100,
      y: ((dy + cell.y * cy) / viewBoxHeight) * 100,
    }
  })

  let lastPairedDTO = {}
  if (props._lastPaired.cell1_id !== null) {
    lastPairedDTO.cell1_id = props._lastPaired.cell1_id
    lastPairedDTO.x1 = cells[props._lastPaired.cell1_id].x
    lastPairedDTO.y1 = cells[props._lastPaired.cell1_id].y
    lastPairedDTO.cell2_id = props._lastPaired.cell2_id
    lastPairedDTO.x2 = cells[props._lastPaired.cell2_id].x
    lastPairedDTO.y2 = cells[props._lastPaired.cell2_id].y
    lastPairedDTO.success = props._lastPaired.success
  }

  return (
    <div
      id='svg-container'
      className={'flex tiles-board-wrapper'}
    >
      <div className='rotation-wrapper-outer'>
        <div className='rotation-wrapper-inner'>
          <svg
            className={
              'tiles-board em-transform em-rotate-90 md:em-rotate-0 em-translate-x-117 -em-translate-y-2/3 em-scale-200 md:em-translate-x-0 md:-em-translate-y-0 md:em-scale-100'
            }
            x='0'
            y='0'
            viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
            preserveAspectRatio='none'
          >
            {cells.map((cell, index) => (
              <TilesHexagon
                key={'hexagon-' + cell.column + '-' + cell.row}
                tileId={props._currentGameData.cells[index].tileId}
                x={cell.x}
                y={cell.y}
                rotation={props._currentGameData.cells[index].rotation}
                additionalRotation={props.board.add_rotation}
                selected={props._currentGameData.cells[index].selected}
                paired={props._currentGameData.cells[index].paired}
              />
            ))}

            {props._lastPaired.cell1_id !== null && (
              <TilesHexagonTransition
                tileId1={props._currentGameData.cells[props._lastPaired.cell1_id].tileId}
                tileId2={props._currentGameData.cells[props._lastPaired.cell2_id].tileId}
                x1={lastPairedDTO.x1}
                y1={lastPairedDTO.y1}
                x2={lastPairedDTO.x2}
                y2={lastPairedDTO.y2}
                rotation1={props._currentGameData.cells[props._lastPaired.cell1_id].rotation}
                rotation2={props._currentGameData.cells[props._lastPaired.cell2_id].rotation}
                additionalRotation={props.board.add_rotation}
                success={props._lastPaired.success}
              />
            )}

            {!props.isFinished &&
              cells.map((cell, index) => (
                <TilesHexagonAnchor
                  key={'hexagon-a-' + cell.column + '-' + cell.row}
                  idx={index}
                  x={cell.x}
                  y={cell.y}
                  selected={props._currentGameData.cells[index].selected}
                  paired={props._currentGameData.cells[index].paired}
                  onClick={props.onClick}
                />
              ))}
          </svg>
        </div>
      </div>
      {props._lastPaired.cell1_id !== null && <TilesSuccessModal success={props._lastPaired.success} />}
    </div>
  )
}
