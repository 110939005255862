import { IoMdShare } from 'react-icons/io'

export const it = {
  close: 'Chiudi',
  by: 'Di',
  play: 'Gioca',
  game: {
    historicalGames: {
      playing: 'IN GIOCO',
    },
    new: 'Nuovo',
    actions: {
      save: 'Salva',
      download: 'Scarica',
      exit: 'Esci',
      help: 'Aiuto',
    },
    timer: {
      show: 'Mostra',
      hide: 'Nascondi',
    },
    yourPoints: 'I tuoi punti',
    heptacracks: 'Heptacracks',
    yourWords: 'Le tue parole',
    yourAnswers: 'Le tue risposte',
    send: 'Invia',
    tusclics: 'I tuoi clic',
    messages: {
      init: {
        message: 'Sei pronto a iniziare?',
        button: 'Gioca',
      },
      pause: {
        message: 'Il gioco è in pausa',
        button: 'Continua',
      },
      restart: {
        message: 'Stai per riavviare il gioco',
        buttonPrimary: 'Riavvia',
        buttonCancel: 'Torna a',
      },
      resolve: {
        message:
          'Se scegli di RISOLVERE, questo gioco sarà considerato incompleto nelle mie statistiche. Sei soddisfatto?',
        buttonPrimary: 'Sì',
        buttonCancel: 'No',
      },
      save: {
        message: 'Stai per uscire dal gioco, vuoi salvare le modifiche?',
        buttonPrimary: 'Sì',
        buttonCancel: 'No',
      },
      errorSaving: {
        message: 'Errore durante il salvataggio. Riprova.',
        button: 'Chiudi',
      },
      errorRestarting: {
        message: 'Errore durante il riavvio del gioco. Riprova.',
        button: 'Chiudi',
      },
      errorResolving: {
        message: 'Errore durante la risoluzione del gioco. Riprova.',
        button: 'Chiudi',
      },
      errorFinishing: {
        message: 'Errore durante la fine del gioco. Riprova.',
        button: 'Chiudi',
      },
      errorChecking: {
        message: 'Peccato! Non sei riuscito a completare il gioco. Continua a provare',
        button: 'Chiudi',
      },
      finished: {
        message: 'Hai completato questo gioco. Puoi solo visualizzarlo.',
        button: 'Torna',
      },
      complete: {
        title: 'Bravo!',
        endGameTitle: 'GIOCO FINITO!',
        averageTime: 'Tempo medio globale',
        percentageGeneralCompleted: 'Percentuale completata',
        completedTime: 'Partita terminata in',
        share: 'Condividi i tuoi risultati',
        shortDescription: 'Controlla se hai migliorato nelle mie statistiche',
        description: 'Hai completato il gioco. Controlla se sei migliorato nelle mie statistiche',
        button: 'Chiudi',
        onDate: (date) => <>Giocato il {date}</>,
      },
      incomplete: {
        message: 'Questo è il gioco risolto. Nelle mie statistiche, sarà considerato incompleto',
      },
      past: {
        message: 'Il gioco è terminato. Puoi solo visualizzarlo.',
        button: 'Torna',
      },
      saved: {
        // message: 'Non è più necessario utilizzare il pulsante Salva. Ogni volta che avanzi, il gioco viene salvato automaticamente.',
        message: 'Il gioco è stato salvato con successo',
        button: 'Esci',
      },
      savedlexireto: {
        message: ({ date }) => <>Il gioco è stato salvato con successo</>,
        button: 'Esci',
      },
    },
    currentChallenge: {
      actions: {
        next: 'Prossimo',
        showExplain: 'Mostra la spiegazione',
        finish: 'Fine',
      },
      messages: {
        finish: {
          title: 'Test completato!',
          description: ({ corrects, errors }) => (
            <>
              Risposte corrette {corrects}, errate {errors}.<br />
              Puoi controllare i tuoi risultati cumulati nelle mie statistiche.
            </>
          ),
          button: 'Chiudi',
        },
        showExplain: 'La ragione della risposta',
      },
      statusEndGame: {
        good: 'BRAVO!',
        neutral: 'BENE!',
        bad: 'PUOI FARE MEGLIO!',
      },
    },
    crosswords: {
      questions: 'Domande di parole crociate',
      horizontals: 'Orizzontali',
      verticals: 'Verticali',
      horizontal: 'Orizzontale',
      vertical: 'Verticale',
      messages: {
        finish: {
          title: 'Bravo!',
          description: 'Hai finito le tue parole.\nControlla se hai migliorato nelle statistiche.',
          button: 'Esci',
        },
        incomplete: {
          title: '',
          description: 'Qui hai risolto le parole che hai scritto. Le mie statistiche verranno considerate incomplete.',
          button: 'Esci',
        },
      },
      actions: {
        checkLetter: 'Verifica lettera',
        checkWord: 'Verifica parola',
        checkCrossword: ({ gameName }) => <>Verifica {gameName}</>,
        resolveCrossword: 'Risolvi',
        restartCrossword: 'Riavvia',
      },
    },
    sudoku: {
      candidates: 'Possibile',
      levels: {
        easy: 'Base',
        medium: 'Avanzato',
        hard: 'Esperto',
      },
      messages: {},
      actions: {
        checkCell: 'Verifica cella',
        checkSudoku: ({ gameName }) => <> Verifica {gameName}</>,
        resolveSudoku: 'Risolvi',
        restartSudoku: 'Riavvia',
      },
    },
    buttons: {
      statistics: 'Le mie statistiche',
      share: () => (
        <>
          <IoMdShare /> <span>Condividi</span>
        </>
      ),
      shareLabel: 'Condividi',
    },
    words: {
      howToPlay: 'Come giocare',
      howToPlayContent:
        "Trova la parola nascosta in 6 tentativi. Ogni tentativo deve essere una parola valida di 5 lettere. Dopo ogni tentativo, il colore delle lettere ti darà indizi sulla parola nascosta. I nomi propri, i plurali e i verbi coniugati non sono consentiti (solo l'infinito).",
      examples: {
        title: 'Esempi',
        first: {
          word: 'RAME',
          explanation: 'La lettera R è nella parola ed è al posto giusto.',
        },
        second: {
          word: 'MARZO',
          explanation: 'La lettera R è nella parola ma nel posto sbagliato.',
        },
        third: {
          word: 'VERDE',
          explanation: 'La lettera E non è nella parola.',
        },
      },
      messages: {
        filaincompleta: 'Riga incompleta',
        noencontrada: 'Non trovata!',
        palabracorrecta: 'La parola corretta è:',
      },
      endGame: {
        puedesMejorar: 'PUOI MIGLIORARE',
        insuperable: 'INSUPERABILE!!',
        fantástico: 'FANTASTICO!',
        excelente: 'ECCELLENTE!',
        bravo: 'BRAVO!',
      },
      footer: 'UNA NUOVA PAROLA OGNI GIORNO!',
      disclaimer:
        'The Word è un adattamento dell’allenamento mentale del gioco originale creato da Josh Wardle (@powerlanguish).',
      choose: 'Scegliere',
    },
    quiz: {
      pregunta: 'Domanda',
    },
    letrasConectadasGame: {
      title: 'Lettere Connesse',
      actions: {
        checkGame: 'Controlla Lettere',
        resolveGame: 'Risolvi',
        restartGame: 'Ricomincia',
        apply: 'Applica',
        clean: 'Cancella',
      },
      messages: {
        saved: {
          message: ({ date }) => (
            <>
              Il gioco è stato salvato con successo. Potrai completarlo entro il <strong>{date}</strong>
            </>
          ),
        },
        letters: 'Lettere',
        howToPlayTitle: 'Come giocare',
        howToPlayText1:
          'Trova sette parole che abbiamo nascosto, selezionando le caselle adiacenti in tutte le direzioni, tranne che in diagonale.',
        howToPlayText12: 'Puoi usare ogni lettera tutte le volte che vuoi, ma non nella stessa parola.',
        howToPlayText2:
          'Attenzione! Non tutte le parole che puoi formare saranno valide. Solo quelle che ti suggeriamo.',
        howToPlayTextFull:
          'Trova sette parole che abbiamo nascosto, selezionando le caselle adiacenti in tutte le direzioni, tranne che in diagonale. Puoi usare ogni lettera tutte le volte che vuoi, ma non nella stessa parola. Attenzione! Non tutte le parole che puoi formare saranno corrette. Solo quelle che suggeriamo.',
        correctword: 'Parola corretta!',
        wrongword: 'Parola errata!',
        repeatedword: 'Parola ripetuta!',
      },
    },
    differencesGame: {
      title: '8 Errori',
      hits: 'colpi',
      clicks: 'clic',
      resolveGame: 'Risolvi',
      restartGame: 'Ricomincia',
      howToPlay: 'Come giocare',
      howToPlayText: () => (
        <>
          Trova e individua 8 errori nel secondo disegno. <br />
          Hai 12 possibilità.
        </>
      ),
      howToPlayTextFull: () => (
        <>
          {' '}
          <strong>Come giocare</strong> <br /> Trova e individua 8 errori nel secondo disegno. <br />
          Hai 12 possibilità.{' '}
        </>
      ),
      successfullyHit: 'OTTIMO!',
      incorrectHit: 'ERRORE!',
      clickOtherImage: 'Clicca sul secondo disegno',
      completadotitulo: 'Gioco completato!',
      textoacertado: 'Clic riusciti',
      textoerroneos: ', clic errati',
      textorevisarestadisticas: 'Puoi verificare i risultati cumulativi nelle Mie statistiche',
      statusEndGame: {
        good: 'BRAVO!',
        neutral: 'BENE!',
        bad: 'PUOI FARE DI PIÙ!',
      },
    },
    tilesGame: {
      title: 'Tessere',
      totalPairs: 'Coppie totali',
      resolvedPairs: 'Le mie coppie',
      marker: 'Tabellone',
      resolveGame: 'Risolvi',
      restartGame: 'Ricomincia',
      howToPlay: 'Come giocare',
      howToPlayText:
        'Abbina le tessere identiche per completare il pannello prima che scada il tempo - hai 4 minuti! Attenzione, alcune tessere potrebbero essere ruotate.',
      howToPlayTextFull: () => (
        <>
          {' '}
          <strong>Come giocare</strong> <br /> Abbina le tessere identiche per completare il pannello nel minor tempo
          possibile. Attenzione: alcune tessere potrebbero essere ruotate.{' '}
        </>
      ),
      incorrectlyPaired: 'Partner sbagliato!',
      successfullyPaired: 'Coppia giusta!',
      messages: {
        init: 'Hai 4 minuti per completare',
        '3m': 'Restano 3 minuti',
        '2m': 'Restano 2 minuti',
        '1m': 'Resta 1 minuto',
        '30s': 'Restano 30 secondi',
        '10s': 'Restano 10 secondi',
      },
      incomplete: {
        title: 'FINE DEL TEMPO!',
        message: 'Il tuo tempo è scaduto',
      },
    },
    LexiRetoGame: {
      expirationDate: 'oggi alle 23:59:59',
      title: 'Sfida Lexi',
      resolveGame: 'Soluzione',
      restartGame: 'Riavviar',
      howToPlay: 'Come giocare',
      howToPlayText: 'Come giocare',
      howToPlayTextFull: () => (
        <>
          <div className='mb-1'>
            <strong>Come giocare</strong>
          </div>
          <ul>
            <li>
              Crea parole composte da almeno 3 lettere. Le lettere possono ripetersi, ma sempre la lettera centrale.
            </li>
            <li>I nomi propri, i plurali e le forme coniugate dei verbi non sono consentiti (solo l'infinito).</li>
            <li>
              Trova parole che contengano tutte e 7 le lettere (Heptacrack!) per avanzare nella classifica e migliorare
              le tue statistiche.
            </li>
            <li>
              Punteggio: le parole di 3 lettere valgono 1 punto, quelle di 4 lettere valgono 2 punti. A partire da 5
              lettere, ottieni tanti punti quanti sono le lettere della parola. Gli Heptacrack valgono 10 punti.
            </li>
            <li>
              NOTA: Le sfide Lexi possono essere risolte solo il giorno della loro pubblicazione. Dopo le 12 diventano
              inattive e possono essere solo consultate.
            </li>
          </ul>
        </>
      ),
      totalpalabras: 'Le tue parole',
      tuspalabras: 'Le tue parole',
      empiezanpor: 'Iniziano con:',
      encontradas: 'Trovate',
      heptacrackencontradas: 'Heptacracks',
      comovatujuego: 'Come va il gioco',
      comovatujuego_heptacrack: 'Heptacrack!',
      comovatujuego_nuevonivel: 'Avanzi di livello!',
      marcador: 'Tabellone',
      puntuacion: 'Il tuo punteggio',
      maximo: 'Massimo',
      encontrados: 'Trovato',
      actions: {
        apply: 'Applica',
        clean: 'Pulisci',
        reordenar: 'Riordina',
      },
      messages: {
        correctword: 'Ben fatto!',
        wrongword: 'Non trovato',
        repeatedword: 'Già trovato',
        minimo3letras: 'Minimo 3 lettere',
        faltaletracentral: 'Manca la lettera centrale',
      },
      homenuevo: 'NOVITÀ',
      statusEndGame: {
        faces_6_7: 'ECCEZIONALE!',
        faces_4_5: 'OTTIMO!',
        faces_2_3: 'BUON LIVELLO!',
        faces_0_1: 'PUOI FARE DI MEGLIO',
      },
    },
  },
}
