import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import Menu from 'components/Menu'
import useNavStore from 'store/nav'
import useSiteStore from 'store/site'
import I18n from 'lang'
import { onClickExternalLink } from 'scenes/Home'

export default function Header(props) {
  const { exit = () => {} } = props
  const location = useLocation()
  const navStore = useNavStore()
  const siteStore = useSiteStore()

  const navItems = navStore.items?.map((item) => {
    const isExternal = item?.url?.indexOf('http') === 0

    if (item.children) {
      return {
        label: item.title,
        active: item.children.some((child) => location.pathname.includes(child.url)),
        options: item.children.map((child) => ({
          active: location.pathname.includes(child.url),
          href:
            child.url.indexOf('http') === 0 ? child.url : ['/', child.locale, child.url].join('/').replace(/\/+/g, '/'),
          label: child.title,
          onClick: exit,
        })),
      }
    }

    return {
      active: location.pathname.includes(item.url),
      href: isExternal ? item.url : ['/', item.locale, item.url].join('/').replace(/\/+/g, '/'),
      label: item.title,
      onClick: async (e) => {
        if (isExternal) {
          await onClickExternalLink(e, item?.api?.basepath, item.url)
        }
        await exit(e)
      },
    }
  })

  let path = location.pathname
  let isHome = false
  let spl = path.split('/')

  if (spl.length <= 2) {
    isHome = true
  }

  return (
    <>
      <div className='em-text-center'>
        {siteStore.site?.header?.logo?.src && siteStore.site?.header?.showHeader !== false && (
          <Link
            className='em-inline-block em-max-w-full em-p-8 em-text-center em-transition-all hover:em-opacity-80'
            to='/'
          >
            <img
              className={classNames('em-object-contain', {
                'em-max-h-[150px] em-max-w-[500px] em-w-full': isHome,
                'em-max-h-[70px] em-max-w-[250px] em-w-full': !isHome,
              })}
              src={siteStore.site?.header?.logo?.src}
              alt={siteStore.site?.header?.logo?.alt}
            />
          </Link>
        )}
      </div>
      <I18n t='welcome' />
      <nav className='em-border-t em-border-disabled-lighter !em-m-0 !em-w-auto'>
        <Menu
          parentClassName='em-w-full em-justify-center em-flex'
          className='menu-ppal em-uppercase !em-font-semibold em-overflow-x-auto'
          items={navItems}
        />
      </nav>
    </>
  )
}
