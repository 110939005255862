import create from 'zustand'

import useGameInfoStore from 'store/game'
import gamesApi from 'api'

const useHomeBlockStore = create((set, get) => ({
  items: [],
  getHomeBlocks: async () => {
    const { items: games } = useGameInfoStore.getState()

    const homeBlocksResponse = (await gamesApi.get('/config/home/blocks')).data

    set({
      items: homeBlocksResponse.map((block) => ({
        ...block,
        items: block.items.map((item) => {
          const game = games.find((game) => game.id === item.gameId)

          return {
            ...item,
            game,
            children:
              item.children?.map((child) => {
                const childGame = games.find((game) => game.id === child.gameId)

                return {
                  ...child,
                  game: childGame,
                }
              }) || undefined,
          }
        }),
      })),
    })
  },
}))

export default useHomeBlockStore
