import React from 'react'
import { STATUS_PLAYING, STATUS_INIT } from 'layouts/Game'

export default function useTimer(props) {
  const { status, startFrom = 0, decreasing = false, onTimeEnd = () => {} } = props
  const [time, _setTime] = React.useState(startFrom)
  const interval = React.useRef(null)
  const setTime = React.useCallback(async () => {
    _setTime((s) => {
      if (decreasing) {
        if (s > 0) {
          return s - 1
        }
        onTimeEnd()
        return 0
      }
      return s + 1
    })
  }, [_setTime, decreasing, onTimeEnd])

  React.useEffect(() => {
    _setTime(startFrom)
  }, [startFrom])

  React.useEffect(() => {
    if (status === STATUS_PLAYING) {
      interval.current = setInterval(() => {
        setTime()
      }, 1000)
    } else {
      if (interval.current) {
        clearInterval(interval.current)
      }

      props.setTime && props.setTime(time)
    }

    if (status === STATUS_INIT) {
      _setTime(startFrom)
    }

    return () => {
      if (interval.current) {
        clearInterval(interval.current)
      }
    }
  }, [status])

  return time
}
